import { CLBillingListTableWrapper } from "./cl-billing-list-table.presents";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { CLIcon, ClIconNoResult, ClIconSort } from "../shared/cl-icon";
import NoResultIcon from "../../assets/icons/no-result.svg";
import LoadIcon from "../../assets/icons/export.svg";
import {
  administrationListCountRows,
  listBillingSort,
  sortDirection
} from "../../boot/Constants";
import { CLSpiner } from "../shared/cl-spinner";
import ContentLoader from "react-content-loader";
import { useRootStore } from "../../stores/storeContext";
import { CSVLink } from "react-csv";
import { CLCheckbox } from "../shared/cl-checkbox";
import SortListIcon from "../../assets/icons/sort-list.svg";
import OnIcon from "../../assets/icons/on.svg";
import OffIcon from "../../assets/icons/off.svg";
import { isIDTFMC } from "../../stores/patientInfo/patientInfo.helper.service";
import dayjs from "dayjs";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

export const ClBillingListTable = observer(() => {
  const {
    items,
    isLoading,
    startDate,
    endDate,
    countMode,
    setIsCalculateDuplicate,
    setSortBy,
    isCalculateDuplicate,
    setBilled
  } = useRootStore().billingStore;
  const { IdCurrentMC } = useRootStore().userStore;
  const format = "MM/DD/YYYY HH:mm";
  const isDisplayCPTCode = isFeatureFlagActive("displayCPTCode");

  const csvHeaders = isDisplayCPTCode
    ? [
      { label: "Name", key: "PatientName" },
      { label: "Ordering Physician", key: "Physician" },
      { label: "Reading Physician", key: "ReadingPhysician" },
      { label: "Indications", key: "Indications" },
      { label: "Start Date", key: "StartDate" },
      { label: "End Date", key: "EndDate" },
      { label: "Mode", key: "Mode" },
      { label: "CPT Code", key: "CPT" },
      { label: "Device Id", key: "DeviceSerial" },
      { label: "Billed", key: "Billed" }
    ]
    : [
      { label: "Name", key: "PatientName" },
      { label: "Ordering Physician", key: "Physician" },
      { label: "Reading Physician", key: "ReadingPhysician" },
      { label: "Indications", key: "Indications" },
      { label: "Start Date", key: "StartDate" },
      { label: "End Date", key: "EndDate" },
      { label: "Mode", key: "Mode" },
      { label: "Device Id", key: "DeviceSerial" },
      { label: "Billed", key: "Billed" }
    ];

  const csvData = items.map((item: any) => {
    if (isDisplayCPTCode) {
      return {
        PatientName: item.PatientName || "-",
        Physician: item.Physician || "-",
        ReadingPhysician: item.ReadingPhysician || "-",
        Indications: item.Indications || "-",
        StartDate: item.StartDate ? dayjs(item.StartDate).format(format) : "-",
        EndDate: item.EndDate ? dayjs(item.EndDate).format(format) : "-",
        Mode: item.Mode || "-",
        CPT: item.CPT || "-",
        DeviceSerial: item.DeviceSerial || "-",
        Billed: item.Billed ? "Yes" : "No"
      };
    } else {
      return {
        PatientName: item.PatientName || "-",
        Physician: item.Physician || "-",
        ReadingPhysician: item.ReadingPhysician || "-",
        Indications: item.Indications || "-",
        StartDate: item.StartDate ? dayjs(item.StartDate).format(format) : "-",
        EndDate: item.EndDate ? dayjs(item.EndDate).format(format) : "-",
        Mode: item.Mode || "-",
        DeviceSerial: item.DeviceSerial || "-",
        Billed: item.Billed ? "Yes" : "No"
      };
    }
  });

  const handleCheckbox = (e: any) => {
    setIsCalculateDuplicate(e.target.checked);
  };

  const fileName = () => {
    const startDateF = dayjs(startDate).format("YYYY-MM-DD") || "";
    const endDateF = dayjs(endDate).format("YYYY-MM-DD") || "";
    return `billing_page_${startDateF}_${endDateF}.csv`;
  };

  const setBilledHandler = (ev: any, item: any) => {
    ev.preventDefault();
    setBilled(item.RecordingID, !item.Billed);
  };

  const getLoadContent = () => {
    const items = [];
    for (let i = 0; i < administrationListCountRows; i++) {
      items.push(<tr key={i.toString()}>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="80" height="26">
            <rect rx="4" ry="4" width="80" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="80" height="26">
            <rect rx="4" ry="4" width="80" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="150" height="26">
            <rect rx="4" ry="4" width="150" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="120" height="26">
            <rect rx="4" ry="4" width="120" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="120" height="26">
            <rect rx="4" ry="4" width="120" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="120" height="26">
            <rect rx="4" ry="4" width="120" height="26" />
          </ContentLoader>
        </td>
        <td className="action">
          <ContentLoader rtl={true} title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
        <td className="action">
          <ContentLoader title="" width="60" height="26">
            <rect rx="4" ry="4" width="60" height="26" />
          </ContentLoader>
        </td>
      </tr>);
    }

    return items;
  };

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };

  return (
    <CLBillingListTableWrapper>
      <div className="header">
        <div className="headline">Billing</div>
        <div className="mode-type">
          <div className="checkbox-holder">
            <CLCheckbox
              handleChange={handleCheckbox}
              value={isCalculateDuplicate}
              label={"Exclude Repeat Studies"}
              isChecked={isCalculateDuplicate}
              name={"checkbox_repeat_studies"}></CLCheckbox>
          </div>
          {countMode.map((modeType: any) => <p>
            <span className="mode-title">{modeType.title}</span>:
            <span className="mode-value">{modeType.value}</span>
          </p>)}
        </div>
        <CSVLink
          data={csvData}
          headers={csvHeaders}
          filename={fileName()}
          className="download_link">
          <CLIcon icon={LoadIcon}/>
          <span>Export .csv</span>
        </CSVLink>
      </div>

      <Table>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-title">Name</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Ordering Physician</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Reading Physician</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Indications</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Start Date</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listBillingSort.startdate, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listBillingSort.startdate, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">End Date</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Mode</div>
              </div>
            </th>
            {isDisplayCPTCode
              ? <th>
                <div className="sort">
                  <div className="sort-title">CPT Code</div>
                </div>
              </th>
              : null}
            <th>
              <div className="sort">
                <div className="sort-title">Device Id</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listBillingSort.deviceid, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listBillingSort.deviceid, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Billed</div>
              </div>
            </th>
          </tr>
        </thead>
        {(!isLoading && items.length > 0) && <tbody>
          {items.map((item: any, index: number) => <tr key={index.toString()}>
            <td>{item.PatientName || "-"}</td>
            <td>{item.Physician || "-"}</td>
            <td>{item.ReadingPhysician || "-"}</td>
            <td>{item.Indications || "-"}</td>
            <td>{item.StartDate ? dayjs(item.StartDate).format(format) : "-"}</td>
            <td>{item.EndDate ? dayjs(item.EndDate).format(format) : "-"}</td>
            <td>{item.Mode || "-"}</td>
            {isDisplayCPTCode
              ? <td>{item.CPT || "-"}</td>
              : null
            }
            <td>{item.DeviceSerial || "-"}</td>
            <td>
              <span className="pointer" onClick={(ev: any) => setBilledHandler(ev, item)}>
                <CLIcon icon={item.Billed ? OnIcon : OffIcon} width="24px" height="24px"></CLIcon>
              </span>
            </td></tr>)}
        </tbody>}
        {isLoading && <tbody>
          {getLoadContent()}
          <div className="spiner"><CLSpiner/></div>
        </tbody>}
      </Table>
      {(!isLoading && !items.length) && <div className="no-result-section">
        <div className="no-result-content">
          <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
          <div className="text">Sorry, no results found</div>
        </div>
      </div>}
    </CLBillingListTableWrapper>
  );
});
