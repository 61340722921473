import API from "../../boot/Api";
import * as Types from "../../services/api/api.types";
import { ApiErrorResponse, ApiSuccessResponse } from "../../services/api/api.types";
import { getEventTypeString, setActiveBeatsDiff } from "../../stores/patientInfo/patientInfo.helper.service";
import { isNullDate } from "../../services/handlers/date.handler";
import dayjs from "dayjs";

export const getList = async(patientId: string, selectedDay: string, count: number): Promise<any> => {
  const result
    = API.get(`HSData/BeatsDataPartial?recordingID=${patientId}&selectedDay=${selectedDay}&everyNumberBeat=${count}`);
  return await Types.getResponse(result);
};

export const fetchECGListDates = async(patientId: string, selectedDay: string): Promise<any> => {
  const endDT = dayjs(selectedDay, "MM/DD/YYYY").add(1, "days").format("MM/DD/YYYY");
  const result = API.get(`HSData/ECGShortList?recordingID=${patientId}&startDT=${selectedDay}&endDT=${endDT}`);
  return await Types.getResponse(result);
};

export const fetchECGLastDates = async(patientId: string): Promise<any> => {
  const result = API.get(`HSData/LastECGShort?recordingID=${patientId}`);
  return await Types.getResponse(result);
};

export interface BeatsType {
  x: number,
  y: number | number[],
  click?: Function,
  color?: string,
  indexLabel?: string,
  markerColor?: string,
  indexLabelFontWeight?: string,
  indexLabelFontSize?: number,
  indexLabelTextAlign?: string
}

export function getDiffFromStartOfDay(selectedDay: string) {
  const diff = 0;
  return diff + dayjs(selectedDay, "MM/DD/YYYY").startOf("day").valueOf();
}

export function fetchList(patientId: string, selectedDay: string, count: number = 2): Promise<any> {
  return getList(patientId, selectedDay, count)
    .then((response: ApiSuccessResponse | ApiErrorResponse) => {
      if (response.ok && response.data) {
        const BeatsData = response.data.Beats;
        const data: BeatsType[] = [];
        let diff = getDiffFromStartOfDay(selectedDay);
        if (response.data.StartBeatsDate) {
          const date = response.data.StartBeatsDate;
          const additionalDiff = dayjs(date).valueOf() - dayjs(date).startOf("day").valueOf();
          setActiveBeatsDiff(additionalDiff);
          diff += additionalDiff;
        }
        for (let i = 0; i < BeatsData.length; i++) {
          // if (i % 2 === 0) {
          if (+BeatsData[i][0] === 86) {
            data.push({
              x: diff + BeatsData[i][1] * 5,
              y: (60000 / BeatsData[i][2]),
              color: "red"
            });
          } else {
            data.push({ x: diff + BeatsData[i][1] * 5, y: (60000 / BeatsData[i][2]) });
          }
          // }
        }
        return data;
      }
      return undefined;
    })
    .catch(() => {
      return undefined;
    });
}

export function generateEvent(activeEvent: any) {
  let EventStart;
  if (activeEvent) {
    EventStart = activeEvent.StartDate;
  }
  const points = [
    { x: dayjs(EventStart).valueOf(), y: 0 },
    { x: dayjs(EventStart).valueOf(), y: 250 },
    { x: dayjs(EventStart).valueOf() + 100000, y: 250 },
    { x: dayjs(EventStart).valueOf() + 100000, y: 0 },
    { x: dayjs(EventStart).valueOf(), y: 0 }
  ];

  return points;
}

export function generateECG(activeECG: any) {
  let EventStart;
  let dataECG: any;
  if (activeECG) {
    EventStart = activeECG;
    const points = [
      { x: dayjs(EventStart).valueOf(), y: 0 },
      { x: dayjs(EventStart).valueOf(), y: 250 },
      { x: dayjs(EventStart).valueOf() + 300000, y: 250 },
      { x: dayjs(EventStart).valueOf() + 300000, y: 0 },
      { x: dayjs(EventStart).valueOf(), y: 0 }
    ];
    dataECG = points;
  } else {
    dataECG = [];
  }
  return dataECG;
}

export function generateActiveEvents(events: any) {
  const stripLines: { value: number, color: string }[] = [];

  if (events) {
    events.map((event: any) => {
      const eventType = getEventTypeString(event);
      switch (eventType) {
        case "Source": // Confirmed
          stripLines.push({
            color: "#7E54B5",
            value: dayjs(event.StartDate).valueOf()
          });
          break;
        case "Confirmet":
          stripLines.push({
            color: "#457EF5",
            value: dayjs(event.StartDate).valueOf()
          });
          break;
        case "Stat": // ConfirmedReviewed
          stripLines.push({
            color: "#EF7171",
            value: dayjs(event.StartDate).valueOf()
          });
          break;
        default:
          break;
      }
    });
  }

  return stripLines;
}

function findClosestTime(time: string, list: any[]) {
  const activeTime = dayjs(time, "MM/DD/YYYY HH:mm:ss");
  let activeIndex;
  for (let i = 0; i < list.length; i++) {
    const validEcg = dayjs(list[i].StartDate);
    if (!list[i + 1]) {
      return i;
    }
    const validNextEcg = dayjs(list[i + 1].StartDate);
    if (activeTime.valueOf() > validEcg.valueOf() && activeTime.valueOf() < validNextEcg.valueOf()) {
      if (activeTime.valueOf() - validEcg.valueOf() < -activeTime.valueOf() + validNextEcg.valueOf()) {
        activeIndex = i;
      } else {
        activeIndex = i + 1;
      }
      return activeIndex;
    }
  }
}

export function checkECGByTime(time: string, list: any[]) {
  const activeTime = dayjs(time, "MM/DD/YYYY HH:mm:ss");
  for (let i = 0; i < list.length; i++) {
    const validEcg = dayjs(list[i].StartDate);
    const validNextEcg = dayjs(list[i].StartDate).add(5, "minutes");
    if (activeTime.valueOf() > validEcg.valueOf() && activeTime.valueOf() < validNextEcg.valueOf()) {
      return list[i].FolderName;
    }
  }
  const activeClosestIndex = findClosestTime(time, list);
  if (activeClosestIndex || activeClosestIndex === 0) {
    return list[activeClosestIndex].FolderName;
  } else {
    return null;
  }
}

export function getLastECG(list: any[]) {
  return list[list.length - 1].FolderName;
}

export function checkNextECG(activeFolderName: string, list: any[]) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].FolderName === activeFolderName) {
      return list[i + 1].FolderName;
    }
  }
  return list[0].FolderName;
}

export function checkPrevECG(activeFolderName: string, list: any[]) {
  for (let i = 0; i < list.length; i++) {
    if (list[i].FolderName === activeFolderName) {
      return list[i - 1].FolderName;
    }
  }
  return list[0].FolderName;
}

export function getDateStart(patient: any) {
  if (patient && patient.Device && patient.Device.DeviceStatistics) {
    return patient.Device.DeviceStatistics.DeviceRecordingStartDate + "";
  } else {
    return "";
  }
}

export function getDateEnd(patient: any) {
  if (patient && patient.Device && patient.Device.DeviceStatistics) {
    return dayjs(isNullDate(patient.Device.DeviceStatistics.DeviceRecordingEndDate)
      ? dayjs(patient.Device.DeviceStatistics.DeviceRecordingStartDate)
        .add(patient.Device.DeviceSettings.Duration, "hours").format()
      : patient.Device.DeviceStatistics.DeviceRecordingEndDate).format("MM/DD/YYYY");
  } else {
    return "";
  }
}
export function getDateEndMoment(patient: any) {
  if (patient && patient.Device && patient.Device.DeviceStatistics) {
    return dayjs(
      isNullDate(patient.Device.DeviceStatistics.DeviceRecordingEndDate)
        ? dayjs(patient.Device.DeviceStatistics.DeviceRecordingStartDate)
          .add(patient.Device.DeviceSettings.Duration, "hours").format()
        : patient.Device.DeviceStatistics.DeviceRecordingEndDate
    );
  } else {
    return null;
  }
}
let timerECG: any = null;
export const setECGTimer = (timer: any) => {
  timerECG = timer;
};

export const clearECGTimer = () => {
  timerECG && clearTimeout(timerECG);
};
