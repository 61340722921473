import { PropsType } from "./cl-tooltip.props";
import { TooltipStyle } from "./cl-tooltip.presents";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import React from "react";

export const ClTooltip = (props: PropsType) => {
  return (
    <TooltipStyle>
      <a data-tooltip-id={props.id}>{props.children}</a>
      <Tooltip
        id={props.id}
        place={props.place ? props.place : "top"}
        variant={props.type ? props.type : "light"}
        className={props.class ? props.class : "cl-tooltip"}>
        {props.tooltipBlock}
      </Tooltip>
    </TooltipStyle>
  );
};
