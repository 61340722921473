import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useRootStore } from "../../stores/storeContext";
import { ClIconSort, ClIconNoResult, CLIcon } from "../shared/cl-icon";
import SortListIcon from "../../assets/icons/sort-list.svg";
import NoResultIcon from "../../assets/icons/no-result.svg";
import {
  sortDirection,
  pendingStudyPatientsSort
} from "../../boot/Constants";
import { CLSpiner } from "../shared/cl-spinner";
import ContentLoader from "react-content-loader";

import { CLPatientListTableWraper } from "../cl-patients-list-table/cl-patients-list-table.presents";
import { useState } from "react";
import { CLNotesModal } from "../cl-notes-modal";
import NoteIcon from "../../assets/icons/note.svg";
import NoteIconGray from "../../assets/icons/note_gray.svg";
import { CLDropdown } from "../shared/cl-dropdown";
import ActionIcon from "../../assets/icons/dots.svg";
import { isFeatureFlagActive } from "../../services/feature-flag.service";
import { useNavigate } from "react-router-dom";

export const CLPatientPendingStudyListTable = observer(() => {
  const {
    itemsPendingStudy,
    isLoading,
    setSortByPendingStudy,
    activeCategory,
    isNoPatients
  } = useRootStore().listPatientsStore;
  const isInsuranceInfo = isFeatureFlagActive("insuranceInfo");
  const { resetPatientInfo, getDataBasePatient } = useRootStore().patientInfoStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const navigate = useNavigate();
  
  const [modalNoteShow, setModalNoteShow] = useState({
    isShow: false,
    note: ""
  });

  const commandDevices = [
    {
      text: "Start/Edit Study",
      url: "StartTest",
      checkUrl: "IsCanUseStartTest",
      isStart: true,
      index: 3
    },
    {
      text: "Edit patient",
      isEdit: true,
      index: 4
    }];
  
  if (isInsuranceInfo) {
    commandDevices.push({
      text: "Edit Insurance Info",
      isEdit: true,
      index: 7
    });
  }
  
  const HandleOpenNotesClick = async(ev: any, item: any) => {
    ev.preventDefault();
    const note = item.PatientComment ?? "";
    setModalNoteShow({
      isShow: true,
      note: note
    });
  };

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortByPendingStudy(sortBy, sortDirection);
  };
  
  const openStartStudyPatient = (RecordingID: string | number) => {
    navigate(`/study-create/${RecordingID}`,{state: { activeCategory: activeCategory }});
  };

  const openEditPatient = async(idPatient: string | number) => {
    setGlobalSpiner(true);
    const result = await getDataBasePatient(idPatient);
    setGlobalSpiner(false);
    if (result.ok && result.data) {
      navigate(`/edit-patient/${idPatient}`, {state: { patientData: result.data, activeCategory: activeCategory }});
    }
  };
  const openInsuranceInfo = async(idPatient: string | number) => {
    setGlobalSpiner(true);
    const result = await getDataBasePatient(idPatient);
    setGlobalSpiner(false);
    if (result.ok && result.data) {
      navigate(`/insurance-info/${idPatient}`, {state: { isCreate: false }});
    }
  };
  
  const getAction = async(
    isOpen: boolean,
    patient: any
  ) => {
    if (!isOpen) { return []; }
    const result = commandDevices.map((item) => {
      switch (item.index) {
        case 0:
          return {
            text: item.text,
            action: () => {},
            disabled: true
          };
        case 3:
          return {
            text: item.text,
            action: () => {
              if (patient.PatientId) {
                resetPatientInfo();
                openStartStudyPatient(patient.PatientId);
              }
            },
            disabled: false
          };
        case 4:
          return {
            text: item.text,
            action: () => { openEditPatient(patient.PatientId); },
            disabled: false
          };
        case 7:
          return {
            text: item.text,
            action: () => { openInsuranceInfo(patient.PatientId); },
            disabled: false
          };
      }
    });
    
    return await Promise.all(result.filter(item => !!item));
  };

  const getLoadContent = () => {
    const items = [];
    for (let i = 0; i < 6; i++) {
      items.push(<tr key={i.toString()}>
        <td>
          <ContentLoader title="" width="145" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="105" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26"/>
          </ContentLoader>
        </td>
      </tr>);
    }
    return items;
  };
  return (
    <CLPatientListTableWraper>
      <Table>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-title">Patient Name</div>
                <div className="sort-actions">
                  <div onClick={() => sort(pendingStudyPatientsSort.patientname, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div
                    onClick={() => sort(pendingStudyPatientsSort.patientname, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div className="sort-title">Order Date</div>
              </div>
            </th>
            <th>
              <div>
                <div className="sort-title">Order Status</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Recording Id</div>
                <div className="sort-actions">
                  <div onClick={() => sort(pendingStudyPatientsSort.recordingid, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div
                    onClick={() => sort(pendingStudyPatientsSort.recordingid, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Ordering Physician</div>
                <div className="sort-actions">
                  <div onClick={() => sort(pendingStudyPatientsSort.physician, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div
                    onClick={() => sort(pendingStudyPatientsSort.physician, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div>
                <div className="sort-title">Mode</div>
              </div>
            </th>
            <th>
              <div>
                <div className="sort-title">Notes</div>
              </div>
            </th>
            <th className="sort">
              <div className="sort-title">Duration</div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Order ID</div>
              </div>
            </th>
            <th>
              <div className="sort-title">Actions</div>
            </th>
          </tr>
        </thead>
        
        {(!isLoading && itemsPendingStudy.length > 0) && <tbody>
          {itemsPendingStudy.map((item: any) => <tr className="cursor">
            <td>{item.FullName}</td>
            <td>{item.Start}</td>
            <td>{item.OrderType}</td>
            <td>{item.RecordingId}</td>
            <td>{item.OrderingPhysician}</td>
            <td>{item.Mode}</td>
            <td className="copy">
              <div
                onClick={(ev: any) => HandleOpenNotesClick(ev, item)}>
                {(item.PatientComment && item.PatientComment !== ".")
                || (item.PhysicianComment && item.PhysicianComment !== ".")
                  ? <CLIcon icon={NoteIcon} height="24px" width="24px"></CLIcon>
                  : <CLIcon icon={NoteIconGray} height="24px" width="24px"></CLIcon>}
              </div>
            </td>
            <td>{item.Duration}</td>
            <td>{item.OrderId}</td>
            <td className="action">
              <CLDropdown
                icon={ActionIcon}
                onToggle={(isOpen: boolean) => {
                  return getAction(
                    isOpen,
                    item
                  ).then(result => {
                    return result;
                  });
                }}
                actions={[]}></CLDropdown>
            </td>
          </tr>
          )}
        </tbody>}
        {isLoading && <tbody>
          {getLoadContent()}
          <tr>
            <td>
              <div className="spiner"><CLSpiner></CLSpiner></div>
            </td>
          </tr>
        </tbody>}
      </Table>
      {(!isLoading && !itemsPendingStudy.length)
        && <div className="no-result-section">
          {!isNoPatients() && <div className="no-result-content">
            <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
            <div className="text">Sorry, no results found</div>
          </div>}
        </div>}
      {modalNoteShow.isShow && <CLNotesModal
        isShow={modalNoteShow.isShow}
        note={modalNoteShow.note}
        isOperatorOnlyView={true}
        closeModal={
          () => setModalNoteShow({isShow: false, note: "" })}></CLNotesModal>}
    </CLPatientListTableWraper>
  );
});
