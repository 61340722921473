import { observer } from "mobx-react-lite";
import { CLPatientsListCategoriesWrapper } from "./cl-patients-list-categories.presents";
import { CLCategoryItem } from "../shared/cl-category-item";
import { CLPagination } from "../shared/cl-pagination";
import {
  HomeHookupCategory,
  PatiensListCategories,
  patientListCountRows,
  PendingStudyCategory
} from "../../boot/Constants";
import { useRootStore } from "../../stores/storeContext";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const CLPatientsListCategories = observer(() => {
  const {
    activeCategory,
    total,
    page,
    setActiveCategory,
    nextPage,
    previousPage,
    allCategoryTotal,
    activeCategoryTotal,
    eventsCategoryTotal,
    pendingCategoryTotal,
    archivedCategoryTotal,
    inactiveCategoryTotal,
    homeHookupCategoryTotal,
    pendingStudyCategoryTotal
  } = useRootStore().listPatientsStore;

  const {
    idPhysician,
    isUseSH,
    isUseEMR
  } = useRootStore().userStore;

  const { state: locationState } = useLocation();

  const categoriesTotals: any = {
    allCategoryTotal,
    activeCategoryTotal,
    eventsCategoryTotal,
    pendingCategoryTotal,
    archivedCategoryTotal,
    inactiveCategoryTotal,
    homeHookupCategoryTotal,
    pendingStudyCategoryTotal
  };

  useEffect(() => {
    if (locationState?.activeCategory) {
      setActiveCategory(locationState?.activeCategory, 0, true);
    } else if (idPhysician) {
      setActiveCategory("active", 0, true);
    }
  }, [idPhysician, locationState]);

  const changeListCategory = (value: string) => {
    setActiveCategory(value, categoriesTotals[`${value}CategoryTotal`]);
  };

  return (
    <CLPatientsListCategoriesWrapper>
      {PatiensListCategories.map(item => (
        <CLCategoryItem
          key={item.title}
          onChange={changeListCategory}
          title={item.title}
          icon={activeCategory === item.value ? item.iconActive : item.icon}
          count={categoriesTotals[`${item.value}CategoryTotal`]}
          value={item.value}
          isActive={activeCategory === item.value}
        ></CLCategoryItem>
      ))}
      {isUseSH
        ? <CLCategoryItem
          key={HomeHookupCategory.title}
          onChange={changeListCategory}
          title={HomeHookupCategory.title}
          icon={activeCategory === HomeHookupCategory.value ? HomeHookupCategory.iconActive : HomeHookupCategory.icon}
          count={categoriesTotals[`${HomeHookupCategory.value}CategoryTotal`]}
          value={HomeHookupCategory.value}
          isActive={activeCategory === HomeHookupCategory.value}
        ></CLCategoryItem>
        : null}
      {isUseEMR
        ? <CLCategoryItem
          key={PendingStudyCategory.title}
          onChange={changeListCategory}
          title={PendingStudyCategory.title}
          icon={
            activeCategory === PendingStudyCategory.value ? PendingStudyCategory.iconActive : PendingStudyCategory.icon
          }
          count={categoriesTotals[`${PendingStudyCategory.value}CategoryTotal`]}
          value={PendingStudyCategory.value}
          isActive={activeCategory === PendingStudyCategory.value}
        ></CLCategoryItem>
        : null}
      <div className="pagination">
        {(total > patientListCountRows) && <CLPagination
          total={total}
          page={page}
          countOnPage={patientListCountRows}
          next={nextPage}
          previous={previousPage}></CLPagination>}
      </div>

    </CLPatientsListCategoriesWrapper>
  );
});
