import { PatientInfoHeaderInfoStyle } from "./cl-patient-info-header-info.presents";
import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import { DeviceModes } from "../../boot/Constants";
import { isNullDate } from "../../services/handlers/date.handler";
import { clearECGTimer } from "../cl-charts-canvas/cl-charts-canvas.service";
import dayjs from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

export const ClPatientInfoHeaderInfo = observer(() => {
  const { patient, resetPatientInfo } = useRootStore().patientInfoStore;
  const { state: locationState } = useLocation();
  const [isResetInfo, setIsResetInfo] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      if (isResetInfo) {
        resetPatientInfo();
        setIsResetInfo(false);
      }
    };
  },[]);

  const cancelChanges = () => {
    clearECGTimer();
    setIsResetInfo(true);
    if (locationState && locationState.returnToReports) {
      navigate("/reports-list");
    } else {
      if (locationState?.activeCategory) {
        navigate("/patients-list", {state: { activeCategory: locationState?.activeCategory }});
      } else {
        navigate("/patients-list");
      }
    }
  };

  const getGender = (type: string): string => {
    if (type === "M") {
      return "Male";
    } else if (type === "F") {
      return "Female";
    } else {
      return "Other";
    }
  };

  const getOperatingMode = (item: any) => {
    if (item.Device) {
      return item.Device.DeviceSettings.HPDays > 0
        ? "Holter+ MCT"
        : DeviceModes[item.Device.DeviceSettings.OperatingMode];
    } else {
      return "-";
    }
  };

  let ICD10Text = "";
  if (patient && patient.Patient && patient.Patient.IndForMonitor) {
    if (patient.Patient.IndForMonitor.length > 25) {
      ICD10Text = patient.Patient.IndForMonitor.substring(0, 25) + "...";
    } else {
      ICD10Text = patient.Patient.IndForMonitor;
    }
  }

  const checkPending = (date: string) => {
    return dayjs(date).year() === 1 || dayjs(date).year() === 1901;
  };

  const getDateRecording = () => {
    if (checkPending(patient.Device.DeviceStatistics.DeviceRecordingStartDate)) {
      return "Pending - Pending";
    } else {
      return `${dayjs(patient.Device.DeviceStatistics.DeviceRecordingStartDate).format("MM/DD/YYYY HH:mm:ss")} - 
      ${dayjs(isNullDate(patient.Device.DeviceStatistics.DeviceRecordingEndDate)
    ? dayjs(patient.Device.DeviceStatistics.DeviceRecordingStartDate)
      .add(patient.Device.DeviceSettings.Duration, "hours").format("")
    : patient.Device.DeviceStatistics.DeviceRecordingEndDate).format("MM/DD/YYYY HH:mm:ss")}`;
    }
  };

  //  TODO ModelNumber: "2"  1.MCT, 2. ER, 3.Holter  1. MCT, 2. Event Recorder, 3. Wireless Holter
  return (
    <PatientInfoHeaderInfoStyle>
      <span className="back" onClick={cancelChanges}> <img src={ArrowLeftIcon}></img></span>

      {patient && patient.Patient && (<div className="info">
        <div className="headline">
          <span className="title">{patient.Patient.Name + " " + patient.Patient.LastName}</span> |
          <span> id {patient.Patient.PatientIDClinic}</span>
          <span></span>
        </div>

        <div className="additional-info">
          {patient.Device && patient.Device.DeviceStatistics && (
            <span>{getDateRecording()}</span>
          )}
          <br/>
          <span> {dayjs(patient.Patient.DOB).format("MMM Do YYYY")}</span> |
          <span> {getGender(patient.Patient.Gender)}</span> |
          <span> {ICD10Text} </span> |
          <span> {getOperatingMode(patient)} </span>
        </div>
      </div>)}

    </PatientInfoHeaderInfoStyle>
  );
});
