import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router-dom";
import { PatientsListPage } from "../pages/PatientsList";
import { DevicesListPage } from "../pages/DevicesList";
import { CreateUserPage } from "../pages/CreateUserPage";
import { useRootStore } from "../stores/storeContext";
import { PatientInfoPage } from "../pages/PatientInfo";
import { LoginPage } from "../pages/Login";
import { ForgotPasswordPage } from "../pages/ForgotPassword";
import { RecoveryPasswordPage } from "../pages/RecoveryPassword";
import { ClinicPortalPage } from "../pages/ClinicPortal";
import { AdministrationListPage } from "../pages/AdministrationList";
import { StudyCreatePage } from "../pages/StudyCreate";
import { CLSpiner } from "../components/shared/cl-spinner";
import { Notifications } from "../pages/Notifications";
import { BillingPage } from "../pages/Billing";
import { SupportPage } from "../pages/Support";
import { UserPage } from "../pages/User/User.page";
import { ReportsListPage } from "../pages/ReportsList";
import { NotificationsMobilePage } from "../pages/NotificationsMobile";
import { DeviceMode } from "./Constants";
import React from "react";
import { InsuranceInfoPage } from "../pages/InsuranceInfo";

export const Routers = observer(() => {
  const { setMobile, clearIsMobile, detectMode, detectIsMobile } = useRootStore().userStore;
  useRootStore().userStore.checkAuthorized();
  const isMobile = detectMode() === DeviceMode.mobile;

  if (typeof useRootStore().userStore.isAuth === "undefined") {
    return (<></>);
  } else {
    if (!isMobile) {
      clearIsMobile();
    } else {
      setMobile(isMobile);
    }
    const stylesForMobileDevice = !isMobile && detectIsMobile() ? { minWidth: "980px" } : { minWidth: "auto" };

    return (
      <div style={stylesForMobileDevice}>
        {!isMobile && detectIsMobile()
          ? <meta name="viewport" content="user-scalable = no"></meta>
          : <meta content="width=device-width, initial-scale=1"></meta>}
        {useRootStore().globalStore.showGlobalSpiner && <div className="global-spiner">
          <CLSpiner></CLSpiner>
        </div>}
        {useRootStore().userStore.isAuth && <ClinicPortalPage></ClinicPortalPage>}
        {useRootStore().userStore.isAuth && !isMobile && <Routes>
          <Route path='/patients-list' element={<PatientsListPage />} />
          <Route path='/devices-list' element={<DevicesListPage />} />
          <Route path='/billing/*' element={<BillingPage />} />
          <Route path='/create-patient' element={<CreateUserPage />} />
          <Route path="/edit-patient/:patientId" element={<CreateUserPage />} />
          <Route path='/patient-info/:patientId/*' element={<PatientInfoPage />} />
          <Route path='/study-create/:patientId' element={<StudyCreatePage />} />
          <Route path='/insurance-info/:patientId' element={<InsuranceInfoPage />} />
          <Route path="/administration-list/*" element={<AdministrationListPage />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/support" element={<SupportPage />} />
          <Route path="/user/*" element={<UserPage />} />
          <Route path="/reports-list" element={<ReportsListPage />} />
          <Route path="*" element={<Navigate to={"/patients-list"} replace={true}/>} />
        </Routes>
        }
        {useRootStore().userStore.isAuth && isMobile && <Routes>
          <Route path='/notifications-mobile' element={<NotificationsMobilePage />} />
          <Route path='/patient-info/:patientId/*' element={<PatientInfoPage />} />
          <Route path="*" element={<Navigate to={"/notifications-mobile"} replace={true}/>} />
        </Routes>
        }
        {!useRootStore().userStore.isAuth && <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/forgot-password" element={<ForgotPasswordPage />} />
          <Route path="/recovery-password/:userId" element={<RecoveryPasswordPage />} />
          <Route path="/create-password/:userId" element={<RecoveryPasswordPage />} />
          <Route path="*" element={<Navigate to={"/login"} replace={true}/>} />
        </Routes>
        }
      </div>
    );
  }
});
