import API from "../../boot/Api";
import * as Types from "./api.types";
import { dataToParamsString } from "../handlers/data.handler";

export const getListPatients = async(jsonFilter: {}, url: string): Promise<Types.ApiResponse> => {
  const result = API.get(url, {
    params: { jsonFilter: jsonFilter }
  });
  return await Types.getResponse(result);
};
export const getListHomeHookupPatients = async(jsonFilter: {}): Promise<Types.ApiResponse> => {
  const result = API.get("HSData/HomeHookupRecords", {
    params: { jsonFilter: jsonFilter, isLoadCancelled: true }
  });
  return await Types.getResponse(result);
};
export const getListPendingStudyPatients = async(jsonFilter: {}): Promise<Types.ApiResponse> => {
  const result = API.get("HSData/PendingStudyRecords", {
    params: { jsonFilter: jsonFilter, isLoadCancelled: true }
  });
  return await Types.getResponse(result);
};

export const getTotalPatientsCount = async(jsonFilter: {}): Promise<Types.ApiResponse> => {
  const result = API.get("HSData/RecordCounter", {
    params: { jsonFilter: jsonFilter }
  });
  return await Types.getResponse(result);
};

export const getListHomeHookupCountApi = async(jsonFilter: {}): Promise<Types.ApiResponse> => {
  const result = API.get("HSData/HomeHookupCount", {
    params: { jsonFilter: jsonFilter, isLoadCancelled: true }
  });
  return await Types.getResponse(result);
};
export const getListPendingStudyCountApi = async(jsonFilter: {}): Promise<Types.ApiResponse> => {
  const result = API.get("HSData/PendingStudyCount", {
    params: { jsonFilter: jsonFilter, isLoadCancelled: true }
  });
  return await Types.getResponse(result);
};

export const checkIsCanUseApi = async(url: string, params: any): Promise<Types.ApiResponse> => {
  const result = API.get(`/ClinicDevices/${url}`, {
    params: params
  });
  return await Types.getResponse(result);
};

export const checkIsCanUseChangeStudyApi = async(url: string, params: any): Promise<Types.ApiResponse> => {
  const result = API.get(`/PortalUser/${url}`, {
    params: params
  });
  return await Types.getResponse(result);
};

export const sendCommandForDeviceApi = async(url: string, data: any): Promise<Types.ApiResponse> => {
  const result = API.post(`/ClinicDevices/${url}?${dataToParamsString(data)}`);
  return await Types.getResponse(result);
};

export const updateStatusDevicesApi = async(ids: any[]): Promise<Types.ApiResponse> => {
  const result = API.get("ClinicDevices/DeviceStatus", {
    params: { jsonIds: JSON.stringify(ids) }
  });
  return await Types.getResponse(result);
};

export const getDeviceListCSVApi = async(type: string, jsonFilter: {}): Promise<Types.ApiResponse> => {
  const result = API.get("ClinicDevices/ExportDevices", {
    params: {
      type: type,
      jsonFilter: jsonFilter
    }
  });
  return await Types.getResponse(result);
};
