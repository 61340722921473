import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { PatientInfoEvents } from "./cl-patient-info-events.presents";
import { useEffect, useState } from "react";
import { ClPatientEventsNav } from "../cl-patient-events-nav";
import { getEventTypeString } from "../../stores/patientInfo/patientInfo.helper.service";
import DropdownIcon from "../../assets/icons/dropdown.svg";
import { CLDropdown } from "../shared/cl-dropdown";
import { ClPatientInfoEventsTab } from "../cl-patient-info-events-tab";
import { ClPatientInfoEventsTabReported } from "../cl-patient-info-events-tab-reported";
import { ClTooltip } from "../shared/cl-tooltip";
import dayjs from "dayjs";

const listEvents = [
  { icon: "All", type: "all", id: 0, count: 1, tooltip: "All" },
  { icon: "Confirmet", id: 3, count: 1, tooltip: "Confirmed" },
  { icon: "Profile", id: 2, count: 1, tooltip: "Symptomatic" },
  { icon: "Rejected", id: 4, count: 1, tooltip: "Rejected" }];

const listEventsReported = [
  { icon: "All", type: "all", id: 0, count: 1, tooltip: "All" },
  { icon: "Stat", id: 1, count: 1, tooltip: "Notify" },
  { icon: "Profile", id: 2, count: 1, tooltip: "Symptomatic" },
  { icon: "Rejected", id: 4, count: 1, tooltip: "Rejected" }
];

export const ClPatientInfoEvents = observer(() => {
  const [activeFilter, setActiveFilter] = useState(0);
  const [activeFilterReport, setActiveFilterReport] = useState(0);
  const {
    patient,
    activeEventType,
    setActiveFolderName,
    setActiveEventType,
    setActiveDate,
    events,
    activeTab,
    setActiveTab
  } = useRootStore().patientInfoStore;

  if (!patient || !patient.ReceivedDatas || !events.length) {
    return (<PatientInfoEvents>
      <div className="wrapper">
        <div className="header">
          <h3>
            No Events
          </h3>
        </div>
      </div>
    </PatientInfoEvents>);
  }

  const getItemType = (listItem: any) => {
    if (listItem) {
      return getEventTypeString(listItem);
    }
  };
  const getCount = (type: string): number => {
    let count = 0;
    events.map((item: any) => {
      item.data.map((listItem: any) => {
        if (+listItem.EventSource === 77 || +listItem.EventSource === 78) {
          return false;
        }
        const typeItem = getItemType(listItem);
        if (type === "All" && typeItem !== "Rejected") {
          count++;
        }
        if (type === typeItem) {
          count++;
        }
      });
    });

    return count;
  };

  const getCountReported = (type: string): number => {
    let count = 0;
    events.map((item: any) => {
      item.data.map((listItem: any) => {
        if (+listItem.EventSource !== 77 && +listItem.EventSource !== 78) {
          return false;
        }
        const typeItem = getItemType(listItem);
        if (type === "All" && typeItem !== "Rejected") {
          count++;
        } else if (type === typeItem) {
          count++;
        }
      });
    });

    return count;
  };

  const setEvent = (listItem: any) => {
    setActiveFolderName(listItem.FolderName + "");
    setActiveEventType(+listItem.Id);
    setActiveDate(dayjs(listItem.StartDate));
  };

  const reportsData = events.map((item: any) => {
    const data = item.data.filter((listItem: any) => {
      if (+listItem.EventSource === 77 || +listItem.EventSource === 78) {
        return true;
      }
      return false;
    });
    if (data.length) {
      return { ...item, data };
    } else {
      return {};
    }
  });

  const profileActions = [{
    text: "Events",
    color: "#484848",
    action: () => {
      setActiveTab("events");
    }
  }, {
    text: "Reported",
    color: "#484848",
    action: () => {
      setActiveTab("reports");
    }
  }];

  useEffect(() => {
    if (activeEventType) {
      let isAnyReported = false;
      events.map((item: any) => {
        item.data.map((listItem: any) => {
          if (listItem.Id === activeEventType) {
            if (listItem.EventSource === 77 || listItem.EventSource === 78) {
              setActiveTab("reports");
              isAnyReported = true;
            }
          }
        });
      });
      if (!isAnyReported) {
        events.map((item: any) => {
          item.data.map((listItem: any) => {
            if (listItem.Id === activeEventType) {
              if (listItem.EventSource !== 77 && listItem.EventSource !== 78) {
                setActiveTab("events");
              }
            }
          });
        });
      }
    }
  }, [activeEventType]);

  return (
    <PatientInfoEvents>
      <div className="wrapper">
        <div className="header">
          <h3>
            <CLDropdown
              text={activeTab === "events" ? "Events" : "Reported"}
              icon={DropdownIcon}
              actions={profileActions}
              rotateIcon={true}></CLDropdown>
          </h3>

          <div className="events-nav">
            {activeTab === "events" && listEvents.map((item: any, index) => (
              <ClTooltip
                id={`eventNav${item.id}`}
                class="cl-tooltip"
                tooltipBlock={<div>{item.tooltip}</div>}>
                <ClPatientEventsNav key={item.id}
                  icon={item.icon}
                  count={getCount(item.icon)}
                  isActive={activeFilter === index}
                  setActiveFilter={() => setActiveFilter(index)}></ClPatientEventsNav>
              </ClTooltip>
            ))}

            {activeTab === "reports" && listEventsReported.map((item: any, index) => (
              <ClTooltip
                id={`reportedNav${item.id}`}
                class="cl-tooltip"
                tooltipBlock={<div>{item.tooltip}</div>}>
                <ClPatientEventsNav key={item.id}
                  icon={item.icon}
                  count={getCountReported(item.icon)}
                  isActive={activeFilterReport === index}
                  setActiveFilter={() => setActiveFilterReport(index)}></ClPatientEventsNav>
              </ClTooltip>
            ))}
          </div>
        </div>
        {activeTab === "events" && <div className="events-list">
          <ClPatientInfoEventsTab
            events={events}
            activeFilter={activeFilter}
            setEvent={setEvent}></ClPatientInfoEventsTab>
        </div>}
        {activeTab === "reports" && <div className="events-list">
          <ClPatientInfoEventsTabReported
            events={reportsData}
            activeFilter={activeFilterReport}
            setEvent={setEvent}></ClPatientInfoEventsTabReported>
        </div>}
      </div>
    </PatientInfoEvents>
  );
});
