import { ClChartsEcgStyles } from "./cl-charts-ecg.presents";
import { useRootStore } from "../../stores/storeContext";
import CanvasJSReact from "../../assets/charts/@canvasjs/react-charts/canvasjs.react";
import React, { useEffect, useRef, useState } from "react";
import { ApiErrorResponse, ApiSuccessResponse } from "../../services/api/api.types";
import { PropsType } from "../cl-charts-ecg/cl-charts-ecg.props";
import * as Types from "../../services/api/api.types";
import * as d3 from "d3";
import API from "../../boot/Api";
import GainIcon from "../../assets/icons/gain_mmmv.svg";
import { observer } from "mobx-react-lite";
import {
  getListECG,
  getStoredBeats,
  isIncludeStoredBeats,
  setStoredBeats
} from "../../stores/patientInfo/patientInfo.helper.service";
import { CLSpiner } from "../shared/cl-spinner";
import { CLDropdown } from "../shared/cl-dropdown";
import { CLIcon } from "../shared/cl-icon";
import {
  EcgStateModel, getECGChartRef, getECGShortChartRef,
  setChartsPreviewData,
  setECGChartRef,
  setECGEventDeltaStart,
  setECGEventStart,
  setECGShortChartRef
} from "../../stores/patientInfo/patientInfo.chartexport.service";
import CallipersIcon from "../../assets/icons/callipers.svg";
import RightIcon from "../../assets/icons/right.svg";
import LeftIcon from "../../assets/icons/left.svg";
import { theme } from "../../theme/theme";
import { BeatsType } from "../cl-charts-canvas/cl-charts-canvas.service";
import { findBiggestPoint, get8SecondData, getStripLines, setEventChart } from "./cl-charts-ecg.service";
import { ClTooltip } from "../shared/cl-tooltip";
import { ClChartModalsEvent } from "../cl-chart-modals-event/cl-chart-modals-event";
import { CLBlueButton } from "../shared/cl-button";
import dayjs from "dayjs";

const cooefToPX = 0.078;
const CanvasJSChart = CanvasJSReact.CanvasJSChart;
let countResponse = 0;
let zoom = 1;
function hideCallipers() {
  d3.select("#calipers").select("svg").remove();
  d3.select("#calipers2").select("svg").remove();
  d3.select("#calipers2").style("opacity", 0);
}

function getSec(width: number, realProp: number) {
  return (width / realProp).toFixed(2);
}

function getBMP(width: number, realProp: number) {
  return (60 / (width / realProp)).toFixed(0);
}

function getSecShort(width: number) {
  return (cooefToPX * width).toFixed(2);
}

function getBMPShort(width: number) {
  return (60 / (cooefToPX * (+width))).toFixed(0);
}

function drawCalipers(ref: any, id?: string) {
  const canvasOffset = ref.canvas.getBoundingClientRect();
  const callipers2Height = 90;
  let xy0: any;
  let keep = false;
  let gNumber = 0;
  const rectHeight = id ? 50 : 60;
  const realProp = ((+canvasOffset.width - 45) * zoom) / 8;
  const line = d3.line()
    .x(function(d) { return d[0]; })
    .y(function(d) { return d[1]; });
  if (id) {
    d3.select(id).style("opacity", 1);
  }

  const svg = d3.select(id || "#calipers")
    .append("svg")
    .attr("width", canvasOffset.width)
    .attr("height", id ? callipers2Height : canvasOffset.height);

  let g: any;
  let isRectDrag = false;
  let isRightLineDrag = false;
  let ybase: number;
  let xbase: number;

  const drag = d3.drag()
    .on("start", function() {
      if (d3.event.sourceEvent.target.classList.value === "rect-drag") {
        isRectDrag = true;
      } else if (d3.event.sourceEvent.target.classList.value === "right-line-drag") {
        isRightLineDrag = true;
      }
    })
    .on("end", function() {
      if (isRectDrag) {
        isRectDrag = false;
      }
      if (isRightLineDrag) {
        isRightLineDrag = false;
      }
    })
    .on("drag", function(d: any) {
      if (isRectDrag) {
        d.x += d3.event.dx;
        d.y += d3.event.dy;
        d3.select(this).attr("transform", function(d: any) {
          return "translate(" + [d.x, d.y] + ")";
        });
      } else if (isRightLineDrag) {
        d.gwidth += d3.event.dx;
        const Line = line([[0, rectHeight / 2], [d.gwidth, rectHeight / 2]]);
        const l1: [number, number][] = [d.gwidth, 0] as any;
        const l2: [number, number][] = [d.gwidth, rectHeight] as any;
        const lres: [number, number][] = [l1, l2] as any;
        const currentG = d3.select(this);
        const width = Math.abs(+d.gwidth);

        currentG.attr("width", width);

        currentG.select(".main-line")
          .attr("d", Line as string);

        currentG.select(".right-line")
          .attr("d", line(lres) as string);

        currentG.select(".right-line-drag")
          .attr("x", width - 5)
          .attr("width", 10);

        currentG.select(".rect-drag")
          .attr("x", 0)
          .attr("width", width);

        let sec = getSec(+width, realProp);
        if (id) {
          sec = getSecShort(+width);
        }
        let bpm = getBMP(+width, realProp);
        if (id) {
          bpm = getBMPShort(+width);
        }
        currentG.select(".text")
          .attr("x", width / 2 - 30)
          .text(sec + " sec");

        currentG.select(".text-bmp")
          .attr("x", width / 2 - 30)
          .text(bpm + " bpm/-");
      } else {
        return null;
      }
    });

  svg
    .on("mousedown", function(this: SVGGraphicsElement) {
      keep = true;
      const m: [number, number][] = d3.mouse(this) as any;
      const m0: [number, number][] = [0, 0] as any;
      xy0 = [0, 0];
      const number = ++gNumber;
      const l1: [number, number][] = [0, 0] as any;
      const l2: [number, number][] = [0, rectHeight] as any;
      const lres: [number, number][] = [l1, l2] as any;
      ybase = +m[1] - (rectHeight / 2);
      xbase = +m[0];
      g = svg.append("g")
        .attr("id", "calipers_id" + number)
        .data([{
          // Position of the rectangle
          x: xbase,
          y: ybase,
          gwidth: 0
        }])
        .attr("transform", function(d: any) {
          return "translate(" + d.x + ", " + d.y + ")";
        })
        .call(drag as any);
      g.append("path")
        .attr("id", "left-line" + number)
        .attr("class", "left-line")
        .attr("d", line(lres))
        .style("stroke", "blue")
        .style("stroke-width", "1px");

      g.append("path")
        .attr("id", "right-line" + number)
        .attr("class", "right-line")
        .attr("d", line(lres) as string)
        .style("stroke", "blue")
        .style("stroke-width", "1px");

      g.append("path")
        .attr("id", "main-line" + number)
        .attr("class", "main-line")
        .attr("d", line(m0))
        .style("stroke", theme.colors.blue)
        .style("stroke-width", "1px");

      g.append("rect")
        .attr("class", "rect-drag")
        .attr("id", "rect" + number)
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", 2)
        .attr("height", rectHeight + 4)
        .attr("fill", "rgba(234,241,255,0.5)");

      g.append("rect")
        .attr("id", "right-line-drag" + number)
        .attr("class", "right-line-drag")
        .attr("x", -2)
        .attr("y", 0)
        .attr("width", 2)
        .attr("height", rectHeight + 4)
        .style("fill", "rgba(255, 50, 50, 0)")
        .style("cursor", "ew-resize");

      g.append("text")
        .attr("id", "text" + number)
        .attr("class", "text")
        .attr("x", -15)
        .attr("y", rectHeight)
        .style("fill", "blue")
        .text("");

      g.append("text")
        .attr("id", "text-bmp" + number)
        .attr("class", "text-bmp")
        .attr("x", -15)
        .attr("y", 0)
        .style("fill", "blue")
        .text("");
    })
    .on("mouseup", function() {
      keep = false;
    })
    .on("mousemove", function(this: any) {
      const mbase: [number, number][] = d3.mouse(this) as any;
      const m: [number, number][] = [(+mbase[0] - xbase), (ybase + rectHeight / 2)] as any;
      if (keep) {
        const Line = line([[0, (rectHeight / 2)], [+mbase[0] - xbase, (rectHeight / 2)]]);
        const l1: [number, number][] = [+m[0], 0] as any;
        const l2: [number, number][] = [+m[0], rectHeight] as any;
        const lres: [number, number][] = [l1, l2] as any;
        let x0;
        let width: number;
        const height = rectHeight + 4;
        if (xy0[0] < m[0]) {
          x0 = xy0[0];
          width = Math.abs(+m[0]) + 4;
        } else {
          x0 = m[0];
          width = Math.abs(+m[0]) + 4;
        }
        const oldData = g.data();
        g.data([{
          x: oldData[0].x,
          y: oldData[0].y,
          gwidth: width
        }]);

        g.attr("width", Math.abs(+m[0]));

        g.select("#main-line" + gNumber)
          .attr("d", Line as string);

        g.select("#right-line" + gNumber)
          .attr("d", line(lres) as string);

        g.select("#right-line-drag" + gNumber)
          .attr("x", width - 5)
          .attr("width", 10);

        g.select("#rect" + gNumber)
          .attr("x", 0)
          .attr("width", width)
          .attr("height", height);

        let sec = getSec(+width - 4, realProp);
        if (id) {
          sec = getSecShort(+width - 4);
        }
        let bpm = getBMP(+width - 4, realProp);
        if (id) {
          bpm = getBMPShort(+width - 4);
        }
        g.select("#text" + gNumber)
          .attr("x", x0 + width / 2 - 30)
          .text(sec + " sec");

        g.select("#text-bmp" + gNumber)
          .attr("x", x0 + width / 2 - 30)
          .text(bpm + " bpm/-");
      }
    });
}

export const getEcg = async(patientId: string, folderName: string): Promise<any> => {
  if (isIncludeStoredBeats(patientId, folderName)) {
    const result = getStoredBeats(patientId, folderName);
    return await Types.getResponse(result);
  } else {
    const result = API.get(`HSData/ECGData?recordingID=${patientId}&receivedDataFolderName=${folderName}`);
    setStoredBeats(patientId, folderName, result);
    return await Types.getResponse(result);
  }
};

export const ClChartsEcg = observer((props: PropsType) => {
  const { activeFolderName, setActiveECG } = useRootStore().patientInfoStore;

  const myRef = useRef<HTMLDivElement>(null);
  const activeStateObject: EcgStateModel = {
    data: [],
    dataSecond: [],
    dataShort: [],
    dataShort2: [],
    dataEvent: [],
    dataNumbers: [],
    dataBeats: [],
    isScroll: true,
    activeDate: "",
    activeDataState: "",
    mmmV: 1,
    mV: 0.5
  };
  let activeStateObject2: any = {};

  const [Gstate, setGstate] = useState<EcgStateModel>(activeStateObject);
  const stripLines: { value: number, color: string }[] = [{ value: 0, color: "rgb(237, 105, 226)" }];
  const stripLinesShort: { value: number, color: string }[] = [{ value: 0, color: "#000000" }];
  const [isCalipersActive, setCalipers] = useState(false);
  const [isEmptyDate, setIsEmptyDate] = useState(false);

  const GainAxisY = [
    {
      text: "10 mm/mV",
      action: () => {
        setAction(1);
      }
    },
    {
      text: "‘P-wave’ mode",
      action: () => {
        setAction(0.5);
      }
    }
  ];
  const GainDelta = [
    {
      text: "0.25 mV",
      action: () => {
        setActionMv(0.25);
      }
    },
    {
      text: "0.50 mV",
      action: () => {
        setActionMv(0.5);
      }
    },
    {
      text: "0.75 mV",
      action: () => {
        setActionMv(0.75);
      }
    }
  ];

  let options = {};
  let optionsShort = {};

  const onClick = (ev: any, dataBeatsResponse: any, activeDate?: string) => {
    if (ev.dataPoint && ev.dataPoint.x) {
      activeStateObject2 = {};
      let pointStart = 0;
      if (+ev.dataPoint.x - 4000 > 0) {
        pointStart = +ev.dataPoint.x - 4000;
        if (+ev.dataPoint.x + 4000 >= 300000) {
          pointStart = 300000 - 8000;
        }
      }
      activeStateObject2.dataEvent = setEventChart(pointStart / 5);
      get8Second(pointStart + 4000, dataBeatsResponse);
      setEventStartDate(pointStart / 5, activeDate);
      activeStateObject2.isScroll = false;

      setGstate((prevState) => ({ ...prevState, ...activeStateObject2 }));
    }
  };

  const nextEcg = () => {
    props.nextEcg();
  };

  const prevEcg = () => {
    props.prevEcg();
  };

  function getEventStart(ecgEvent: any) {
    if (ecgEvent && (ecgEvent.Length || ecgEvent.Length === 0)) {
      if (ecgEvent.Length < 8) {
        return ecgEvent.StartFromBeginReceivedData * 5 + 4000 - (ecgEvent.Length * 500);
      }
    }
    return ecgEvent.StartFromBeginReceivedData * 5;
  }

  const convertDataShort = (data: any, activeDate?: string) => {
    const data0: BeatsType[] = [];
    const data1: BeatsType[] = [];
    if (data.DataPrimary) {
      for (let i = 0; i < data.DataPrimary.length; i++) {
        if (data.DataPrimary[i] && i % 3 === 0 && data.DataPrimary[i] < 6.3) {
          data0.push({
            x: i * 5,
            y: data.DataPrimary[i],
            click: (ev: any) => {
              onClick(ev, data, activeDate);
            }
          });
        }
      }
    }
    if (data.DataSecondary) {
      for (let i = 0; i < data.DataSecondary.length; i++) {
        if (data.DataSecondary[i] && i % 3 === 0 && data.DataSecondary[i] < 6.3) {
          data1.push({
            x: i * 5,
            y: data.DataSecondary[i] - 0.5,
            click: (ev: any) => {
              onClick(ev, data, activeDate);
            }
          });
        }
      }
    }
    return { data0: data0, data1: data1 };
  };

  const getData = () => {
    countResponse = 0;
    setIsEmptyDate(false);
    return getEcg(props.patientId, activeFolderName)
      .then((response: ApiSuccessResponse | ApiErrorResponse) => {
        if (response.ok && response.data) {
          if (!countResponse) {
            countResponse++;
            activeStateObject2 = {};
            const { data0, data1 }: {data0: BeatsType[], data1: BeatsType[]}
              = convertDataShort(response.data, response.data.StartDateRD);
            if (!props.ecgEvent || (Object.keys(props.ecgEvent) && !Object.keys(props.ecgEvent).length)) {
              activeStateObject2.dataEvent = setEventChart(response.data.DataPrimary.length / 2);
              setEventStartDate(response.data.DataPrimary.length / 2, response.data.StartDateRD);
              get8Second(response.data.DataPrimary.length / 2 * 5 + 4000, response.data);
            } else {
              const eventStart = getEventStart(props.ecgEvent) / 5;
              activeStateObject2.dataEvent = setEventChart(eventStart);
              setEventStartDate(eventStart, response.data.StartDateRD);
              if (props.ecgEvent && props.ecgEvent.Start || props.ecgEvent.Start === 0) {
                get8Second(eventStart * 5 + 4000, response.data);
              } else {
                get8Second(response.data.DataPrimary.length / 2 * 5, response.data);
              }
            }

            activeStateObject2.dataBeats = response.data;
            activeStateObject2.dataShort = data0;
            activeStateObject2.dataShort2 = data1;
            activeStateObject2.isScroll = true;
            activeStateObject2.activeDate = response.data.StartDateRD;
            activeStateObject2.activeDataState = props.patientId + "_" + activeFolderName;

            setGstate((prevState) => ({ ...prevState, ...activeStateObject2 }));
            if (dayjs(props.selectedDay).isSame(dayjs(activeStateObject2.activeDate), "day")) {
              setActiveECG(response.data.StartDateRD);
            }
            return response;
          }
        }
        setIsEmptyDate(true);
        return null;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const get8Second = (time: number, dataBeats2: any) => {
    const result = get8SecondData(Gstate, time, dataBeats2);

    activeStateObject2.data = result.data1;
    activeStateObject2.dataSecond = result.data2;
    activeStateObject2.dataNumbers = result.dataNumbers;
  };

  const scrollToCenter = () => {
    if (Gstate.dataEvent && Gstate.dataShort && Gstate.isScroll) {
      const xD0 = Gstate.dataEvent[0].x;
      const xD1 = Gstate.dataShort[Gstate.dataShort.length - 1].x;

      if (myRef && myRef.current) {
        const visibleWidth = myRef.current.offsetWidth;
        const coof1 = 3800 / xD1;
        const center = visibleWidth / 2 - (4000 * coof1);
        let xC0 = 0;
        if (xD0 * coof1 > center) {
          xC0 = xD0 * coof1 - center;
        } else {
          xC0 = 0;
        }
        myRef.current.scroll(xC0, 0);
      }
    }
  };

  const setEventStartDate = (EventStart: number, activeDate?: string) => {
    setECGEventStart(dayjs(activeDate || Gstate.activeDate).valueOf() + EventStart * 5);
    setECGEventDeltaStart(EventStart);
  };

  const setAction = (eventKey: number) => {
    activeStateObject2 = {};
    activeStateObject2.mmmV = eventKey;
    setGstate((prevState) => ({ ...prevState, ...activeStateObject2 }));
  };

  const setActionMv = (eventKey: number) => {
    activeStateObject2 = {};
    activeStateObject2.mV = eventKey;
    setGstate((prevState) => ({ ...prevState, ...activeStateObject2 }));
  };

  const toggleCallipers = (toggle: boolean) => {
    if (toggle) {
      drawCalipers(getECGChartRef());
      drawCalipers(getECGShortChartRef(), "#calipers2");
    } else {
      hideCallipers();
    }
    setCalipers(toggle);
  };

  const getActiveEventTime = () => {
    let delta = 0;
    if (Gstate.dataEvent) {
      delta = Gstate.dataEvent[0].x;
    }
    const time = dayjs(Gstate.activeDate).valueOf() + delta;
    return dayjs(time).format("MM/DD/YYYY hh:mm:ss A");
  };

  useEffect(() => {
    getData()
      .then((response) => {
        if (!response) return null;
      });
  }, [activeFolderName, props.ecgEvent, Gstate.mV]);
  let isLoading = false;
  if (Gstate.activeDataState !== props.patientId + "_" + activeFolderName) {
    isLoading = true;
  }

  if (Gstate && Gstate.dataShort && Gstate.data && Gstate.dataSecond
        && Gstate.dataShort.length && Gstate.data.length && Gstate.dataSecond.length) {
    getStripLines(Gstate, stripLines, stripLinesShort);
    const dataNumbersMMMV = Gstate.dataNumbers && Gstate.dataNumbers.map((item) => {
      item.y = 1.35 * Gstate.mmmV;
      return item;
    });
    const isAllPointBig = findBiggestPoint(Gstate);
    options = {
      animationEnabled: false,
      exportEnabled: false,
      zoomEnabled: true,
      height: 240,
      backgroundColor: "rgb(255, 255, 255)",
      rangeChanged: (d: any) => {
        const x0 = d.axisX[0].viewportMinimum;
        const x1 = d.axisX[0].viewportMaximum;
        if (!x0 || !x1) {
          zoom = 1;
        } else {
          const z = 8000 / (x1 - x0);
          zoom = z;
        }
      },
      theme: "light2", // "light1", "dark1", "dark2"
      toolTip: {
        enabled: false // enable here
      },
      axisY: {
        interval: 0.5 * Gstate.mmmV,
        maximum: 2 * Gstate.mmmV,
        minimum: -2 * Gstate.mmmV
      },
      axisX: {
        stripLines: stripLines,
        interval: 1000,
        valueFormatString: "#,.#"
      },
      data: [{
        markerType: "none",
        lineThickness: 1,
        type: "line",
        color: "#000000",
        xValueType: "number",
        dataPoints: Gstate.data
      }, {
        markerType: "none",
        lineThickness: 1,
        type: "line",
        color: "#000000",
        xValueType: "number",
        dataPoints: Gstate.dataSecond
      }, {
        lineThickness: 0,
        type: "scatter",
        markerSize: 0,
        color: "rgba(0,0,0,0)",
        xValueType: "number",
        indexLabelFontWeight: "bold",
        indexLabelFontSize: 16,
        indexLabelFontColor: "#000",
        dataPoints: dataNumbersMMMV
      }]
    };

    optionsShort = {
      animationEnabled: false,
      height: 76,
      toolTip: {
        enabled: false // enable here
      },
      axisY: {
        title: "",
        tickLength: 0,
        lineThickness: 0,
        margin: 0,
        maximum: isAllPointBig ? 6.3 : 0.5,
        minimum: isAllPointBig ? -6.3 : -0.5,
        labelFormatter: function() {
          return " ";
        }
      },
      axisX: {
        title: "",
        tickLength: 0,
        lineThickness: 0,
        margin: 0,
        labelFormatter: function() {
          return " ";
        }
      },
      theme: "light2", // "light1", "dark1", "dark2"
      data: [{
        type: "line",
        lineThickness: 0.5,
        color: "#000000",
        xValueType: "dateTime",
        dataPoints: Gstate.dataShort
      }, {
        type: "stepArea",
        markerSize: 2,
        color: "rgba(69, 126, 245, 0.15)",
        dataPoints: Gstate.dataEvent
      }
        // {
        //     type: 'rangeSplineArea',
        //     fillOpacity: 0.15,
        //     lineThickness: 0.5,
        //     markerType: 'none',
        //     color: 'rgb(69, 126, 245)',
        //     dataPoints: Gstate.dataEvent2
        // }
      ]
    };

    setChartsPreviewData(Gstate);
  } else {
    if (isEmptyDate) {
      return (
        <ClChartsEcgStyles>
          <h6>No data received</h6>
        </ClChartsEcgStyles>
      );
    } else {
      return (
        <ClChartsEcgStyles>
          <div className="spiner"><CLSpiner id="ecg" boxShadow={"none"}></CLSpiner></div>
        </ClChartsEcgStyles>
      );
    }
  }

  if (myRef) {
    setTimeout(() => {
      scrollToCenter();
    }, 500);
  }

  const activeDate = getActiveEventTime();
  const list = getListECG();
  let activeIndex = 0;
  for (let i = 0; i < list.length; i++) {
    if (list[i].FolderName === activeFolderName) {
      activeIndex = i;
      break;
    }
  }
  const isNextHide = list.length ? (activeIndex >= list.length - 1) : false;
  const isPrevHide = list.length ? (activeIndex <= 0) : false;

  const onRef = (ref: any) => {
    setECGChartRef(ref);
    // drawCalipers(ref);
  };

  const onRefShort = (ref: any) => {
    setECGShortChartRef(ref);
  };
  if (props.isEmptyEvents) {
    return (
      <ClChartsEcgStyles>
        <h3>ECG not selected</h3>
        <p>Please click on HR trend or select Events/Reports</p>
      </ClChartsEcgStyles>
    );
  }
  return (
    <ClChartsEcgStyles>
      {isLoading && <div className="spiner"><CLSpiner id="ecg" boxShadow={"none"}></CLSpiner></div>}
      <div className="chart-header">
        <div className="selected-date">{activeDate}</div>
        {props.ecgEvent && props.ecgEvent.EcgStripCaption && <p>{props.ecgEvent.EcgStripCaption}</p>}
        <div className="d-flex border-between">
          <div className="dropdown">
            <div className="dropdown-icon-wrapper">
              <CLIcon icon={GainIcon}></CLIcon>
            </div>
            <CLDropdown
              text={(Gstate.mV) + " mV"}
              onToggle={() => {}}
              actions={GainDelta}></CLDropdown>
            <CLBlueButton
              padding={"10px"}
              width={"120px"}
              click={() => {
                if (Gstate.mmmV === 0.5) {
                  GainAxisY[0].action();
                } else {
                  GainAxisY[1].action();
                }
              }}
              text={Gstate.mmmV === 0.5 ? "10 mm/mV" : "P-wave"}></CLBlueButton>
          </div>
          <div className="calipers-btn"
            style={{
              background: isCalipersActive ? "rgba(38,86,186,0.08)" : "#FFFFFF",
              border: isCalipersActive ? "1px solid #457EF5" : "1px solid transparent"
            }}
            onClick={() => toggleCallipers(!isCalipersActive)}>
            <CLIcon width="18px" icon={CallipersIcon}></CLIcon>
          </div>
          <ClChartModalsEvent/>
        </div>

      </div>
      <div id="calipers" style={{
        position: "absolute",
        zIndex: 10,
        border: "1px solid" + theme.colors.blue,
        borderRadius: "4px"
      }}></div>
      <div className="holder">
        <div id="chart-container">
          <CanvasJSChart options={options} onRef={onRef}></CanvasJSChart>
        </div>
      </div>
      <div style={{
        overflow: "hidden",
        position: "relative"
      }}>
        <div id="calipers2" style={{
          opacity: 0,
          position: "absolute",
          width: "100%",
          zIndex: 10,
          top: "-10px",
          border: "1px solid" + theme.colors.blue,
          borderRadius: "4px"
        }}></div>
        <div className="holder-short" ref={myRef}>
          <div>
            <CanvasJSChart options={optionsShort} onRef={onRefShort}></CanvasJSChart>
          </div>
        </div>
      </div>
      <div className="control">
        <div className="left" onClick={prevEcg}>
          {!isPrevHide
            && <ClTooltip
              id={"eventPrev5Min"}
              class="cl-tooltip"
              tooltipBlock={<div>prior 5 min</div>}>
              <CLIcon width="18px" icon={LeftIcon}></CLIcon>
            </ClTooltip>
          }
        </div>
        <div className="right" onClick={nextEcg}>
          {!isNextHide
            && <ClTooltip
              id={"eventNext5Min"}
              class="cl-tooltip"
              tooltipBlock={<div>next 5 min</div>}>
              <CLIcon width="18px" icon={RightIcon}></CLIcon>
            </ClTooltip>}
        </div>
      </div>

    </ClChartsEcgStyles>
  );
});
