import { observer } from "mobx-react-lite";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRootStore } from "../../stores/storeContext";
import { useEffect } from "react";
import ArrowLeftIcon from "../../assets/icons/arrow-left.svg";
import { ClPatientInsuranceInfo } from "../../components/cl-patient-insurance-info";
import { InsuranceInfoStyle } from "./InsuranceInfo.presents";

export const InsuranceInfoPage = observer(() => {
  const { getPatientBase } = useRootStore().patientInfoStore;
  const patientAdditional = useRootStore().patientCreateAdditionalModel;

  const { patientId } = useParams();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    patientAdditional.init({ patientId });
    getPatientBase(patientId);
  }, [patientId]);

  const backClicked = () => {
    if (location.pathname.indexOf("/create-patient") + 1) {
      navigate(-1);
    } else if (locationState?.activeCategory) {
      navigate("/patients-list", { state: { activeCategory: locationState?.activeCategory }});
    } else {
      navigate(-1);
    }
  };

  return (
    <InsuranceInfoStyle>
      <span className="back" onClick={backClicked}> <img src={ArrowLeftIcon}></img></span>
      <div className="study-form" id={"study-form"}>
        <ClPatientInsuranceInfo/>
      </div>
    </InsuranceInfoStyle>
  );
});
