import { OptionType } from "../../components/shared/cl-select/cl-select.props";
import { getEventTypeConstant } from "../../components/cl-patient-events-type/cl-patient-events-type.constant";

function createArytmiaType() {
  const ids = [69, 1, 2, 3, 4, 5, 92, 93, 94, 12, 77, 95, 79, 9, 7, 6, 11, 80, 81, 82, 83, 75, 73, 72,
    10, 70, 76, 100, 101, 102, 103, 104, 105, 106, 107, 108, 111];
  const returnObj: OptionType[] = ids.map((id: number) => {
    const value = getEventTypeConstant(id) || "";
    return {
      id: "" + id,
      label: value,
      value: value
    };
  });

  return returnObj;
}
export const ArrhythmiaType: OptionType[] = createArytmiaType();
