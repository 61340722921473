import styled from "styled-components";
import { theme } from "../../../theme/theme";
import { PropsType } from "./cl-progress.props";

export const ProgressStyle = styled.div<PropsType>`
  border: 1px solid ${theme.colors.greyBorder};
  border-radius: 4px;
  font-weight: normal;
  font-size: 14px;
  box-sizing: border-box;
  height: ${props => props.text ? "32px" : "12px"};
  display: flex;
  align-items: center;
  min-width: 50px;
  width: 100%;
  justify-content: center;
  position: relative;
  background: ${theme.colors.light};
  overflow: hidden;
  span {
    z-index: 10;
  }
  

  :after {
    content: '';
    position: absolute;
    background: ${props => props.backgroundColor ? props.backgroundColor : theme.colors.backgroundTableHeader};
    top: 0; bottom: 0;
    left: 0; 
    width: ${props => props.percentage}%;
    z-index: -1;
  }
`;
