import { observer } from "mobx-react-lite";
import { PatientsListStyle } from "./PatientsList.presents";
import { CLCard } from "../../components/shared/cl-card";
import { CLPatientsListHeader } from "../../components/cl-patients-list-header";
import { CLPatientsListCategories } from "../../components/cl-patients-list-categories";
import { CLPatientListTable } from "../../components/cl-patients-list-table";
import { useRootStore } from "../../stores/storeContext";
import React, { useEffect } from "react";
import { timeUpdateStatus, timeUpdateListPatients } from "../../boot/Constants";
import { ClAlert2fa } from "../../components/cl-alert-2fa";
import { CLPatientHomeHookupListTable } from "../../components/cl-patients-list-home-hookup";
import { ClAction2fa } from "../../components/cl-action-2fa";
import { ClAlert2faHero } from "../../components/cl-alert-2fa-hero";
import { isFeatureFlagActive } from "../../services/feature-flag.service";
import { CLPatientPendingStudyListTable } from "../../components/cl-patients-list-pending-study";

export const PatientsListPage = observer(() => {
  const {
    getTotalCount,
    activeCategory,
    updateStatusDevices
  } = useRootStore().listPatientsStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { PortalUserMC, isCenterAvailable, IdCurrentMC } = useRootStore().userStore;
  let isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");

  useEffect(() => {
    isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");
  }, [PortalUserMC, IdCurrentMC]);

  useEffect(() => {
    if (!isCenterAvailable) {
      getTotalCount()
        .then(() => { setGlobalSpiner(false); })
        .catch(() => { setGlobalSpiner(false); });
    }
    const idUpdateList = setInterval(() => {
      getTotalCount();
    }, timeUpdateListPatients);

    const idUpdateStatus = setInterval(() => {
      updateStatusDevices();
    }, timeUpdateStatus);

    return () => {
      if (idUpdateList) {
        clearInterval(idUpdateList);
      }
      if (idUpdateStatus) {
        clearInterval(idUpdateStatus);
      }
    };
  }, []);

  useEffect(() => {
    if (isCenterAvailable) {
      const isPortalUserMCExist = PortalUserMC || PortalUserMC === 0;
      if (isPortalUserMCExist) {
        setGlobalSpiner(true);
        getTotalCount()
          .then(() => { setGlobalSpiner(false); })
          .catch(() => { setGlobalSpiner(false); });
      }
    }
  }, [PortalUserMC]);

  return (
    <PatientsListStyle>
      <CLCard padding="24px">
        <CLPatientsListHeader/>
        <CLPatientsListCategories/>
        {activeCategory === "homeHookup" ? <CLPatientHomeHookupListTable/>
          : activeCategory === "pendingStudy" ? <CLPatientPendingStudyListTable/>
            : <CLPatientListTable/>}
      </CLCard>
      {isMandatoryMFA ? <ClAlert2fa/> : <ClAlert2faHero/>}
      <ClAction2fa/>
    </PatientsListStyle>
  );
});
