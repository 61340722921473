import { types, flow } from "mobx-state-tree";
import { IObservableArray } from "mobx";
import API from "../boot/Api";
// import { defaultValueClinicSelect } from "../boot/Constants";
import {
  loginApi,
  validateCodeApi,
  getUserApi,
  forgotPasswordApi,
  recoveryPasswordApi,
  getNotificationsApi,
  getCurentUserApi,
  getMonitoringCenterApi,
  invitePatientToAppApi,
  getProblemDevicesApi,
  postUserMandatoryMFA
} from "../services/api/user.api";
import orderBy from "lodash.orderby";
import {
  updateAllUsersNotificationsCriteria,
  updateClinicDeviceSettingsTemplate,
  updateNotificationsCriteria
} from "../services/api/study.api";
import { UsersGroupSTR, DeviceMode } from "../boot/Constants";
import { FeatureFlagService, isFeatureFlagActive } from "../services/feature-flag.service";
import {
  getClinicName,
  getUserGroup,
  isAdminsGroup,
  isCanSwitchBetweenCenters,
  isMobile,
  isSuperUserGroup
} from "./helpers/user.store.helpers";

export const UserStoreModel = types
  .model("UserStoreModel", {
    idUser: types.maybe(types.number),
    firstName: "",
    portalUserLogin: "",
    phoneNumber: "",
    phone2: "",
    description: "",
    lastName: "",
    email: "",
    npi: types.optional(types.string, ""),
    disablePasscode: false,
    optOut2FA: false,
    waiver2FA: false,
    allClinicLoad: false,
    isBannerShow: false,
    idPhysician: types.optional(types.union(types.string, types.number), ""),
    isAuth: types.maybe(types.boolean),
    clinics: types.optional(types.array(types.frozen()), []),
    center: types.optional(types.array(types.frozen()), []),
    idClinic: types.optional(types.string, ""),
    clinicSettingsTemplate: types.optional(types.frozen(), {}),
    notificationCriteria: types.optional(types.frozen(), {}),
    clinicPhysicians: types.optional(types.frozen(), {}),
    PortalUserAC: types.maybe(types.number),
    IdCurrentMC: types.maybe(types.number),
    PortalUserMC: types.maybe(types.number),
    newNotifications: types.optional(types.array(types.frozen()), []),
    wifiSettingsTemplate: types.optional(types.frozen(), {}),
    itContacts: types.optional(types.frozen(), {}),
    lastNotifications: types.optional(types.array(types.frozen()), []),
    problemDevices: types.optional(types.number, 0),
    inviting: types.optional(types.boolean, false),
    usersGroup: types.optional(types.number, 0),
    usersGroupSTR: types.optional(types.string, ""),
    isMobile: types.optional(types.boolean, false),
    isUseSH: types.optional(types.boolean, false),
    isUseEMR: types.optional(types.boolean, false),
    isCenterAvailable: types.optional(types.boolean, false),
    isAction2FA: types.optional(types.boolean, false),
    isUseWiFi: types.optional(types.boolean, false),
    isAdminsRightsAvailable: types.optional(types.boolean, false),
    isStandartRightsAvailable: types.optional(types.boolean, false),
    isInvitingPatient: types.optional(types.boolean, false),
    signAs: types.optional(types.string, ""),
    additionalClinics: types.optional(types.array(types.frozen()), []),
    clinicInfo: types.optional(types.frozen(), {})
  })
  .views(self => ({
    get is2FABannerShow() {
      if (isSuperUserGroup(self.usersGroupSTR)) {
        return true;
      } else if (isFeatureFlagActive("mandatoryMFA")) {
        return !self.waiver2FA;
      } else {
        return !self.optOut2FA && self.isBannerShow;
      }
    }
  }))
  .actions(self => ({
    setMobile(val: boolean) {
      self.isMobile = val;
    },
    setUsersGroupSTR(val: string) {
      self.usersGroupSTR = val;
    },
    setWaiver2FA(val: boolean) {
      self.waiver2FA = val;
    },
    setOptOut2FA(val: boolean) {
      self.waiver2FA = val;
    },
    setIsBannerShow(val: boolean) {
      self.waiver2FA = val;
    },
    setIsAction2FA(val: boolean) {
      self.isAction2FA = val;
    },
    clearIsMobile() {
      self.isMobile = false;
    },
    getMode() {
      return localStorage.getItem("CLMode");
    },
    setMobileMode() {
      localStorage.setItem("CLMode", DeviceMode.mobile);
    },
    setDesktopMode() {
      localStorage.setItem("CLMode", DeviceMode.desktop);
    },
    detectIsMobile() {
      if (isMobile()) {
        self.isMobile = true;
        return true;
      } else {
        self.isMobile = false;
        return false;
      }
    }
  }))
  .actions(self => ({
    detectMode() {
      let deviceMode = "";
      if (self.getMode() === DeviceMode.mobile) {
        deviceMode = DeviceMode.mobile;
      } else if (self.getMode() === DeviceMode.desktop) {
        deviceMode = DeviceMode.desktop;
      } else if (self.detectIsMobile()) {
        deviceMode = DeviceMode.mobile;
      }
      return deviceMode;
    }
  }))
  .actions(self => ({
    setClinics(dto: any[]) {
      self.clinics = dto as IObservableArray;
    },
    setCenter(dto: any[]) {
      self.center = dto as IObservableArray;
    },
    setUserInfo(dto: any) {
      if (dto.Clinic && dto.Clinic.SettingsTemplate) {
        self.clinicSettingsTemplate = dto.Clinic.SettingsTemplate;
      }
      if (dto.Clinic) {
        self.isUseWiFi = dto.Clinic.IsUseWiFi;
        self.isUseSH = dto.Clinic.IsUseSH;
        self.isUseEMR = dto.Clinic.IsUseEMR;
        self.wifiSettingsTemplate = {
          ssid: dto.Clinic.WiFiSSID,
          password: dto.Clinic.WiFiPWD
        };
        self.itContacts = dto.Clinic.ITContactInfo;
        self.idClinic = dto.Clinic.Id + "";
      }
      self.additionalClinics = dto.AdditionalClinics;
      if (dto.Physician && dto.Physician.NotificationCriteria) {
        self.notificationCriteria = dto.Physician.NotificationCriteria;
      } else {
        self.notificationCriteria = {};
      }
      if (dto.Physician && (dto.Physician.Name || dto.Physician.Name) && dto.Physician.Email) {
        self.firstName = dto.Physician.Name;
        self.lastName = dto.Physician.LastName;
        self.email = dto.Physician.Email;
        self.idPhysician = dto.Physician.Id;
        self.phoneNumber = dto.Physician.Phone;
        self.phone2 = dto.Physician.Phone2 || "";
        self.description = dto.Physician.Description || "";
        self.npi = dto.Physician.NPI;
      } else {
        self.firstName = dto.FirstName;
        self.lastName = dto.LastName;
        self.idPhysician = "";
        self.phoneNumber = dto.Phone;
        self.phone2 = dto.Phone2 || "";
        self.description = dto.Description || "";
        self.email = dto.Email || "";
      }
      self.disablePasscode = dto.DisablePasscode;
      self.optOut2FA = dto.OptOut2FA;
      self.waiver2FA = dto.Waiver2FA;
      self.allClinicLoad = dto.AllClinicLoad;
      self.portalUserLogin = dto.Login;
      self.isInvitingPatient = dto.IsInvitingPatient;
      self.inviting = isAdminsGroup(dto);
      self.usersGroup = dto.UsersGroup;
      self.isCenterAvailable = isCanSwitchBetweenCenters(dto);
      self.isAdminsRightsAvailable = dto.UsersGroupSTR === UsersGroupSTR.Support
        || dto.UsersGroupSTR === UsersGroupSTR.Superuser
        || dto.UsersGroupSTR === UsersGroupSTR.Admin;
      self.isStandartRightsAvailable = dto.UsersGroupSTR === UsersGroupSTR.Standard;
      self.idUser = dto.Id;
      self.usersGroupSTR = getUserGroup(dto);
      self.IdCurrentMC = dto.IdCurrentMC;
      self.signAs = dto.Initials || "";
      localStorage.setItem("idPhysician", `${self.idPhysician}`);
      const distributor = dto.Clinic && dto.Clinic.Distributor;
      dto.IdCurrentMC && FeatureFlagService.getInstance().setFeatureDetails(dto.IdCurrentMC, distributor?.Insurance);
    },
    setIsAuth(value: boolean) {
      self.isAuth = value;
    },
    setIsBannerShow(value: boolean) {
      self.isBannerShow = value;
    },
    setPortalUserAC(value: number | undefined) {
      self.PortalUserAC = value;
    },
    setPortalUserMC(value: number | undefined) {
      self.PortalUserMC = value;
    },
    setIdCurrentMC(value: number | undefined) {
      self.IdCurrentMC = value;
    },
    setNotifications(newNotifications: any[], lastNotifications: any[]) {
      self.newNotifications = newNotifications as IObservableArray;
      self.lastNotifications = lastNotifications as IObservableArray;
    },
    setProblemDevices(problemDevices: number) {
      self.problemDevices = problemDevices;
    }
  }))
  .actions(() => ({
    afterCreate() {
      const puMc = localStorage.getItem("PortalUserMC");
      API.defaults.headers.PortalUserMC = puMc;
    }
  }))
  .actions(self => {
    const getDataForSave = (state: any) => {
      function getValue(item: any) {
        if (item && item.value && item.value !== "None") {
          return +item.value;
        } else {
          return 0;
        }
      }
      function getValueServiceType(item: any) {
        if (item && item.value && +item.value !== 4) {
          return item.value;
        } else {
          return "3";
        }
      }
      function getHPDays(item: any, days: any) {
        if (item && item.value && +item.value === 4) {
          return getValue(days);
        } else {
          return "0";
        }
      }
      const obj: any = {
        "OperatingMode": getValueServiceType(state.serviceType),
        "TachyRateUrgent": getValue(state.TNotify),
        "TachyRateRoutine": getValue(state.TReport),
        "TachySensitivity": getValue(state.TNoOfBeats),
        "BradyRateUrgent": getValue(state.BNotify),
        "BradyRateRoutine": getValue(state.BReport),
        "BradySensitivity": getValue(state.BNoOfBeats),
        "PauseTimeUrgent": +getValue(state.PNotify) * 2,
        "PauseTimeRoutine": +getValue(state.PReport) * 2,
        "Duration": +getValue(state.duration) * 24,
        "IsAF": (state.reportA && state.reportA.value) || "false",
        "HPDays": getHPDays(state.serviceType, state.HPDays)
      };

      return { ...self.clinicSettingsTemplate, ...obj };
    };

    const getCurentUser = () => {
      return self;
    };

    const _getUser = flow(function* _getUser(email: string, password: string, token: string) {
      try {
        API.defaults.headers.Authorization = `Bearer ${token}`;
        localStorage.setItem("token", `Bearer ${token}`);

        const portalUser = yield getUserApi(email.toLowerCase(), password);
        if (portalUser.ok && portalUser.data) {
          self.setUserInfo(portalUser.data);
          self.setIsAuth(true);

          if (portalUser.data.AdditionalClinics.length && portalUser.data.Clinic) {
            const data = orderBy(
              portalUser.data.AdditionalClinics, (item: any) => item.Name)
              .map((item: any) => {
                return {
                  value: item.Id,
                  label: item.Name
                };
              });

            // self.setClinics([defaultValueClinicSelect].concat(data));
            self.setClinics(data);
            selectClinic(portalUser.data.Clinic.Id);
            getMonitoringCenters();
            return true;
          }
        }
      } catch (eror) {
        console.log(eror);
      }
    });

    const login = flow(function* login(email: string, password: string) {
      try {
        const resultLogin = yield loginApi(email.toLowerCase(), password);
        if (resultLogin.ok && resultLogin.data) {
          self.setIsBannerShow(true);
          if (!resultLogin.data.IsToken) {
            return { tel: resultLogin.data.Value };
          }
          return _getUser(email, password, resultLogin.data.Value);
        } else {
          self.setIsAuth(false);
          return false;
        }
      } catch (err) {
        self.setIsAuth(false);
        return false;
      }
    });

    const validateCode = flow(function* validateCode(email: string, password: string, code: string) {
      try {
        const resultValidateCode = yield validateCodeApi(email.toLowerCase(), code);
        if (resultValidateCode.ok && resultValidateCode.data) {
          self.setIsBannerShow(true);
          return _getUser(email, password, resultValidateCode.data);
        } else {
          self.setIsAuth(false);
          return false;
        }
      } catch (err) {
        self.setIsAuth(false);
        return false;
      }
    });

    const getClinicTemplate = (id: number) => {
      if (self.additionalClinics) {
        const activeClinic = self.additionalClinics.find((item: any) => +item.Id === id);
        if (activeClinic) {
          self.clinicSettingsTemplate = activeClinic.SettingsTemplate;
          self.idClinic = activeClinic.Id + "";
          self.clinicInfo = activeClinic;
        }
      }
    };

    const logout = () => {
      delete API.defaults.headers.Authorization;
      localStorage.removeItem("token");
      localStorage.removeItem("PortalUserAC");
      localStorage.removeItem("PortalUserMC");
      self.setIsAuth(false);
      setTimeout(() => {
        window.location.reload();
      }, 100);
    };

    const selectClinic = async(value: number) => {
      if (value !== -1) {
        API.defaults.headers.PortalUserAC = value;
        self.setPortalUserAC(value);
        localStorage.setItem("PortalUserAC", JSON.stringify(value));
        getClinicTemplate(value);
      } else {
        delete API.defaults.headers.PortalUserAC;
        self.setPortalUserAC(undefined);
        localStorage.removeItem("PortalUserAC");
      }
      getProblemDevices();
      getNotifications();
      return self.PortalUserAC;
    };

    const selectCenter = async(value: number | undefined) => {
      if (value && value !== -1) {
        API.defaults.headers.PortalUserMC = value;
        self.setPortalUserMC(value);
        localStorage.setItem("PortalUserMC", JSON.stringify(value));
      } else {
        delete API.defaults.headers.PortalUserMC;
        self.setPortalUserMC(undefined);
        localStorage.removeItem("PortalUserMC");
      }
      await checkAuthorized(true);
      return self.PortalUserMC;
    };

    const updateUserData = flow(function* updateUserData() {
      const userData = yield getCurentUserData();
      self.setUserInfo(userData);
    });

    const updateMFAction = flow(function* updateMFAction(formData: FormData) {
      const result = yield postUserMandatoryMFA(formData);
      if (result.ok) {
        // setTimeout(() => {
        //   window.location.reload();
        // }, 0);
        const userData = yield getCurentUserData();
        self.setUserInfo(userData);
        return true;
      } else {
        return false;
      }
    });

    const uniqIdClinicArray = (baseClinic: any, additionalClinic: any[]) => {
      const isExist = additionalClinic.find((item: any) => item.Id === baseClinic.Id);
      if (isExist) {
        return additionalClinic;
      } else {
        return [...additionalClinic, baseClinic];
      }
    };

    const checkAuthorized = flow(function* checkAuthorized(priority?: boolean) {
      const token = localStorage.getItem("token");
      if (token) {
        if (self.isAuth && !priority) {
          return;
        }

        API.defaults.headers.Authorization = token;

        const userData = yield getCurentUserData();
        self.setUserInfo(userData);
        self.setIsAuth(true);
        if (userData.AdditionalClinics.length || userData.Clinic) {
          const data = orderBy(
            uniqIdClinicArray(userData.Clinic, userData.AdditionalClinics), (item: any) => item.Name)
            .map((item: any) => {
              return {
                value: item.Id,
                label: item.Name
              };
            });
          // self.setClinics([defaultValueClinicSelect].concat(data));
          self.setClinics(data);
          const PortalUserAC = localStorage.getItem("PortalUserAC");
          if (priority) {
            selectClinic(userData.Clinic.Id);
            self.setPortalUserAC(userData.Clinic.Id);
          } else if (PortalUserAC) {
            selectClinic(+PortalUserAC);
          } else if (!self.PortalUserAC) {
            selectClinic(userData.Clinic.Id);
          }
          !priority && getMonitoringCenters();
          getProblemDevices();
          getNotifications();
        } else {
          self.setClinics([]);
        }
      } else {
        self.setIsAuth(false);
      }
    });

    const getNotifications = flow(function* getNotifications() {
      if (!self.idPhysician) {
        return false;
      }
      try {
        const result = yield getNotificationsApi(
          self.PortalUserAC && self.PortalUserAC >= 0 ? self.PortalUserAC : "-1", self.idPhysician);
        if (result.ok) {
          self.setNotifications(result.data.NewNotifications || [], result.data.ReviewedNotifications || []);
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    });

    const getProblemDevices = flow(function* getProblemDevices() {
      if (!self.idPhysician) {
        return false;
      }
      try {
        const result = yield getProblemDevicesApi();
        if (result.ok) {
          self.setProblemDevices(Number(result.data));
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    });

    const getMonitoringCenters = flow(function* getNotifications() {
      try {
        const result = yield getMonitoringCenterApi();
        if (result.ok) {
          const center = result.data.map((center: any) => {
            return {
              label: getClinicName(center),
              value: center.Id
            };
          });
          self.setCenter(center);
          const PortalUserMC = localStorage.getItem("PortalUserMC");
          if (PortalUserMC && PortalUserMC !== "null" && PortalUserMC !== "undefined") {
            selectCenter(+PortalUserMC);
          } else if (!self.PortalUserMC) {
            selectCenter(self.IdCurrentMC);
          }
        }
      } catch (error) {
        return false;
      }
    });

    const forgotPassword = flow(function* forgotPassword(email: string, url: string) {
      try {
        const result = yield forgotPasswordApi(email.toLowerCase(), url);
        return !!result.ok;
      } catch (error) {
        return false;
      }
    });

    const recoveryPassword = flow(function*
    recoveryPassword(password: string, userId: number | string) {
      try {
        const result = yield recoveryPasswordApi(password, userId);
        return !!result.ok;
      } catch (error) {
        return false;
      }
    });

    const invitePatientToApp = flow(function* invitePatientToApp(patientId: string | number) {
      try {
        if (self.idUser) {
          const result = yield invitePatientToAppApi(patientId);
          return !!result.ok;
        }
      } catch (error) {
        return false;
      }
    });

    const updateClinicTemplate = flow(function* (additional: any) {
      const data = getDataForSave(additional) as any;
      const str = JSON.stringify(data);
      try {
        const response = yield updateClinicDeviceSettingsTemplate(self.idClinic, str);
        if (response.ok) {
          self.clinicSettingsTemplate = data;

          return response.data;
        }
      } catch (error) {
        return false;
      }
    });

    const updateNotifications = flow(function* (notifications: any, selectedIdPhysician?: number | string) {
      const str = JSON.stringify(notifications);
      try {
        const response = yield updateNotificationsCriteria(selectedIdPhysician
          ? selectedIdPhysician.toString()
          : self.idPhysician + "", str);
        if (response.ok && response.data) {
          self.clinicSettingsTemplate = notifications;
          self.notificationCriteria = notifications;
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    });

    const updateAllUsersNotifications = flow(function* (notifications: any, isUserExistInClinic: boolean) {
      const str = JSON.stringify(notifications);
      try {
        const response = yield updateAllUsersNotificationsCriteria(self.PortalUserAC + "", str);
        if (response.ok && response.data) {
          if (isUserExistInClinic) {
            self.clinicSettingsTemplate = notifications;
            self.notificationCriteria = notifications;
          }
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    });

    const getCurentUserData = flow(function* () {
      try {
        const response = yield getCurentUserApi();
        if (response.ok) {
          return response.data;
        }
      } catch (error) {
        console.log(error);
      }
    });

    const updateProfileData = (data: any) => {
      self.firstName = data.Name;
      self.lastName = data.LastName;
      self.email = data.Email;
      self.portalUserLogin = data.Login;
      self.phoneNumber = data.Phone;
      self.phone2 = data.Phone2;
      self.description = data.Description;
      self.disablePasscode = data.DisablePasscode;
      self.allClinicLoad = data.AllClinicLoad;
      self.optOut2FA = data.OptOut2FA;
      self.waiver2FA = data.Waiver2FA;
      self.signAs = data.Initials;
    };

    return {
      getCurentUser,
      login,
      logout,
      selectClinic,
      selectCenter,
      getMonitoringCenters,
      checkAuthorized,
      forgotPassword,
      updateUserData,
      updateMFAction,
      getProblemDevices,
      recoveryPassword,
      invitePatientToApp,
      getNotifications,
      updateClinicTemplate,
      updateNotifications,
      updateAllUsersNotifications,
      validateCode,
      updateProfileData
    };
  });
