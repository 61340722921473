import { observer } from "mobx-react-lite";
import { CLDevicesListCategoriesWrapper } from "./cl-devices-list-categories.presents";
import { CLCategoryItem } from "../shared/cl-category-item";
import { CLPagination } from "../shared/cl-pagination";
import { useRootStore } from "../../stores/storeContext";
import { devicesListCountRows, DevicesListCategories, FCMID } from "../../boot/Constants";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export const CLAdministrationListCategories = observer(() => {
  const {
    activeCategory,
    page,
    total,
    setActiveCategory,
    nextPage,
    previousPage,
    allCategoryTotal,
    activeCategoryTotal,
    problemCategoryTotal,
    inventoryCategoryTotal
  } = useRootStore().listDevicesStore;
  const { IdCurrentMC } = useRootStore().userStore;
  const isROCMC = IdCurrentMC + "" === "20";
  const isFCM = IdCurrentMC + "" === FCMID + "";
  const { state: locationState } = useLocation();
  const categoriesTotals: any = {
    allCategoryTotal,
    activeCategoryTotal,
    inventoryCategoryTotal,
    problemCategoryTotal
  };
  useEffect(() => {
    if (locationState?.activeCategory) {
      const value = locationState?.activeCategory || "all";
      if (activeCategory !== value) {
        setActiveCategory(value, categoriesTotals[`${value}CategoryTotal`]);
      }
    }
  }, [locationState]);

  const changeListCategory = (value: string) => {
    setActiveCategory(value, categoriesTotals[`${value}CategoryTotal`]);
  };

  const getContactInfo = () => {
    if (isROCMC) {
      return {
        phone: "800-381-8593",
        email: "support@roctele.com",
        mailto: "mailto:support@roctele.com"
      };
    } else if (isFCM) {
      return {
        phone: "800-274-5399",
        email: "clinicsupport@fcminc.com",
        mailto: "mailto:clinicsupport@fcminc.com"
      };
    } else {
      return {
        phone: "866-324-8783",
        email: "support@rhythmexpressecg.com",
        mailto: "mailto:support@rhythmexpressecg.com"
      };
    }
  };

  return (
    <CLDevicesListCategoriesWrapper>
      <div className="nav">
        {DevicesListCategories.map(item => (
          <CLCategoryItem
            key={item.title}
            onChange={changeListCategory}
            title={item.title}
            icon={activeCategory === item.value ? item.iconActive : item.icon}
            count={categoriesTotals[`${item.value}CategoryTotal`]}
            value={item.value}
            isActive={activeCategory === item.value}
          ></CLCategoryItem>
        ))}
      </div>
      <div className="contacts">
        <div className="info">
          <div className="content">
            <div className="item">
              <div className="content-title">Customer Support</div>
              <div className="content-value">{getContactInfo().phone}</div>
            </div>
            <div className="item">
              <div className="content-title">Email</div>
              <div className="content-value">
                <a href={getContactInfo().mailto}>{getContactInfo().email}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pagination">
        {(total > devicesListCountRows) && <CLPagination
          total={total}
          page={page}
          countOnPage={devicesListCountRows}
          next={nextPage}
          previous={previousPage}></CLPagination>}
      </div>
    </CLDevicesListCategoriesWrapper>
  );
});
