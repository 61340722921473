import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLAppHeaderOnlyLogo } from "../../components/cl-app-header";
import { useNavigate, useParams } from "react-router";
import { ErrorMessages, SuccessMessages } from "../../boot/Constants";
import { VQAuthCreatePassword } from "vivaquant-components-library";

export const RecoveryPasswordPage = observer(() => {
  const { recoveryPassword } = useRootStore().userStore;
  const {
    password,
    passwordConfirm,
    invalidPassword,
    invalidPasswordConfirm,
    isSubmit,
    isShowPassword,
    isShowPasswordConfirm,
    isErrorRecovery,
    isLoading,
    setPassword,
    setPasswordConfirm,
    setSubmit,
    setShowPassword,
    setShowPasswordConfirm,
    setErrorRecovery,
    setLoading,
    cleanData
  } = useRootStore().recoveryFormStore;
  const navigate = useNavigate();
  const { userId } = useParams();

  const showPassword = () => {
    setShowPassword();
  };

  const showPasswordConfirm = () => {
    setShowPasswordConfirm();
  };

  const onSubmit = async(event: any) => {
    event.preventDefault();
    setSubmit();
    if (invalidPassword || invalidPasswordConfirm || !password || !passwordConfirm) { return; }
    if (password !== passwordConfirm) {
      return setErrorRecovery(ErrorMessages.recoveryPasswordsNotEqual);
    }
    setLoading(true);
    const result = await recoveryPassword(password, userId);
    if (result) {
      cleanData();
      navigate("/login", { state: { message: SuccessMessages.successRecovery } });
    } else {
      setErrorRecovery(ErrorMessages.recoveryError);
    }
    setLoading(false);
  };

  return (
    <VQAuthCreatePassword
      header={(<CLAppHeaderOnlyLogo></CLAppHeaderOnlyLogo>)}
      isLoading={isLoading}
      isErrorSave={isErrorRecovery}
      setPassword={setPassword}
      setPasswordConfirm={setPasswordConfirm}
      onSubmit={onSubmit}
      showPassword={showPassword}
      showPasswordConfirm={showPasswordConfirm}
      isSubmit={isSubmit}
      password={password}
      passwordConfirm={passwordConfirm}
      isShowPassword={isShowPassword}
      isShowPasswordConfirm={isShowPasswordConfirm}
      invalidPassword={invalidPassword}
      invalidPasswordConfirm={invalidPasswordConfirm}
    ></VQAuthCreatePassword>
  );
});
