import { observer } from "mobx-react-lite";
import { ClStudyCreate } from "../../../components/cl-study-create";
import { PatientStudyStyle } from "./PatientStudy.present";
import { useRootStore } from "../../../stores/storeContext";
import { useEffect } from "react";
import { CLSpiner } from "../../../components/shared/cl-spinner";

export const PatientStudy = observer(() => {
  const state = useRootStore().studyStore;
  const patientAdditional = useRootStore().patientCreateAdditionalModel;
  const { patient } = useRootStore().patientInfoStore;
  useEffect(() => {
    if (patient && patient.Device) {
      state.availableDevice.getAvailableDevice()
        .then(() => {
          setInitialState();
        });
    }
  }, [patient]);
  const additional: any = {};

  const getPatientFullName = (patient: any) => {
    return patient.Name + " " + patient.LastName;
  };

  const setInitialState = () => {
    const device = patient.Device.DeviceSettings;
    const initState = {
      availableDevice: patient.Device.Id,
      serviceType: (+device.HPDays) ? "4" : device.OperatingMode,
      duration: Math.round(device.Duration / 24),
      TNotify: device.TachyRateUrgent,
      TReport: device.TachyRateRoutine,
      TNoOfBeats: device.TachySensitivity,
      BNotify: device.BradyRateUrgent,
      BReport: device.BradyRateRoutine,
      BNoOfBeats: device.BradySensitivity,
      PNotify: +device.PauseTimeUrgent / 2,
      PReport: +device.PauseTimeRoutine / 2,
      reportA: device.IsAF,
      HPDays: device.HPDays || "None",
      isChangeDiagnostics: "false",
      shipToHome: "false"
    };
    // initState.id = device.Id;

    additional.DST = device.DST;
    additional.TimeZone = device.TimeZone;
    state.apply(initState, false, patient.Device.Status);
    patientAdditional.init({ patientId: patient.Patient.Id });
  };

  return (
    <PatientStudyStyle>
      {patient && patient.Patient && patient.Device
      && state.availableDevice.options.length
      && <ClStudyCreate
        patientId={patient.Patient.Id}
        patientName={getPatientFullName(patient.Patient)}
        additional={additional}
        status={patient.Device.Status}
        statusArchived={patient.ArchiveStatus}
        isEditMode={true}>
      </ClStudyCreate>
      || <div className="spiner"><CLSpiner id="ecg" boxShadow={"none"}></CLSpiner></div>
      }
    </PatientStudyStyle>
  );
});
