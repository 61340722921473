import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router-dom";
import { BillingStyles } from "./Billing.presents";
import { BillingListPage } from "./List";
import { BillingUtilizationPage } from "./Utilization";
import { ClBillingHeader } from "../../components/cl-billing-header";
import { useRootStore } from "../../stores/storeContext";
import { ClAlert2fa } from "../../components/cl-alert-2fa";
import { ClAction2fa } from "../../components/cl-action-2fa";
import { useEffect } from "react";
import { ClAlert2faHero } from "../../components/cl-alert-2fa-hero";
import { isFeatureFlagActive } from "../../services/feature-flag.service";
import { DisclaimerPage } from "./Disclaimer";

export const BillingPage = observer(() => {
  const { getItems, getUtilizationData } = useRootStore().billingStore;
  getItems();
  getUtilizationData();
  const { PortalUserMC, IdCurrentMC } = useRootStore().userStore;

  let isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");

  useEffect(() => {
    isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");
  }, [PortalUserMC, IdCurrentMC]);

  const getLink = (link: string) => {
    const pathArr = location.pathname.split("/");
    return `/${pathArr[1]}${link}`;
  };

  return (
    <BillingStyles>
      <ClBillingHeader></ClBillingHeader>
      <div className="content">
        <Routes>
          <Route path={"*"} element={<Navigate to={getLink("/list")} replace={true}/>} />
          <Route path={"/"} element={<Navigate to={getLink("/list")} replace={true}/>} />
          <Route path={"/list"} element={<BillingListPage />} />
          <Route path={"/utilization"} element={<BillingUtilizationPage />} />
          <Route path={"/disclaimer"} element={<DisclaimerPage />} />
        </Routes>
      </div>
      {isMandatoryMFA ? <ClAlert2fa/> : <ClAlert2faHero/>}
      <ClAction2fa/>
    </BillingStyles>
  );
});
