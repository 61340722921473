import { observer } from "mobx-react-lite";
import { Navigate, Route, Routes } from "react-router-dom";
import { UserProfilePage } from "./Profile/Profile.page";
import { UserStyles } from "./User.presents";

export const UserPage = observer(() => {

  const getLink = (link: string) => {
    const pathArr = location.pathname.split("/");
    return `/${pathArr[1]}${link}`;
  };

  return (
    <UserStyles>
      <div className="content">
        <Routes>
          <Route path={"*"} element={<Navigate to={getLink("/profile")} replace={true}/>}/>
          <Route path={"/"} element={<Navigate to={getLink("/profile")} replace={true}/>}/>
          <Route path={"/profile"} element={<UserProfilePage/>}/>
          {/* <Route path={`${url}/settings`} render={() => <UserProfilePage/>}/> */}
          {/* <Route path={`${url}/notification-settings`} render={() => <UserProfilePage/>}/> */}
        </Routes>
      </div>
    </UserStyles>
  );
});
