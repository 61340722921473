import { isFCMMC, isIDTFMC, isRA1MC, isRA2MC, isROCTELMC } from "../stores/patientInfo/patientInfo.helper.service";

export const features = {
  insuranceInfo: function(MC: number, forceEnabled?: boolean) {
    const idtf = isIDTFMC(MC);
    const ra2 = isRA2MC(MC);
    if (ra2 && forceEnabled) {
      return true;
    } else {
      return idtf;
    }
  },
  wifiAvailable: function(MC: number) {
    const idtf = isIDTFMC(MC);
    const ra1 = isRA1MC(MC);
    const ra2 = isRA2MC(MC);
    const isFCM = isFCMMC(MC);
    const isROCTEL = isROCTELMC(MC);
    return idtf || ra1 || ra2 || isFCM || isROCTEL;
  },
  sighScrubbedHolterReport: function(MC: number) {
    const fcm = isFCMMC(MC);
    const idtf = isIDTFMC(MC);
    const ra1 = isRA1MC(MC);
    const ra2 = isRA2MC(MC);

    return idtf || ra2 || ra1 || fcm;
  },
  sighScrubbedSummaryReport: function(MC: number) {
    const fcm = isFCMMC(MC);
    const idtf = isIDTFMC(MC);
    const ra1 = isRA1MC(MC);
    const ra2 = isRA2MC(MC);

    return idtf || ra2 || ra1 || fcm;
  },
  mandatoryMFA: function(MC: number) {
    const idtf = isIDTFMC(MC);
    const ra1 = isRA1MC(MC);
    const ra2 = isRA2MC(MC);
    return idtf || ra1 || ra2;
  },
  displayCPTCode: function(MC: number) {
    const ra1 = isRA1MC(MC);
    const ra2 = isRA2MC(MC);
    return ra1 || ra2;
  },
  physicianNPINumber: function(MC: number) {
    const idtf = isIDTFMC(MC);
    return idtf;
  },
  setupPhysicianNPINumber: function(MC: number) {
    const idtf = isIDTFMC(MC);
    const ra1 = isRA1MC(MC);
    const ra2 = isRA2MC(MC);
    return idtf || ra1 || ra2;
  },
  sevenHPDays: function(MC: number) {
    const ra1 = isRA1MC(MC);
    const ra2 = isRA2MC(MC);
    return ra1 || ra2;
  },
  displayDisclaimer: function(MC: number) {
    const ra1 = isRA1MC(MC);
    const ra2 = isRA2MC(MC);
    return ra1 || ra2;
  }
};

export class FeatureFlagService {
  static instance: FeatureFlagService;
  private mc: number | undefined;
  private forceEnabled: boolean = false;

  setFeatureDetails(mc: number, forceEnabled: boolean = false) {
    this.mc = mc;
    this.forceEnabled = forceEnabled;
  }

  public static getInstance() {
    if (!FeatureFlagService.instance) {
      FeatureFlagService.instance = new FeatureFlagService();
    }
    return FeatureFlagService.instance;
  }

  isFeatureFlagActive(feature: string) {
    if (!this.mc) return false;
    return features[feature as keyof typeof features](this.mc, this.forceEnabled);
  }
}

export const isFeatureFlagActive = (feature: string) => {
  return FeatureFlagService.getInstance().isFeatureFlagActive(feature);
};
