import styled from "styled-components";
import { theme } from "../../theme/theme";

export const CLPatientReportsMainWraper = styled.div`
  flex: 1;
  
  .sticky {
    z-index: 11;
  }

  .main {
    display: flex;

    .info {
      flex: 1;
      display: flex;
      align-items: center;

      .date {
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
        color: ${theme.colors.text};
      }

      .title {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: ${theme.colors.text};
  
        span {
          margin-left: 8px;
          font-size: 14px;
          cursor: pointer;
          color: ${theme.colors.blue};
        }
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .action {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: ${theme.colors.text};
        cursor: pointer;
        display: flex;
        align-items: center;

        .text {
          padding-left: 8px;
        }
      }

      .split-block {
        width: 1px;
        height: 22px;
        background: ${theme.colors.split};
        margin-left: 16px;
        margin-right: 16px;
      }

      .delete {
        color: ${theme.colors.red};
      }
    }

    .action-butons {
      display: flex;
      padding-left: 32px;

      .split-block {
        width: 16px;
      }
    }
  }
  .empty-state {
    font-size: 20px;
    margin-bottom: 0;
  }
  .summary {
    margin-top: 8px;
    position: relative;

    textarea {
      border: 1px solid ${theme.colors.greyBorder};
      box-sizing: border-box;
      border-radius: 8px;
      padding: 16px;
      resize: none;
      height: 156px !important;
      width: 100%;
      display: block;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
    }

    textarea::placeholder {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.text};
      opacity: 0.5;
    }

    .chart-length {
      position: absolute;
      top: 124px;
      display: inline-block;
      z-index: 100000000;
      right: 0;
      font-size: 12px;
      margin: 10px;
    }

    .error {
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: ${theme.colors.red};
      margin-top: 4px;
    }

    .error-textarea {
      border: 1px solid ${theme.colors.red};
    }
  }

  .pdf-container {
    .title {
      font-weight: normal;
      font-size: 18px;
      line-height: 20px;
      color: ${theme.colors.text};
      margin-top: 32px;
      margin-bottom: 16px;
    }

    canvas {
      width: 100% !important;
      height: auto !important;
    }
  }
  .report-no-signin {
    margin-bottom: 0;
    color: ${theme.colors.placeholder};;
  }
`;
