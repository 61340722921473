import { PropsType } from "./cl-app-header.props";
import { useLocation, useNavigate } from "react-router-dom";
import { HeaderStyle } from "./cl-app-header.presents";
import { theme } from "../../theme/theme";
import LogoImgAccess from "../../assets/img/rhythm-access.png";
import LogoImgRoc from "../../assets/img/roc-logo.png";
import LogoImgFmc from "../../assets/img/fmc-logo.png";
import EventIcon from "../../assets/icons/list-patients-event.svg";
import EventIconActive from "../../assets/icons/list-patients-event-active.svg";
import ExitIcon from "../../assets/icons/exit.svg";
import Profile from "../../assets/icons/list-patients-all-active.svg";
import NotificationsIcon from "../../assets/icons/notification.svg";
import DropdownIcon from "../../assets/icons/dropdown.svg";
import { CLNavItem } from "../shared/cl-nav-item";
import { CLIcon } from "../shared/cl-icon";
import { CLDropdown } from "../shared/cl-dropdown";
import { CLSelect } from "../shared/cl-select";
import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { AdditionalTabs, FCMID, HeaderTabs, ROCTELID } from "../../boot/Constants";
import { useState } from "react";
import { ClAppHeaderNotification } from "../cl-app-header-notification";
import { isLocationROC } from "../../services/handlers/path.handler";

export const CLAppHeader = observer((props: PropsType) => {
  const location = useLocation();
  const {
    selectClinic,
    selectCenter,
    clinics,
    center,
    isCenterAvailable,
    logout,
    updateUserData,
    firstName,
    lastName,
    IdCurrentMC,
    email,
    newNotifications,
    problemDevices,
    PortalUserAC,
    usersGroupSTR,
    PortalUserMC,
    notificationCriteria,
    detectIsMobile,
    setMobileMode
  } = useRootStore().userStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const navigate = useNavigate();
  const [isOpenNotification, setIsOpenNotification] = useState(false);
  const {
    setActiveCategory,
    getTotalCount: getTotalCountPatients
  } = useRootStore().listPatientsStore;
  const { getTotalCount: getTotalCountDevices } = useRootStore().listDevicesStore;
  const { getTotalCount: getTotalCountAdministration } = useRootStore().listAdministrationStore;
  const { getTotalCount: getTotalCountReports } = useRootStore().listReportsStore;
  const { initForm } = useRootStore().adminICD10Store;
  const stores = useRootStore();

  let isNotificationsAvailable = false;
  const isROCMC = IdCurrentMC + "" === ROCTELID + "";
  const isFMC = IdCurrentMC + "" === FCMID + "";

  if (notificationCriteria && Object.keys(notificationCriteria).length) {
    isNotificationsAvailable = true;
  }

  const resetPages = () => {
    Object.values(stores).forEach((store) => {
      if (store.setFirstPage) {
        store.setFirstPage();
      }
    });
  };

  const profileActions: any = [{
    text: "Profile",
    color: theme.colors.text,
    icon: Profile,
    action: () => {
      navigate("/user/profile");
    }
  }, {
    text: "Exit",
    color: theme.colors.red,
    icon: ExitIcon,
    action: () => {
      logout();
      navigate("/login");
    }
  }];

  if (detectIsMobile()) {
    profileActions.unshift({
      text: "Mobile Mode",
      color: theme.colors.text,
      action: () => {
        setMobileMode();
        window.location.reload();
      }
    });
  }

  if (isNotificationsAvailable) {
    profileActions.unshift({
      text: "Notifications",
      color: theme.colors.text,
      icon: NotificationsIcon,
      action: () => {
        navigate("/notifications");
      }
    });
  }

  const clinicianSelected = async(event: any) => {
    setGlobalSpiner(true);
    selectClinic(event.value);
    await updateUserData();
    await resetPages();
    if (location.pathname === HeaderTabs[0].link) {
      setActiveCategory("active", 0, true);
      await getTotalCountPatients();
    } else if (location.pathname === HeaderTabs[1].link) {
      await getTotalCountDevices();
    } else if (location.pathname === HeaderTabs[3].link) {
      await getTotalCountReports();
    } else if (location.pathname === HeaderTabs[4].link) {
      await getTotalCountAdministration();
      // await getClinicPhoneNumber();
    } else if (location.pathname === AdditionalTabs[0].link) {
      await initForm();
    } else {
      setGlobalSpiner(false);
    }

    setTimeout(() => {
      setGlobalSpiner(false);
    }, 500);
  };

  const centerSelected = async(event: any) => {
    setGlobalSpiner(true);
    await selectCenter(event.value);
    await resetPages();
    if (location.pathname === HeaderTabs[0].link) {
      setActiveCategory("active", 0, true);
      await getTotalCountPatients();
    } else if (location.pathname === HeaderTabs[1].link) {
      await getTotalCountDevices();
    } else if (location.pathname === HeaderTabs[3].link) {
      await getTotalCountReports();
    } else if (location.pathname === HeaderTabs[4].link) {
      await getTotalCountAdministration();
    } else if (location.pathname === AdditionalTabs[0].link) {
      await initForm();
    } else {
      setGlobalSpiner(false);
    }

    setTimeout(() => {
      setGlobalSpiner(false);
    }, 500);
  };

  const isActiveRoute = (item: any): boolean | undefined => {
    if (item.link === "/patients-list") {
      if (location.pathname === item.link) return true;
      if (location.pathname.indexOf("patient-info") + 1) return true;
      if (location.pathname.indexOf("edit-patient") + 1) return true;
      if (location.pathname.indexOf("study-create") + 1) return true;
      return false;
    } else if (item.link === "/billing") {
      if (location.pathname === item.link) return true;
      if (location.pathname.indexOf("billing/list") + 1) return true;
      if (location.pathname.indexOf("billing/utilization") + 1) return true;
      if (location.pathname.indexOf("billing/disclaimer") + 1) return true;
    } else if (item.link === "/administration-list") {
      if (location.pathname === item.link) return true;
      if (location.pathname.indexOf("administration-list/user-management") + 1) return true;
      if (location.pathname.indexOf("administration-list/icd10") + 1) return true;
      if (location.pathname.indexOf("administration-list/settings") + 1) return true;
      if (location.pathname.indexOf("administration-list/notifications") + 1) return true;
    } else {
      return location.pathname === item.link;
    }
  };

  const renderLogo = () => {
    if (isROCMC) return (<img className="logo" src={LogoImgRoc} alt=""></img>);
    if (isFMC) return (<img className="logo" src={LogoImgFmc} alt=""></img>);
    return (<img className="logo" src={LogoImgAccess} alt=""></img>);
  };

  return (
    <HeaderStyle shadow={true}>
      {renderLogo()}
      {props.navTabs.map((item, index) => {
        const isActive = isActiveRoute(item);
        return (
          <CLNavItem key={index.toString()} link={item.link} title={item.title} isActive={isActive}></CLNavItem>
        );
      })}
      <div className="left-element">
      </div>
      {isCenterAvailable && <div className="notification left-element">
        {center.length > 0 && <CLSelect
          width={"200px"}
          options={center}
          defaultValue={center.find((item: any) => item.value === PortalUserMC)}
          selectOption={centerSelected}></CLSelect>}
      </div>}
      <div className="notification left-element">
        {clinics.length > 0 && <CLSelect
          width={"200px"}
          options={clinics}
          isDisabled={clinics.length === 1}
          defaultValue={clinics.find((item: any) => item.value === PortalUserAC)}
          value={clinics.find((item: any) => item.value === PortalUserAC)}
          // defaultValue={defaultValueClinicSelect}
          selectOption={clinicianSelected}></CLSelect>}
      </div>
      <div className="notification notification-count" onClick={() => { setIsOpenNotification(!isOpenNotification); }}>
        <CLIcon
          count={newNotifications.length + problemDevices ? 1 : 0}
          icon={isOpenNotification ? EventIconActive : EventIcon}
          borderColor={theme.colors.background}
          active={isOpenNotification}
          alert={true}
          padding="7px"></CLIcon>
        <ClAppHeaderNotification isOpen={isOpenNotification}></ClAppHeaderNotification>
      </div>
      <CLDropdown
        text={firstName || lastName ? `${firstName} ${lastName} (${usersGroupSTR})` : `${email}`}
        icon={DropdownIcon}
        actions={profileActions}
        rotateIcon={true}></CLDropdown>
    </HeaderStyle>
  );
});

export const CLAppHeaderOnlyLogo = () => {
  const isRoc = isLocationROC();
  return (
    <HeaderStyle className="center">
      {isRoc
        ? <img className="logo-access" src={LogoImgRoc} alt=""></img>
        : <img className="logo-access" src={LogoImgAccess} alt=""></img>
      }
    </HeaderStyle>
  );
};
