import html2canvasModule from "html2canvas";
import { CLBlueButton, CLLightButton } from "../shared/cl-button";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CLModal } from "../shared/cl-modal";
import { CLSelect } from "../shared/cl-select";
import {
  PatientInfoCreateEventsModalContainerStyle,
  PatientInfoCreateEventsStyle
} from "./cl-patient-info-include-to-report.presents";
import { PropsType } from "./cl-patient-info-include-to-report.props";
import { ArrhythmiaType } from "../../stores/patientInfo/patientInfo.constant";
import { ClChartsEcgPreview } from "../cl-charts-ecg-preview";
import { CLInput } from "../shared/cl-input";
import * as Types from "../../services/api/api.types";
import API from "../../boot/Api";
import { useRootStore } from "../../stores/storeContext";
import {
  getECGEventDeltaStart,
  getECGEventStart
} from "../../stores/patientInfo/patientInfo.chartexport.service";
import { encodeData } from "../../services/handlers/data.handler";
import { getListECG } from "../../stores/patientInfo/patientInfo.helper.service";
import dayjs from "dayjs";

export const saveReport = async(recordingID: string, deviceId: string,
  jsonSegment: any, formData: FormData): Promise<any> => {
  const encodedData = encodeData({
    recordingID: recordingID,
    deviceId: deviceId,
    jsonSegment: JSON.stringify(jsonSegment),
    jsonRenderOptions: JSON.stringify({})
  });
  const result = API.post(`ClinicReports/AddSegment?${encodedData}`, formData);
  return await Types.getResponse(result);
};

function getActiveEventStartDate(): number {
  // const activeEventDate = getECGEventStart();
  const activeEventDate2 = getECGEventDeltaStart();
  return activeEventDate2;
}

export const ClPatientInfoIncludeToReport = observer((props: PropsType) => {
  const {
    patient,
    activeDate,
    events,
    activeEventType,
    getPatient,
    additionalEvents,
    activeFolderName
  } = useRootStore().patientInfoStore;
  const [fields, setFields] = useState({
    eventType: { value: { id: "3", label: "Pause", value: "Pause" } },
    caption: { value: "" }
  });

  if (!activeDate || !events) {
    return (<></>);
  }
  let date: string;
  if (activeDate.format) {
    date = activeDate.format("MM/DD/YYYY");
  }

  let activeEvent: any;
  if (additionalEvents && additionalEvents.EcgEvents && additionalEvents.EcgEvents.length) {
    activeEvent = additionalEvents.EcgEvents[0];
  } else if (activeFolderName) {
    const activeId = getListECG().find((eventEcg: any) => eventEcg.FolderName === activeFolderName)?.Id || 0;
    activeEvent = {
      FolderName: activeFolderName,
      IDReceivedData: activeId
    };
  } else {
    events.forEach((obj: any) => {
      if (date === obj.key) {
        obj.data.forEach((event: any) => {
          if (+event.Id === +activeEventType) {
            activeEvent = event;
          }
        });
      }
    });
  }

  const saveChanges = () => {
    const chartElement = document.getElementById("chart-ecg-preview");

    if (chartElement !== null) {
      html2canvasModule(chartElement, {
        scrollX: 0,
        scrollY: 0,
        allowTaint: true,
        useCORS: true,
        logging: false
      }).then(function(canvas) {
        // document.body.appendChild(canvas);
        // let dataURL = canvas.toDataURL();
        canvas.toBlob((blob: Blob | null) => {
          if (blob) {
            const formData = new FormData();
            formData.append("my-file", blob, "filename.png");

            const data = {
              IDReceivedData: activeEvent.IDReceivedData,
              FolderName: activeEvent.FolderName,
              StartFromBeginRD: getActiveEventStartDate(),
              EventType: fields.eventType.value && fields.eventType.value.id,
              EcgStripCaption: fields.caption.value
            };
            saveReport(patient.RecordingID, patient.Device.Id, data, formData)
              .then((response) => {
                if (response.ok) {
                  props.closeModal();
                  getPatient(patient.RecordingID, true);
                } else {
                  console.error("Something went wrong");
                }
              });
          }
        });
      });
    }
  };

  const handleSearchChange = (ev: any) => {
    const value = ev.target.value;
    setFields((prevValue) => ({ ...prevValue, caption: { value: value } }));
  };

  const selectOption = (ev: any) => {
    setFields((prevValue) => ({ ...prevValue, eventType: { value: ev } }));
  };

  // const isDisabledButton = (fields.caption.value && fields.eventType.value) ? "" : "disabled";
  const isDisabledButton = "";
  return (
    <PatientInfoCreateEventsStyle>
      <CLModal
        show={props.isShow}
        handleHideModal={() => props.closeModal()}
        title={"Create Event"}>
        <PatientInfoCreateEventsModalContainerStyle>
          <div className="modal-container" id="modal-container">
            <div className="title">
                            Include to Report
            </div>
            <div className="tabs">
              <p className="chart-time">{dayjs(getECGEventStart()).format("MM/DD/YYYY hh:mm:ss A")}</p>
              <div className="chart-ecg" id="chart-ecg-preview">
                <div className="chart">
                  <ClChartsEcgPreview></ClChartsEcgPreview>
                </div>
              </div>

              <div className="form">
                <div className="form-field">
                  <label>
                                        Event Type
                  </label>
                  <CLSelect
                    width="100%"
                    options={ArrhythmiaType}
                    selectOption={selectOption}
                    name={"type"}
                    defaultValue={fields.eventType.value}></CLSelect>
                </div>
                <div className="form-field">
                  <label>
                                        Caption
                  </label>
                  <CLInput handleChange={handleSearchChange}
                    placeholder="Add Caption"
                    width="100%"
                    name={"name"}
                    type={"text"}
                    value={fields.caption.value}></CLInput>
                </div>
              </div>
            </div>
            <div className="footer">
              <div className="btn-wrapper">
                <CLLightButton
                  click={props.closeModal}
                  text="Cancel"
                  width="200px"></CLLightButton>
              </div>

              <div className={"btn-wrapper " + isDisabledButton}>
                <CLBlueButton
                  click={saveChanges}
                  text="Save"
                  width="200px"></CLBlueButton>
              </div>
            </div>
          </div>
        </PatientInfoCreateEventsModalContainerStyle>

      </CLModal>

    </PatientInfoCreateEventsStyle>
  );
  // }
});
