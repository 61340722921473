export enum NotificationSelectNames {
  VTR1_V1 = "VTR1_V1",
  VTR1_V2 = "VTR1_V2",
  VTR2_V1 = "VTR2_V1",
  VTR2_V2 = "VTR2_V2",
  VTR2_V3 = "VTR2_V3",
  AF1_V1 = "AF1_V1",
  AF1_V2 = "AF1_V2",
  AF1_V3 = "AF1_V3",
  AF2_V1 = "AF2_V1",
  AF2_V2 = "AF2_V2",
  AF2_V3 = "AF2_V3",
  AF2_V4 = "AF2_V4",
  BR1_V1 = "BR1_V1",
  BR1_V2 = "BR1_V2",
  BR2_V1 = "BR2_V1",
  BR2_V2 = "BR2_V2",
  BR2_V3 = "BR2_V3",
  BR2_V4 = "BR2_V4",
  P1_V1 = "P1_V1",
  P2_V1 = "P2_V1",
  P2_V2 = "P2_V2",
  P2_V3 = "P2_V3",
  SVT_V1 = "SVT_V1",
  SVT_V2 = "SVT_V2",
  SDAVB_V = "SDAVB_V",
  CHB_V = "CHB_V",
}
