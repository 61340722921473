import { observer } from "mobx-react-lite";
import { AdministrationListStyle } from "./AdministrationList.presents";
import { useRootStore } from "../../stores/storeContext";
import React, { useEffect, useMemo } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { UserManagementPage } from "./userManagementPage";
import { ICD10Page } from "./icd10Page";
import { ReportsTabs } from "../../boot/Constants";
import { CLNavItem } from "../../components/shared/cl-nav-item";
import { Notifications } from "../Notifications";
import { CLCard } from "../../components/shared/cl-card";
import { SettingsPage } from "./settingsPage";
import { ClAlert2fa } from "../../components/cl-alert-2fa";
import { ClAction2fa } from "../../components/cl-action-2fa";
import { ClAlert2faHero } from "../../components/cl-alert-2fa-hero";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

export const AdministrationListPage = observer(() => {
  const { getTotalCount } = useRootStore().listAdministrationStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { PortalUserAC, PortalUserMC, IdCurrentMC } = useRootStore().userStore;
  const location = useLocation();
  let isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");

  useEffect(() => {
    isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");
  }, [PortalUserMC, IdCurrentMC]);

  useEffect(() => {
    setGlobalSpiner(true);
    getTotalCount().then(() => {
      setGlobalSpiner(false);
    });
  }, [PortalUserAC]);

  const currentPage = useMemo(() => {
    return `/${location.pathname.split("/")[2]}`;
  }, [location.pathname]);
  
  const getLink = (link: string) => {
    const pathArr = location.pathname.split("/");
    return `/${pathArr[1]}${link}`;
  };

  return (
    <AdministrationListStyle>
      <div className="header">
        <div className="nav-wraper">
          {ReportsTabs.map((item) => (<CLNavItem
            key={item.id}
            link={getLink(item.link)}
            title={item.title}
            isActive={item.link.indexOf(currentPage) !== -1}
            fontSize={"16px"}/>))}
        </div>
      </div>
      <div className="content">
        <Routes>
          <Route path={"*"} element={<Navigate to={getLink("/user-management")} replace={true}/>} />
          <Route path={"/"} element={<Navigate to={getLink("/user-management")} replace={true}/>} />
          <Route path={"/user-management"} element={<UserManagementPage />} />
          <Route path={"/icd10"} element={<ICD10Page />} />
          <Route path={"/settings"} element={<SettingsPage />} />
          <Route path={"/notifications"} element={(
            <div className="wrap">
              <CLCard padding="24px">
                <Notifications />
              </CLCard>
            </div>)} />
        </Routes>
      </div>
      {isMandatoryMFA ? <ClAlert2fa/> : <ClAlert2faHero/>}
      <ClAction2fa/>
    </AdministrationListStyle>
  );
});
