export enum NotificationRadioStates {
  VentricularFib = "VentricularFib",
  VentricularTach = "VentricularTach", // Ventricular Tachycardia (1)
  VentricularTachSecond = "VentricularTachSecond", // Ventricular Tachycardia (2)
  AtrialFib = "AtrialFib", // Atrial fibrillation(AF/AFL) (1)
  NewOnsetAF = "NewOnsetAF", // Atrial fibrillation(AF/AFL) (2)
  SevereBradycardia = "SevereBradycardia", // Bradycardia (1)
  Bradycardia = "Bradycardia", // Bradycardia (2)
  Asystole = "Asystole", // Pause (1)
  Pause = "Pause", // Pause (2)
  Tachycardia = "Tachycardia", // SVT (1)
  "DegAVBlock_Mobitz" = "DegAVBlock_Mobitz", // Second-degree AV Block (1)
  DegHB = "DegHB", // Complete Heart Block (1)
  Fainting = "Fainting"
}
