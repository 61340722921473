import { types, flow, getSnapshot } from "mobx-state-tree";
import {
  patientListCountRows,
  ListPatientsUrls,
  listPatientsSort,
  sortDirection,
  DeviceStatuses,
  ErrorMessages
} from "../boot/Constants";
import {
  getListPatients,
  getTotalPatientsCount,
  checkIsCanUseApi,
  sendCommandForDeviceApi,
  updateStatusDevicesApi,
  checkIsCanUseChangeStudyApi,
  getListHomeHookupPatients,
  getListHomeHookupCountApi,
  getListPendingStudyCountApi, getListPendingStudyPatients
} from "../services/api/list-patients.api";
import { getAllClinicPhysiciansApi } from "../services/api/clinic.api";
import { IObservableArray } from "mobx";
const availableSortHookup = ["patientname", "serial", "recordingid", "trackingnumber", "deviceclinic", "physician"];
const availableSortPendingStudy = ["patientname", "recordingid", "deviceclinic", "physician"];

let lastPortalUserAC = "";
export const ListPatientsModel = types
  .model("ListPatientsModel", {
    activeCategory: types.optional(types.string, "all"),
    page: 0,
    total: 0,
    allCategoryTotal: types.optional(types.number, 0),
    activeStatuses: types.optional(types.string, ""),
    activeCategoryTotal: types.optional(types.number, 0),
    eventsCategoryTotal: types.optional(types.number, 0),
    pendingCategoryTotal: types.optional(types.number, 0),
    archivedCategoryTotal: types.optional(types.number, 0),
    inactiveCategoryTotal: types.optional(types.number, 0),
    homeHookupCategoryTotal: types.optional(types.number, 0),
    pendingStudyCategoryTotal: types.optional(types.number, 0),
    searchValue: types.maybe(types.string),
    phusiciansFilterOptions: types.optional(types.array(types.frozen()), []),
    selectedPhusicians: types.optional(types.array(types.number), []),
    sortBy: types.optional(types.string, listPatientsSort.status),
    sortDirection: types.optional(types.number, sortDirection.ASC),
    deviceStatuses: types.optional(types.array(types.number), []),
    items: types.optional(types.array(types.frozen()), []),
    itemsHomeHookup: types.optional(types.array(types.frozen()), []),
    itemsPendingStudy: types.optional(types.array(types.frozen()), []),
    isLoading: types.optional(types.boolean, true)
  })
  .views((self) => ({
    getActivePatient(id: string) {
      let active = {};
      if (self.items) {
        active = self.items.find((patient: any) => patient.Id === id);
      }
      return active;
    }
  }))
  .actions(self => ({
    setList(dto: any[]) {
      self.items = dto as IObservableArray;
    },
    setListHomeHookup(dto: any[]) {
      self.itemsHomeHookup = dto as IObservableArray;
    },
    setListPendingStudy(dto: any[]) {
      self.itemsPendingStudy = dto as IObservableArray;
    },
    setLoading(isLoading: boolean) {
      self.isLoading = isLoading;
    },
    setPhusiciansFilterOptions(dto: any[]) {
      self.phusiciansFilterOptions = dto as IObservableArray;
    },
    setSelectedPhusicians(dto: any[]) {
      self.selectedPhusicians = dto as IObservableArray;
    },
    setDeviceStatuses(status: number) {
      self.deviceStatuses = [status] as IObservableArray;
    },
    setActiveStatuses(status: string) {
      self.activeStatuses = status;
    }
  }))
  .actions(self => {
    const setActiveCategory = (value: string, count: number, notReload: boolean) => {
      self.activeCategory = value;
      self.page = 0;
      self.total = count;
      if (value === "homeHookup") {
        !notReload && getListHomeHookup();
      } else if (value === "pendingStudy") {
        !notReload && getListPendingStudy();
      } else {
        !notReload && getList();
      }
    };

    const setFirstPage = () => {
      self.page = 0;
    };

    const nextPage = () => {
      self.page++;
      if (self.activeCategory === "homeHookup") {
        getListHomeHookup();
      } else if (self.activeCategory === "pendingStudy") {
        getListPendingStudy();
      } else {
        getList();
      }
    };

    const previousPage = () => {
      self.page--;
      if (self.activeCategory === "homeHookup") {
        getListHomeHookup();
      } else if (self.activeCategory === "pendingStudy") {
        getListPendingStudy();
      } else {
        getList();
      }
    };

    const defaultPage = () => {
      self.page = 0;
      if (self.activeCategory === "homeHookup") {
        getListHomeHookup();
      } else if (self.activeCategory === "pendingStudy") {
        getListPendingStudy();
      } else {
        getList();
      }
    };

    const getAllClinicPhysicians = flow(function* getAllClinicPhysicians() {
      const idPhysician = localStorage.getItem("idPhysician");
      const PortalUserAC = localStorage.getItem("PortalUserAC");
      if (lastPortalUserAC === PortalUserAC) return true;
      self.setSelectedPhusicians([]);
      const result = yield getAllClinicPhysiciansApi();
      lastPortalUserAC = PortalUserAC || "";
      if (result.ok && result.data.length) {
        const data = result.data.map((item: any) => {
          return {
            value: item.Id,
            label: `${item.Name} ${item.LastName}`
          };
        });
        self.setPhusiciansFilterOptions(data);
        if (idPhysician) {
          const active = data.find((option: any) => +option.value === +idPhysician);
          if (active) {
            self.setSelectedPhusicians([active.value]);
            return true;
          }
        }
        return data;
      } else if (result.ok) {
        self.setPhusiciansFilterOptions([]);
        return false;
      }
    });

    const getListHomeHookupCount = flow(function* getListHomeHookupCount() {
      self.setLoading(true);
      const rows = self.total && ((self.page * patientListCountRows + patientListCountRows) > self.total)
        ? self.total - (self.page * patientListCountRows)
        : patientListCountRows;
      const filter: any = {
        Offset: self.page * patientListCountRows,
        CountRows: rows
      };
      if (self.searchValue) {
        filter.Find = {
          patientname: self.searchValue,
          serial: self.searchValue,
          patientid: self.searchValue,
          recordingid: self.searchValue,
          trackingnumber: self.searchValue,
          deviceclinic: self.searchValue
        };
      }
      if (self.selectedPhusicians.length) {
        filter.WithPhysician = self.selectedPhusicians;
      }
      if (self.sortBy && self.sortDirection) {
        if (availableSortHookup.includes(self.sortBy)) {
          filter.SortBy = self.sortBy;
          filter.SortDirection = self.sortDirection;
        } else {
          filter.SortBy = "patientname";
          filter.SortDirection = sortDirection.ASC;
        }
      }
      try {
        const result = yield getListHomeHookupCountApi(filter);
        return result;
      } catch (error) {
        console.error(error);
      }
    });
    const getListPendingStudyCount = flow(function* getListPendingStudyCount() {
      self.setLoading(true);
      const rows = self.total && ((self.page * patientListCountRows + patientListCountRows) > self.total)
        ? self.total - (self.page * patientListCountRows)
        : patientListCountRows;
      const filter: any = {
        Offset: self.page * patientListCountRows,
        CountRows: rows
      };
      if (self.searchValue) {
        filter.Find = {
          patientname: self.searchValue,
          serial: self.searchValue,
          patientid: self.searchValue,
          recordingid: self.searchValue,
          trackingnumber: self.searchValue,
          deviceclinic: self.searchValue
        };
      }
      if (self.selectedPhusicians.length) {
        filter.WithPhysician = self.selectedPhusicians;
      }
      if (self.sortBy && self.sortDirection) {
        if (availableSortPendingStudy.includes(self.sortBy)) {
          filter.SortBy = self.sortBy;
          filter.SortDirection = self.sortDirection;
        } else {
          filter.SortBy = "patientname";
          filter.SortDirection = sortDirection.ASC;
        }
      }
      try {
        const result = yield getListPendingStudyCountApi(filter);
        return result;
      } catch (error) {
        console.error(error);
      }
    });

    const getListHomeHookup = flow(function* getListHomeHookup() {
      self.setLoading(true);
      const rows = self.total && ((self.page * patientListCountRows + patientListCountRows) > self.total)
        ? self.total - (self.page * patientListCountRows)
        : patientListCountRows;
      const filter: any = {
        Offset: self.page * patientListCountRows,
        CountRows: rows
      };
      if (self.searchValue) {
        filter.Find = {
          patientname: self.searchValue,
          serial: self.searchValue,
          patientid: self.searchValue,
          recordingid: self.searchValue,
          trackingnumber: self.searchValue,
          deviceclinic: self.searchValue
        };
      }
      if (self.selectedPhusicians.length) {
        filter.WithPhysician = self.selectedPhusicians;
      }
      if (self.sortBy && self.sortDirection) {
        if (availableSortHookup.includes(self.sortBy)) {
          filter.SortBy = self.sortBy;
          filter.SortDirection = self.sortDirection;
        } else {
          filter.SortBy = "patientname";
          filter.SortDirection = sortDirection.ASC;
        }
      }
      try {
        const result = yield getListHomeHookupPatients(filter);
        if (result.ok) {
          self.setListHomeHookup(result.data);
          self.setLoading(false);
        } else {
          self.setListHomeHookup([]);
          self.setLoading(false);
        }
      } catch (error) {
        self.setListHomeHookup([]);
        self.setLoading(false);
      }
    });
    
    
    const getListPendingStudy = flow(function* getListPendingStudy() {
      self.setLoading(true);
      const rows = self.total && ((self.page * patientListCountRows + patientListCountRows) > self.total)
        ? self.total - (self.page * patientListCountRows)
        : patientListCountRows;
      const filter: any = {
        Offset: self.page * patientListCountRows,
        CountRows: rows
      };
      if (self.searchValue) {
        filter.Find = {
          patientname: self.searchValue,
          serial: self.searchValue,
          patientid: self.searchValue,
          recordingid: self.searchValue,
          trackingnumber: self.searchValue,
          deviceclinic: self.searchValue,
          orderid: self.searchValue,
          ordertype: self.searchValue,
        };
      }
      if (self.selectedPhusicians.length) {
        filter.WithPhysician = self.selectedPhusicians;
      }
      if (self.sortBy && self.sortDirection) {
        if (availableSortHookup.includes(self.sortBy)) {
          filter.SortBy = self.sortBy;
          filter.SortDirection = self.sortDirection;
        } else {
          filter.SortBy = "patientname";
          filter.SortDirection = sortDirection.ASC;
        }
      }
      try {
        const result = yield getListPendingStudyPatients(filter);
        if (result.ok) {
          self.setListPendingStudy(result.data);
          self.setLoading(false);
        } else {
          self.setListPendingStudy([]);
          self.setLoading(false);
        }
      } catch (error) {
        self.setListPendingStudy([]);
        self.setLoading(false);
      }
    });

    const getList = flow(function* getList() {
      self.setLoading(true);
      const rows = self.total && ((self.page * patientListCountRows + patientListCountRows) > self.total)
        ? self.total - (self.page * patientListCountRows)
        : patientListCountRows;
      const filter: any = {
        Offset: self.page * patientListCountRows,
        CountRows: rows
      };

      if (self.searchValue) {
        filter.Find = {
          name: self.searchValue,
          lastname: self.searchValue,
          serialnumber: self.searchValue,
          patientid: self.searchValue,
          recordingid: self.searchValue
        };
      }
      const deviceStatuses = self.deviceStatuses[0] || self.deviceStatuses[0] === 0;

      if (self.deviceStatuses && (deviceStatuses && self.deviceStatuses[0] + 1)) {
        filter.Find = { ...filter.Find, devicestatus: JSON.stringify(self.deviceStatuses) };
      }

      if (self.selectedPhusicians.length) {
        filter.WithPhysician = self.selectedPhusicians;
      }

      if (self.sortBy && self.sortDirection) {
        filter.SortBy = self.sortBy;
        filter.SortDirection = self.sortDirection;
      }

      const listItem = ListPatientsUrls.find(i => i.key === self.activeCategory);

      try {
        const result = yield getListPatients(filter, listItem ? listItem.url : ListPatientsUrls[0].url);
        if (result.ok) {
          self.setList(result.data);
          self.setLoading(false);
        } else {
          self.setList([]);
          self.setLoading(false);
        }
      } catch (error) {
        console.error(error);
        self.setList([]);
        self.setLoading(false);
      }
    });

    const getTotalCount = flow(function* getTotalCount() {
      yield getAllClinicPhysicians();
      const filter: any = {
      };

      if (self.searchValue) {
        filter.Find = {
          name: self.searchValue,
          lastname: self.searchValue,
          serialnumber: self.searchValue,
          patientid: self.searchValue,
          recordingid: self.searchValue
        };
      }
      const deviceStatuses = self.deviceStatuses[0] || self.deviceStatuses[0] === 0;

      if (self.deviceStatuses && (deviceStatuses && self.deviceStatuses[0] + 1)) {
        filter.Find = { ...filter.Find, devicestatus: JSON.stringify(self.deviceStatuses) };
      }

      if (self.selectedPhusicians.length) {
        filter.WithPhysician = self.selectedPhusicians;
      }
      try {
        const results = yield Promise.all([
          getTotalPatientsCount(filter),
          getListHomeHookupCount(),
          getListPendingStudyCount()
        ]);
        const result = results[0];
        const result2 = results[1];
        const result3 = results[2];
        if (result.ok) {
          self.allCategoryTotal = result.data.All || 0;
          self.activeCategoryTotal = result.data.ActiveData || 0;
          self.eventsCategoryTotal = result.data.WithNewEvents || 0;
          self.pendingCategoryTotal = result.data.PendingDiagnosis || 0;
          self.archivedCategoryTotal = result.data.ArchiveData || 0;
          self.inactiveCategoryTotal = result.data.Free || 0;
          self.homeHookupCategoryTotal = result2.data;
          self.pendingStudyCategoryTotal = result3.data;
          switch (self.activeCategory) {
            case ListPatientsUrls[0].key:
              self.total = self.allCategoryTotal;
              break;
            case ListPatientsUrls[1].key:
              self.total = self.activeCategoryTotal;
              break;
            case ListPatientsUrls[2].key:
              self.total = self.eventsCategoryTotal;
              break;
            case ListPatientsUrls[3].key:
              self.total = self.pendingCategoryTotal;
              break;
            case ListPatientsUrls[4].key:
              self.total = self.archivedCategoryTotal;
              break;
            case ListPatientsUrls[5].key:
              self.total = self.inactiveCategoryTotal;
              break;
            default:
              break;
          }
          if (self.activeCategory === "homeHookup") {
            self.total = self.homeHookupCategoryTotal;
            getListHomeHookup();
          } else if (self.activeCategory === "pendingStudy") {
            self.total = self.pendingStudyCategoryTotal;
            getListPendingStudy();
          } else {
            getList();
          }
        }
      } catch (error) {
        console.error(error);
      }
    });

    const setSearch = (searachValue? : string) => {
      self.searchValue = searachValue;
    };

    const selectePractice = (data: any) => {
      self.setSelectedPhusicians(data.map((item: any) => { return item.value; }));
      getTotalCount();
    };

    const setSortBy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getList();
    };
    const setSortByHookup = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getListHomeHookup();
    };
    const setSortByPendingStudy = (sortBy: string, sortDirection: number) => {
      self.sortBy = sortBy;
      self.sortDirection = sortDirection;
      getListPendingStudy();
    };

    const updateStatusSearch = (status: number) => {
      self.setDeviceStatuses(status);
      getTotalCount();
    };

    const isNoPatients = () => {
      if (self.searchValue || self.selectedPhusicians.length) {
        return false;
      } else if (!self.allCategoryTotal || self.allCategoryTotal === 0) {
        return true;
      } else {
        return false;
      }
    };

    const checkIsCanUse = flow(function* checkIsCanUse(
      item: any,
      status: number,
      idDevice: number | string,
      idPatient?: number | string,
      archiveStatus?: number,
      recordingId?: any
    ) {
      try {
        if (item.isEdit) {
          return true;
          // return !!((DeviceStatuses.Archived === status
          //   || DeviceStatuses.Complete === status
          //   || DeviceStatuses.Standby === status
          //   || DeviceStatuses.Recording === status
          //   || DeviceStatuses.Configured === status));
        } else if (item.isStart) {
          return !!idPatient;
          // return idPatient && !!((DeviceStatuses.Standby === status
          //   || DeviceStatuses.Ready === status
          //   || DeviceStatuses.Recording === status
          //   || DeviceStatuses.Configured === status)) || !status; // 2, 3, 4, 7

          // const result = yield checkIsCanUseApi(item.checkUrl, { patientId: idPatient, deviceId: idDevice });
          // if (result.ok) {
          //   return result.data;
          // } else {
          //   return true;
          // }
        } else if (item.isEnrollmentChange) {
          const result = yield checkIsCanUseChangeStudyApi(item.checkUrl, { recordingId: recordingId });
          if (result.ok) {
            return result.data;
          } else {
            return true;
          }
          // MANUAL CALCULATION
          //   let dateEvent = null;
          //   if ((status !== DeviceStatuses.Configured) && deviceStatistics) {
          //     dateEvent = isNullDate(deviceStatistics.DeviceRecordingEndDate)
          //       ? dayjs(deviceStatistics.DeviceRecordingStartDate)
          //         .add(deviceSettings.Duration, "hours")
          //       : deviceStatistics.DeviceRecordingEndDate;
          //   }
          //
          //   const date1 = moment(dateEvent).add("30", "day");
          //   const date2 = moment();
          //   const diff = date1.diff(date2);
          //   return diff > 0 && !!((DeviceStatuses.Archived === status
          //     || DeviceStatuses.Complete === status
          //     || DeviceStatuses.InTransit === status));
        } else if (item.checkArchiveStatus && archiveStatus && archiveStatus > 0) {
          return false;
        } else if (item.checkInTransitStatus && status === DeviceStatuses.InTransit) {
          return false;
        } else {
          const result = yield checkIsCanUseApi(item.checkUrl, { deviceId: idDevice });
          if (result.ok) {
            return result.data;
          } else {
            return true;
          }
        }
      } catch (error) {
        return true;
      }
    });

    const sendCommandForDevice = flow(function* sendCommandForDevice(
      item: any,
      status: number,
      idDevice: number | string,
      idPatient: number | string,
      data: any
    ) {
      try {
        self.setLoading(true);
        const isCanUse = yield checkIsCanUse(item, status, idDevice, idPatient);
        if (isCanUse) {
          const result = yield sendCommandForDeviceApi(item.url, data);
          if (!result.ok) {
            alert(ErrorMessages.actionCanNotUse);
          }
        } else {
          alert(ErrorMessages.actionCanNotUse);
        }
        getList();
      } catch (error) {
        alert(ErrorMessages.actionCanNotUse);
        getList();
      }
    });

    const updatePatientComment = (id: string, comment: string) => {
      const newObj = JSON.parse(JSON.stringify(getSnapshot(self.items)));
      newObj.map((item: any) => {
        if (item.Patient.Id + "" === id) {
          item.Patient.PhysicianComment = comment;
          return item;
        }
        return item;
      });
      self.setList(newObj);
    };

    const updateStatusDevices = flow(function* updateStatusDevices() {
      try {
        if (self.items && self.items.length) {
          const idDevicesArr = self.items.map((item) => {
            if (item.Device) {
              return item.Device.Id;
            }
          });
          if (!idDevicesArr.length) { return; }
          const result = yield updateStatusDevicesApi(idDevicesArr);
          if (result.ok) {
            const newList = self.items.map((item) => {
              if (item.Device) {
                const device = { ...item.Device };
                device.Status = result.data[0];
                result.data.splice(0, 1);
                return { ...item, Device: device };
              } else {
                return { ...item };
              }
            });
            self.setList(newList);
          }
        }
      } catch (error) {
        console.error(error);
      }
    });

    return {
      setActiveCategory,
      nextPage,
      defaultPage,
      previousPage,
      getList,
      getTotalCount,
      setSearch,
      getAllClinicPhysicians,
      getListHomeHookupCount,
      selectePractice,
      setSortBy,
      setSortByHookup,
      setSortByPendingStudy,
      updateStatusSearch,
      sendCommandForDevice,
      isNoPatients,
      checkIsCanUse,
      updatePatientComment,
      updateStatusDevices,
      setFirstPage
    };
  });
