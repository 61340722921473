import styled from "styled-components";
import { theme } from "./theme";

export const GlobalStyles = styled.div`
  min-height: 100vh;
  background: ${theme.colors.background};

  * {
    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700&display=swap');

    -webkit-tap-highlight-color: transparent;
    //font-family: -apple-system, BlinkMacSystemFont, "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .global-spiner {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
  }

  .bold {
    font-weight: bold;
  }
  @media(max-width: 740px) {
    min-height: 100vh;
    //padding-top: 15px;
  }
`;
