import styled from "styled-components";
import { theme } from "../../theme/theme";

export const HeaderStyle = styled.div<{shadow?: boolean}>`
  background: ${theme.colors.light};
  ${props => props.shadow ? "box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.04);" : ""}
  height: ${theme.size.header};
  padding-left: ${theme.offsets.mainContent};
  padding-right: ${theme.offsets.mainContent};
  display: flex;
  align-items: center;
  @media(max-width: 1780px) {
    padding-left: 20px;
    padding-right: 20px;
  }
  &.center {
    justify-content: center;
  }

  .logo {
    height: 49px;
    width: auto;
    margin-right: 70px;
  }

  .logo-access {
    height: 64px;
    width: auto;
    margin-top: 24px;
  }

  .left-element {
    margin-left: auto;
  }

  .notification {
    margin-left: 24px;
    cursor: pointer;
    .cl-select__input-container {
      margin: 0;
      padding-top: 0;
    }
  }

  .profile-img {
    box-shadow: 8px 8px 16px rgba(38, 86, 186, 0.04);
    border-radius: 8px;
    height: 40px;
    width: 40px;
    overflow: hidden;
    margin-left: 24px;
    margin-right: 8px;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .notification-count {
    margin-left: 24px;
    margin-right: 8px;
  }

  .cl-select__value-container {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    height: 38px;
  }
`;
