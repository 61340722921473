import { types, flow } from "mobx-state-tree";
import API from "../../boot/Api";
import { IObservable } from "mobx";
import { BeatsType, fetchECGLastDates } from "../../components/cl-charts-canvas/cl-charts-canvas.service";
import {
  generateList,
  getStoredBeats,
  isIncludeStoredBeats, setListECG,
  setStoredBeats,
  setValidBeats
} from "./patientInfo.helper.service";
import * as Types from "../../services/api/api.types";
import { EventActionCP, sortDirection } from "../../boot/Constants";
import { encodeData } from "../../services/handlers/data.handler";
export interface DataBeatsType {
    data: BeatsType
}

export const PatientInfoModel = types
  .model("PatientInfoModel", {
    graph: types.optional(types.array(types.frozen()), []),
    patient: types.optional(types.frozen(), {}),
    activeDate: types.optional(types.frozen(), []),
    activeFolderName: types.optional(types.string, ""),
    activeEventType: types.optional(types.number, 0),
    events: types.optional(types.frozen(), []),
    reports: types.optional(types.frozen(), []),
    additionalEvents: types.optional(types.frozen(), {}),
    activeECG: types.optional(types.frozen(), null),
    isEmptyEvents: types.optional(types.boolean, false),
    sortEvents: types.optional(types.number, sortDirection.DESC),
    patientBase: types.optional(types.frozen(), {}),
    timeZones: types.optional(types.frozen(), []),
    activeTab: types.optional(types.string, "reports"),
    lastEcg: types.optional(types.frozen(), {}),
    isLastEcgEmpty: types.optional(types.boolean, false)
  })
  .actions(self => ({
    setPatient(patient: any) {
      self.patient = patient as IObservable;
    },
    setLastEcg(data: any) {
      self.lastEcg = data;
    }
  }))
  .actions(self => {
    const resetPatientInfo = () => {
      self.graph.clear();
      self.patient = {};
      self.activeDate = [];
      self.activeFolderName = "";
      self.activeEventType = 0;
      self.events = [];
      self.reports = [];
      self.additionalEvents = {};
      self.isEmptyEvents = true;
      self.activeECG = null;
      self.lastEcg = {};
      self.activeTab = "reports";
      setValidBeats([]);
      setListECG([]);
    };

    const getBeatsData = async(patientId: string, date: string): Promise<any> => {
      const encodedData = encodeData({
        recordingID: patientId,
        selectedDay: date
      });
      const result = API.get(`HSData/BeatsData?${encodedData}`);
      return await Types.getResponse(result);
    };

    const getDataPatient = async(patientId: string): Promise<any> => {
      const encodedData = encodeData({
        recordingID: patientId
      });
      const result = API.get(`HSData/Full?${encodedData}`);
      return await Types.getResponse(result);
    };

    const getDataBasePatient = async(patientId: string | number): Promise<any> => {
      const encodedData = encodeData({
        idPatient: patientId
      });
      const result = API.post("ClinicPatients/Patient", encodedData);
      return await Types.getResponse(result);
    };

    const getTimeZone = async(): Promise<any> => {
      const result = API.get("/ClinicPatients/TimeZones");
      return await Types.getResponse(result);
    };

    const setEventsActionSTAT = async(idEvents: number[] | string[], status: number | string): Promise<any> => {
      const encodedData = encodeData({
        jsonIdEvents: JSON.stringify(idEvents),
        actionSTAT: status
      });
      const result
        = API.post(`ClinicReports/SetEventsActionSTAT?${encodedData}`);
      return await Types.getResponse(result);
    };

    const includeToReport = async(idEvent: (number | string)[], isInclude: boolean): Promise<any> => {
      const encodedData = encodeData({
        jsonIdEvents: JSON.stringify(idEvent),
        isIncludeToReport: isInclude
      });
      const result = API.post(`ClinicReports/SetEventsIncludedToReport?${encodedData}`);
      return await Types.getResponse(result);
    };

    const trackEvent = flow(function* (id: string | number) {
      try {
        const response = yield setEventsActionSTAT([id] as string[] | number[], EventActionCP.STATReviewed);
        if (response.ok) {
          getPatient(self.patient.RecordingID, true);
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    });

    const getStatesOptions = flow(function* (patientId, date) {
      if (isIncludeStoredBeats(patientId, date)) {
        return getStoredBeats(patientId, date);
      } else {
        try {
          const response = yield getBeatsData(patientId, date);
          setStoredBeats(patientId, date, response.data);
          return response;
        } catch (error) {
          console.log("error happens");
        }
      }
    });
    const setEvents = (receivedData: any) => {
      const list = generateList(receivedData);
      self.events = list;
      self.isEmptyEvents = false;
    };

    const getPatient = flow(function* (patientId: string, forcibly?: boolean) {
      if (!forcibly && self.patient.RecordingID + "" === patientId + "") {
        return;
      }
      function setPatientResponse(data: any) {
        if (data) {
          if (data.ReceivedDatas) {
            setEvents(data.ReceivedDatas);
          }
          self.setPatient(data);
        }
      }
      try {
        const response = yield getDataPatient(patientId);
        if (response.ok) {
          setPatientResponse(response.data);
          return response.data;
        } else {
          return null;
        }
      } catch (error) {
        return null;
      }
    });

    const getLastEcg = flow(function* (patientId: string) {
      try {
        self.isLastEcgEmpty = false;
        const response = yield fetchECGLastDates(patientId);
        if (response.ok) {
          self.setLastEcg(response.data);
        } else {
          self.isLastEcgEmpty = true;
        }
      } catch (error) {
        self.isLastEcgEmpty = true;
      }
    });

    const getPatientBase = flow(function* (patientId: string) {
      try {
        const response = yield getDataBasePatient(patientId);
        const responseTZ = yield getTimeZone();
        self.timeZones = responseTZ.data as IObservable;
        self.patientBase = response.data as IObservable;
        return self.patientBase;
      } catch (error) {
        console.error(error);
      }
    });

    const getPatientReports = flow(function* (patientId: string) {
      if (self.patient.RecordingID + "" === patientId + "") {
        return;
      }

      try {
        const encodedData = encodeData({
          recordingID: patientId
        });
        const response = yield API.get(`HSData/Full?${encodedData}`);
        self.reports = response;
      } catch (error) {
        console.error(error);
      }
    });

    const setActiveDate = (active: any, isEmpty?: boolean) => {
      if (isEmpty) {
        self.isEmptyEvents = true;
      }
      self.activeDate = active;
      self.activeECG = null;
    };

    const setActiveFolderName = (active: string) => {
      self.activeFolderName = active;
      self.isEmptyEvents = false;
    };

    const setActiveEventType = (active: number) => {
      self.activeEventType = active;
      self.isEmptyEvents = false;
      setAdditionalEvents({});
    };

    const setAdditionalEvents = (active: any) => {
      self.additionalEvents = active;
      self.isEmptyEvents = false;
      if (active.FolderName) {
        setActiveFolderName(active.FolderName);
      }
    };

    const setActiveECG = (active: any) => {
      self.activeECG = active;
    };

    const setEventIncludeToReport = flow(function* (ids: (number | string)[], isInclude: boolean) {
      try {
        const response = yield includeToReport(ids, isInclude);
        if (response) {
          const eventsList = JSON.parse(JSON.stringify(self.events));
          eventsList.forEach((events: any) => {
            events.data && events.data.forEach((event: any) => {
              ids.forEach((id: number | string) => {
                if (+event.Id === +id) {
                  event.IncludedToReport = isInclude;
                }
              });
            });
          });
          self.events = eventsList;
        }
      } catch (error) {
        console.log(error);
      }
    });

    const changeStatusEvents = flow(function* (ids: number[] | string[], status: string | number) {
      try {
        const response = yield setEventsActionSTAT(ids, status);
        if (response) {
          const eventsList = JSON.parse(JSON.stringify(self.events));
          eventsList.forEach((events: any) => {
            events.data && events.data.forEach((event: any) => {
              ids.forEach((id: number | string) => {
                if (+event.Id === +id) {
                  event.EventActionCP = status;
                  if (status === EventActionCP.STATRejected) {
                    event.IncludedToReport = false;
                  }
                }
              });
            });
          });
          self.events = eventsList;
        }
      } catch (error) {
        console.error(error);
      }
    });

    const changeSortEventsType = (type: number) => {
      self.sortEvents = type;
    };

    const setActiveTab = (type: string) => {
      self.activeTab = type;
    };

    const getState = flow(function* (id: number | string) {
      try {
        const response = yield API.get("ClinicPatients/States");
        const findState = response.data.find((item: any) => item.Id.toString() === id.toString());
        if (findState) {
          return findState.USState;
        } else {
          return "-";
        }
      } catch (error) {
        console.log("error happens");
        return "-";
      }
    });

    return {
      getStatesOptions,
      getPatient,
      getPatientReports,
      setActiveDate,
      setActiveFolderName,
      setActiveEventType,
      setEvents,
      setAdditionalEvents,
      resetPatientInfo,
      setActiveECG,
      setEventIncludeToReport,
      changeStatusEvents,
      trackEvent,
      changeSortEventsType,
      getPatientBase,
      setActiveTab,
      getDataBasePatient,
      getState,
      getLastEcg
    };
  });
