import React from "react";
import { UserStoreModel } from "./user.store";
import { ListPatientsModel } from "./list-patients.store";
import { CreateUserModel } from "./createUser/createUser.model";
import { InitialState } from "./createUser/initial.state";
import { PatientInfoModel } from "./patientInfo/patientInfo.store";
import { LoginFormModel } from "./authorization/login.store";
import { ForgotFormModel } from "./authorization/forgot.store";
import { RecoveryFormModel } from "./authorization/recovery.store";
import { ReportStoreModel } from "./report.store";
import { ListAdministrationModel } from "./list-administration.store";
import { StudyModel } from "./study/study.model";
import { InitialState as InitialStateStudy } from "./study/initial.state";
import { InviteAdminModel } from "./invite-admin/invite-admin.store";
import { InitialState as InitialStateInviteAdmin } from "./invite-admin/initial.state";
import { InitialState as InitialStateWiFiSetup } from "./wifi-setup/initial.state";
import { GlobalStoreModel } from "./global.store";
import { ListDevicesModel } from "./list-devices.store";
import { BillingModel } from "./billing/billing.store";
import { NotificationModel } from "./notification/notification.store";
import { SupportModel } from "./support.store";
import { ProfileModel } from "./profile/profile.model";
import { ProfileInitialState } from "./profile/profile";
import { ListReportsModel } from "./list-reports.store";
import { AdminICD10Model } from "./admin-icd10/admin-icd10.store";
import { InitialStateAdminICD10 } from "./admin-icd10/initial.state";
import { StudyEnrollmentChangeModel } from "./study-enrollment-change.store";
import { StudyEnrollmentInitialState } from "./study/study-enrollment.state";
import { WiFiSetupModel } from "./wifi-setup/wifi-setup.store";
import { PatientCreateAdditionalModel } from "./cl-patient-create-additional.store";
import { AdditionalInfoInitialState } from "./createUser/additional-info-initial.state";
import { UploadInsuranceCardImagesModel } from "./upload-insurance-card-images.store";

type RootStoreContextValue = {
  listPatientsStore: any,
  userStore: any,
  createUserStore: any,
  patientInfoStore: any,
  studyStore: any,
  loginFormStore: any,
  forgotFormStore: any,
  recoveryFormStore: any,
  reportStore: any,
  listAdministrationStore: any,
  wifiSetupStore: any,
  inviteAdminStore: any,
  adminICD10Store: any,
  globalStore: any,
  listDevicesStore: any,
  notificationStore: any,
  billingStore: any,
  supportStore: any,
  profileStore: any,
  studyEnrollmentChangeModel: any,
  uploadInsuranceCardImagesStore: any,
  patientCreateAdditionalModel: any,
  listReportsStore: any
}

const RootStoreContext = React.createContext<RootStoreContextValue>({} as RootStoreContextValue);

const context = {
  userStore: UserStoreModel.create(),
  listPatientsStore: ListPatientsModel.create(),
  patientInfoStore: PatientInfoModel.create(),
  createUserStore: CreateUserModel.create(InitialState),
  studyStore: StudyModel.create(InitialStateStudy),
  loginFormStore: LoginFormModel.create(),
  forgotFormStore: ForgotFormModel.create(),
  recoveryFormStore: RecoveryFormModel.create(),
  reportStore: ReportStoreModel.create(),
  listAdministrationStore: ListAdministrationModel.create(),
  inviteAdminStore: InviteAdminModel.create(InitialStateInviteAdmin),
  wifiSetupStore: WiFiSetupModel.create(InitialStateWiFiSetup),
  adminICD10Store: AdminICD10Model.create(InitialStateAdminICD10),
  globalStore: GlobalStoreModel.create(),
  listDevicesStore: ListDevicesModel.create(),
  billingStore: BillingModel.create(),
  notificationStore: NotificationModel.create(),
  supportStore: SupportModel.create(),
  profileStore: ProfileModel.create(ProfileInitialState),
  studyEnrollmentChangeModel: StudyEnrollmentChangeModel.create(StudyEnrollmentInitialState),
  uploadInsuranceCardImagesStore: UploadInsuranceCardImagesModel.create(),
  patientCreateAdditionalModel: PatientCreateAdditionalModel.create(AdditionalInfoInitialState),
  listReportsStore: ListReportsModel.create()
};

 
export const RootStoreProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  return <RootStoreContext.Provider value={context}>{children}</RootStoreContext.Provider>;
};
export const useRootStore = () => React.useContext(RootStoreContext);
