import { applySnapshot, flow, Instance, SnapshotIn, types } from "mobx-state-tree";
import { UserFormModel } from "../createUser/user.model";
import { ProfileInitialState } from "./profile";
import { useRootStore } from "../storeContext";
import { message } from "antd";
import {
  updateUserProfileApi,
  profileSendCodeApi,
  profileValidateCodeApi
} from "../../services/api/user.api";
import { getValidPhoneNumber } from "../../services/handlers/data.handler";
import { updatePhysicianNPI } from "../../services/api/administration.api";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

export const ProfileModel = types
  .model("CreateUserModel", {
    id: types.identifier,
    firstName: types.maybe(UserFormModel),
    lastName: types.maybe(UserFormModel),
    email: types.maybe(UserFormModel),
    username: types.maybe(UserFormModel),
    signAs: types.maybe(UserFormModel),
    phoneNumber: types.maybe(UserFormModel),
    phone2: types.maybe(UserFormModel),
    description: types.maybe(UserFormModel),
    twoFactorAuth: types.maybe(UserFormModel),
    optOutTwoFactorAuth: types.maybe(UserFormModel),
    allClinicLoad: types.maybe(UserFormModel),
    npi: types.maybe(UserFormModel),
    isInitied: types.optional(types.boolean, false),
    showModal: types.optional(types.boolean, false),
    idPhysician: types.optional(types.string, ""),
    IdCurrentMC: types.optional(types.string, "")
  })
  .actions(self => ({
    setDefaultValueByKey(key: string, defaultValue: any) {
      if (self[key as keyof typeof self]) {
        (<any>self[key as keyof typeof self]).value = defaultValue.value;
        (<any>self[key as keyof typeof self]).defaultValue = defaultValue;
        (<any>self[key as keyof typeof self]).name = (<any>self[key as keyof typeof self]).name + " ";
      }
    },
    openModal() {
      self.showModal = true;
    },
    closeModal() {
      self.showModal = false;
    }
  }))
  .actions(self => {
    const initProfile = () => {
      if (self.firstName) {
        self.firstName.value = useRootStore().userStore.firstName || "";
      }
      if (self.lastName) {
        self.lastName.value = useRootStore().userStore.lastName || "";
      }
      if (self.email) {
        self.email.value = useRootStore().userStore.email || "";
      }
      if (self.username) {
        self.username.value = useRootStore().userStore.portalUserLogin || "";
      }
      if (self.signAs) {
        self.signAs.value = useRootStore().userStore.signAs || "";
      }
      if (self.phoneNumber) {
        self.phoneNumber.value = getValidPhoneNumber(useRootStore().userStore.phoneNumber);
      }
      if (self.phone2) {
        self.phone2.value = getValidPhoneNumber(useRootStore().userStore.phone2);
      }
      if (self.description) {
        self.description.value = getValidPhoneNumber(useRootStore().userStore.description);
      }
      if (self.twoFactorAuth) {
        self.twoFactorAuth.value = !useRootStore().userStore.disablePasscode + "";
      }
      if (self.optOutTwoFactorAuth) {
        self.optOutTwoFactorAuth.value = useRootStore().userStore.optOut2FA + "";
      }
      if (self.allClinicLoad) {
        self.allClinicLoad.value = useRootStore().userStore.allClinicLoad + "";
      }
      if (self.npi) {
        self.npi.value = useRootStore().userStore.npi + "";
      }

      self.idPhysician = useRootStore().userStore?.idPhysician?.toString() || "";
      self.IdCurrentMC = useRootStore().userStore?.IdCurrentMC?.toString() || "";
      self.isInitied = true;
    };

    const getDataForSave = () => {
      return {
        Name: self.firstName && self.firstName.value,
        LastName: self.lastName && self.lastName.value,
        Email: self.email && self.email.value,
        Phone: self.phoneNumber && getValidPhoneNumber(self.phoneNumber.value),
        Phone2: self.phone2 && getValidPhoneNumber(self.phone2.value),
        Description: self.description && getValidPhoneNumber(self.description.value),
        Organization: self.lastName && self.lastName.value,
        Login: self.username && self.username.value,
        DisablePasscode: self.twoFactorAuth && self.twoFactorAuth.value + "" === "false",
        OptOut2FA: self.optOutTwoFactorAuth && self.optOutTwoFactorAuth.value + "" === "true",
        AllClinicLoad: self.allClinicLoad && self.allClinicLoad.value + "" === "true",
        Initials: self.signAs && self.signAs.value,
        Waiver2FA: false
      };
    };

    const sendSaveRequest = flow(function* (data: any) {
      const str = JSON.stringify(data);
      const key = "updatable";
      try {
        const response = yield updateUserProfileApi(str);
        if (response.ok) {
          // message.success({ content: "User profile successfully updated!", key, duration: 2 });

          const isPhysicianNPINumber = isFeatureFlagActive("setupPhysicianNPINumber");

          if (isPhysicianNPINumber && self.idPhysician) {
            yield updatePhysicianNPI(self.idPhysician, self.npi?.value || "");
          }
          window.location.reload();
          return data;
        } else if (response.error) {
          message.error({ content: response.error, key, duration: 2 });
          return response.error;
        }
        return false;
      } catch (error) {
        message.error({ content: "Error, Something went wrong!", key, duration: 2 });
        return error;
      }
    });

    const validateCode = flow(function* (authCode: string) {
      try {
        const resultValidateCode = yield profileValidateCodeApi((self.email?.value || "").toLowerCase(), authCode);
        if (resultValidateCode.ok) {
          return saveForm(true);
        } else {
          return false;
        }
      } catch (err) {
        return false;
      }
    });

    const saveForm = (
      skipPhoneValidation: boolean,
      phoneOrAuthChanged?: boolean,
      oldDisablePasscode?: boolean,
      oldWaiver2FA?: boolean
    ) => {
      const data = getDataForSave();
      if (!skipPhoneValidation && phoneOrAuthChanged) {
        data.DisablePasscode = oldDisablePasscode;
        data.Waiver2FA = !!oldWaiver2FA;
        return sendSaveRequest(data)
          .then((response: any) => {
            return response;
          });
      } else {
        data.DisablePasscode = self.twoFactorAuth && self.twoFactorAuth.value + "" === "false";
        data.Waiver2FA = !!oldWaiver2FA;
        return sendSaveRequest(data);
      }
    };

    const prepareSaveForm = flow(
      function* (
        skipPhoneValidation: boolean,
        phoneOrAuthChanged?: boolean,
        oldDisablePasscode?: boolean,
        oldWaiver2FA?: boolean
      ) {
        const data = getDataForSave();
        if (!skipPhoneValidation && phoneOrAuthChanged) {
          data.DisablePasscode = oldDisablePasscode;
          data.Waiver2FA = !!oldWaiver2FA;
          try {
            const email = self.email?.value || "";
            const number = getValidPhoneNumber(self.phoneNumber?.value || "");
            const response = yield profileSendCodeApi(email, number);
            if (response.ok) {
              self.openModal();
            }
          } catch (error) {
            console.error(error);
          }
        } else {
          data.DisablePasscode = self.twoFactorAuth && self.twoFactorAuth.value + "" === "false";
          data.Waiver2FA = !!oldWaiver2FA;
          return sendSaveRequest(data);
        }
      });
    function reset() {
      applySnapshot(self, ProfileInitialState);
    }

    return {
      initProfile,
      saveForm,
      prepareSaveForm,
      reset,
      validateCode
    };
  });

export interface IProfileModel extends Instance<typeof ProfileModel> {}
export interface IProfileModelSnapShot extends SnapshotIn<typeof ProfileModel> {}
