import { observer } from "mobx-react-lite";
import { PatientInfoReportsStyle } from "./PatientInfoReports.presents";
import { CLPatientReportsMain } from "../../../components/cl-patient-reports-main";
import { CLPatientReportsList } from "../../../components/cl-patient-reports-list";
import { useRootStore } from "../../../stores/storeContext";
import { useEffect, useState } from "react";
import { CLSpiner } from "../../../components/shared/cl-spinner";
import { useLocation } from "react-router-dom";

export const PatientInfoReports = observer(() => {
  const { getAllReports, isLoading, latestReportID } = useRootStore().reportStore;
  const { patient } = useRootStore().patientInfoStore;
  const [loadingPatientData, setLoadingPatientData] = useState(false);
  const { state: locationState } = useLocation();

  useEffect(() => {
    if (patient && patient.RecordingID) {
      setLoadingPatientData(false);
      let ReportId = "";
      if (locationState && locationState.returnToReports) {
        ReportId = locationState.returnToReports;
      }
      if (latestReportID === patient.RecordingID + "_") {
        return;
      }
      getAllReports(patient.RecordingID, !!ReportId, ReportId);
    } else {
      setLoadingPatientData(true);
    }
  }, [patient.RecordingID]);
  const loading = loadingPatientData || isLoading;
  return (
    <PatientInfoReportsStyle>
      {(!loading) && <CLPatientReportsMain></CLPatientReportsMain>}
      {(loading) && <div style={{ flex: 1 }}></div>}
      <CLPatientReportsList></CLPatientReportsList>
      {loading && <div className="spiner"><CLSpiner></CLSpiner></div>}
    </PatientInfoReportsStyle>
  );
});
