import { CreateUserStyle } from "./CreateUserPage.presents";
import { CLPatientCreate } from "../../components/cl-patient-create";
import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getAllClinicPhysiciansApi } from "../../services/api/clinic.api";

export const CreateUserPage = observer(() => {
  const state = useRootStore().createUserStore;
  const { patientId } = useParams();
  const { state: locationState } = useLocation();

  useEffect(() => {
    Init();
  }, [patientId]);

  useEffect(() => {
    getAllClinicPhysiciansApi();
  }, []);

  function Init() {
    if (state.state) {
      state.state.getStatesOptions().then((res: any) => {
        console.log(res);
      });
    }
    if (state.orderingPhysician) {
      state.orderingPhysician.getClinics()
        .then((res: any) => {
          console.log(res);
        });
    }
    if (state.readingPhysician) {
      state.readingPhysician.getClinics()
        .then((res: any) => {
          console.log(res);
        });
    }
    if (state.ICD10Code) {
      if (!state.ICD10Code.options.length) {
        state.ICD10Code.getICD10();
      }
    }
  }

  return (
    <CreateUserStyle>
      {(patientId && locationState && locationState.patientData)
        && state.ICD10Code && state.ICD10Code.options.length
        && <CLPatientCreate patientData={locationState.patientData}/>}
      {(!patientId || !locationState || !locationState.patientData) && <CLPatientCreate patientData={undefined}/>}
    </CreateUserStyle>
  );
});
