import { Navigate, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { ClPatientInfoHeader } from "../../components/cl-patient-info-header";
import { PatientInfoEvents } from "./Events";
import { PatientInfoReports } from "./Reports";
import { PatientStudy } from "./Study";
import { useRootStore } from "../../stores/storeContext";
import { PatientInfoStyles } from "./PatientsInfo.presents";
import { useMemo, useState } from "react";

export const PatientInfoPage = () => {
  const { getPatient, getLastEcg } = useRootStore().patientInfoStore;
  const { patientId } = useParams();
  const { state: locationState } = useLocation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isScrubbed, setIsScrubbed] = useState(false);

  const getLink = (link: string) => {
    const pathArr = location.pathname.split("/");
    return `/${pathArr[1]}/${pathArr[2]}${link}`;
  };

  useMemo(async() => {
    let forcibly = false;
    if (locationState && locationState.returnToReports) {
      forcibly = true;
    }
    if (pathname.indexOf("/study")) {
      forcibly = true;
    }

    Promise.all([getPatient(patientId, forcibly), getLastEcg(patientId)])
      .then((response) => {
        if (!response[0]) {
          navigate("/patient-list", { replace: true });
        } else {
          if (response[0].Device?.DeviceSettings?.ArchiveStatus === 2) {
            setIsScrubbed(true);
            // if route events, redirect to reports
            if (pathname.includes("events")) {
              navigate(getLink("/reports"), { replace: true });
            }
            if (pathname.includes("study")) {
              navigate(getLink("/reports"), { replace: true });
            }
          }
        }
      });
  }, [patientId]);

  return (
    <PatientInfoStyles>
      <div>
        <ClPatientInfoHeader></ClPatientInfoHeader>

        <div className="content">
          <Routes>
            <Route path={"*"} element={<Navigate to={!isScrubbed ? getLink("/events") : getLink("/reports")} 
              replace={true}/>} />
            <Route path={"/"} element={<Navigate to={!isScrubbed ? getLink("/events") : getLink("/reports")} 
              replace={true}/>} />
            {!isScrubbed ? <Route path={"/events"} element={<PatientInfoEvents />} /> : null}
            <Route path={"/reports"} element={<PatientInfoReports />} />
            {!isScrubbed ? <Route path={"/study"} element={<PatientStudy />} /> : null}
          </Routes>
        </div>
      </div>
    </PatientInfoStyles>
  );
};
