import { useState } from "react";
import { useRootStore } from "../../stores/storeContext";
import { observer } from "mobx-react-lite";
import { CLBlueButton, CLRedButton } from "../shared/cl-button";
import { CLModal } from "../shared/cl-modal";
import { CL2faWrapper } from "./cl-alert-2fa.presets";
import { CLIcon } from "../shared/cl-icon";
import LargeLockIcon from "../../assets/icons/large-lock.svg";
import SmallLockIcon from "../../assets/icons/small-lock.svg";
import { isSuperUserGroup } from "../../stores/helpers/user.store.helpers";
import { useNavigate } from "react-router-dom";

export const ClAlert2fa = observer(() => {
  const {
    disablePasscode,
    is2FABannerShow,
    setIsBannerShow,
    setIsAction2FA,
    usersGroupSTR
  } = useRootStore().userStore;

  const isDisplayAlert = is2FABannerShow && disablePasscode;
  const isSuperUser = isSuperUserGroup(usersGroupSTR);
  const [showAlert, setShowAlert] = useState(isDisplayAlert);
  const navigate = useNavigate();

  const onHide = () => {
    setIsBannerShow(false);
    setShowAlert(false);
  };

  const handleClick = () => {
    onHide();
    navigate("/user/profile", { state: { highlight2AF: true } });
  };
  const handleClickIgnore = () => {
    onHide();
    setIsAction2FA(true);
  };

  const renderChild = () => {
    return (<CL2faWrapper>
      <div className="modal-container">
        <div className="icon-wrapper"><CLIcon width="64px" height="64px" icon={LargeLockIcon}></CLIcon></div>
        <div className="title">
          Please, turn on two-factor authentication
          <br/>to protect your account
        </div>

        <p className="description">
          {`70% of all ransomware attacks in the U.S are targeted at small healthcare facilities.
          Help to protect your practice or facility from unauthorized access
          by using a second authentication method in addition to your password.`}</p>
        <div className="footer">
          {!isSuperUser
            ? <div className="btn-wrapper">
              <CLRedButton
                click={handleClickIgnore}
                text="Ignore"
                width="200px"></CLRedButton>
            </div>
            : null}
          <div className="btn-wrapper">
            <CLBlueButton
              click={handleClick}
              icon={<CLIcon icon={SmallLockIcon}></CLIcon>}
              text="Protect My Account"
              width="200px"></CLBlueButton>
          </div>
        </div>
      </div>
    </CL2faWrapper>);
  };

  return (
    <CLModal
      title=""
      show={showAlert}
      hideCloseBtn={true}
      backdrop={"static"}
      dialogClassName="modal-dialog-small"
      handleHideModal={onHide}
    >
      {renderChild()}
    </CLModal>
  );
});
