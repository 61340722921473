import { observer } from "mobx-react-lite";
import { CLStudyCreateWrapper } from "./cl-study-create.presents";
import { useRootStore } from "../../stores/storeContext";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component";
import { CLBlueButton, CLGreenButton, CLLightButton } from "../shared/cl-button";
import { useState, useEffect } from "react";
import { getSnapshot } from "mobx-state-tree";
import { message, Calendar } from "antd";
import { dynamicValidationFunction, ValidateField } from "./cl-study-create.service";
import { ISelectOptionsModelSnapShot } from "../../stores/createUser/options.model";
import { DeviceStatuses } from "../../boot/Constants";
import { ClStudyCreateModal } from "../cl-study-create-modal/cl-study-create-modal";
import { ClStudyConfirmModal } from "../cl-study-confirm-modal/cl-study-confirm-modal";
import { StudyModalDataModel } from "../cl-study-create-modal/cl-study-create-modal.props";
import { CLSpiner } from "../shared/cl-spinner";
import { getDurationOptions } from "../../stores/study/initial.state";
import { ClPatientInfoHeader } from "../cl-patient-info-header";
import { generatePDFService } from "./cl-study-print.service";
import { isRX3Device } from "../../services/handlers/data.handler";
import { ClRadio } from "../shared/cl-radio";
import API from "../../boot/Api";
import * as Types from "../../services/api/api.types";
import { confirmAlert } from "react-confirm-alert";
import { isFeatureFlagActive } from "../../services/feature-flag.service";
import { ClTooltip } from "../shared/cl-tooltip";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const defaultModalData = {
  isShow: false,
  title: "",
  isShowStudy: false,
  isStudy: false,
  studyData: {} as StudyModalDataModel
};

const getClinicsPhysicians = async() => {
  const result = API.get("ClinicPhysicians");
  return await Types.getResponse(result);
};

const studyDurationChangeNote = `Note: Duration change may fail if the study ends before
the request is processed or device is in area with poor wireless connectivity.`;

export const ClStudyCreate = observer((props: {
  patientId: string | number,
  patientName: string,
  additional?: any,
  status?: string | number,
  statusArchived?: string | number,
  isEditMode?: boolean
}) => {
  const { patient, patientBase } = useRootStore().patientInfoStore;
  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const [isHidedDuration, setIsHidedDuration] = useState(false);
  const [modalShow, setModalShow] = useState(defaultModalData);
  const [modalConfirmShow, setModalConfirmShow] = useState({ isShow: false });
  const [modalConfigureConfirmShow, setModalConfigureConfirmShow] = useState({ isShow: false });
  const [isShowDeviceWarning, setIsShowDeviceWarning] = useState(false);
  const [isDurationChanged, setIsDurationChanged] = useState(false);
  const [stateRadio, setStateRadio] = useState(0);
  const [clinicsPhysicians, setClinicsPhysicians] = useState([]);
  const [isShipToHomeAvailable, setIsShipToHomeAvailable] = useState(false);
  const usagePatient = patient && patient.Patient || patientBase;
  const additionalClinics = useRootStore().patientCreateAdditionalModel;
  const patientModel = useRootStore().createUserStore;

  const navigate = useNavigate();
  const state = useRootStore().studyStore;
  const {
    updateClinicTemplate,
    isInvitingPatient,
    invitePatientToApp
  } = useRootStore().userStore;
  const isInsuranceInfo = isFeatureFlagActive("insuranceInfo");
  const isPhysicianNPINumber = isFeatureFlagActive("physicianNPINumber");
  const isSevenHPDays = isFeatureFlagActive("sevenHPDays");

  const isPDFAvailable = isInsuranceInfo;
  const isChangedDiagnosticsClinicAvailable = isInsuranceInfo;
  const isHPDays = +state.HPDays.value > 0;
  const [isHPDisclaimerShow, setIsHPDisclaimerShow] = useState(false);
  const isInsuranceValid = additionalClinics.isValid;
  const isCheckingValid = additionalClinics.isCheckingValid;

  const [isHolter, setIsHolter] = useState(isHPDays);
  const [isChangeDiagnosticsDisplayed, setIsChangeDiagnosticsDisplayed]
    = useState(isChangedDiagnosticsClinicAvailable
    && !props.isEditMode && state.serviceType.getActiveObject().label === "MCT");
  const isChangedTypeDisplayed = isChangeDiagnosticsDisplayed && state.isChangeDiagnostics.value === "true";
  const isInvalidInsuranceInfo = () => {
    if (isInsuranceInfo) {
      return !isInsuranceValid;
    } else {
      return false;
    }
  };

  const getPatientClinicUseSH = () => {
    const clinic = usagePatient.PhysicianOrderingClinicName;
    if (clinic && patientModel.activeClinic) {
      setIsShipToHomeAvailable(patientModel.activeClinic?.IsUseSH);
    }
  };

  const isStudyAvailable = () => {
    if (state.shipToHome.value === "true") {
      return false;
    }
    if (props.statusArchived && props.statusArchived !== 0) {
      return false;
    }
    if (props.status && (DeviceStatuses.Ready === props.status || DeviceStatuses.Configured === props.status)) {
      return true;
    } else if (props.isEditMode) {
      return false;
    } else {
      return true;
    }
  };
  const isConfigureAvailable = () => {
    if (props.statusArchived && props.statusArchived !== 0) {
      return false;
    }
    if (props.status) {
      return DeviceStatuses.Standby === props.status || DeviceStatuses.Ready === props.status
        || DeviceStatuses.Recording === props.status || DeviceStatuses.Configured === props.status;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (props && props.patientId) {
      setFormInvalid(false);
    }
    getClinicsPhysicians().then((response: any) => {
      const arr = response.data.map((item: any) => ({
        value: item.Name + " " + item.LastName,
        label: item.Name + " " + item.LastName,
        id: item.Id + "",
        npi: item.NPI
      }));
      setClinicsPhysicians(arr);
    });
  }, []);

  useEffect(() => {
    getPatientClinicUseSH();
  }, [patientBase, patient.Patient, patientModel.activeClinic?.Id]);

  useEffect(() => {
    state.setOptionsHPDays(isSevenHPDays ? 7 : 2);
  }, [isSevenHPDays]);

  useEffect(() => {
    setIsHPDisclaimerShow((+state.HPDays.value > 2));
  }, [state.HPDays?.value, state.serviceType.value]);

  const setDurationOptions = (count: number) => {
    if (state.isDurationIncreaseMode) {
      state.duration.setOptions(getDurationOptions(count, state.isDurationIncreaseMode));
    } else {
      state.duration.setOptions(getDurationOptions(count));
    }
  };

  const setIs14Duration = (is14D: boolean) => {
    const count: number = is14D ? 14 : 30;
    setDurationOptions(count);
    if (is14D && +state.duration.value > 14) {
      setIsHidedDuration(true);
      setTimeout(() => {
        const newSelectedOption = state.duration.options.find((option: ISelectOptionsModelSnapShot) => {
          return +option.value === 1;
        });
        if (newSelectedOption) {
          state.duration.setValue(newSelectedOption.value);
          state.setDefaultValueByKey("duration", {
            value: "" + newSelectedOption.value,
            label: "" + newSelectedOption.label
          });
        }
        setIsHidedDuration(false);
      }, 200);
    }
  };

  const openConfirmationModal = (
    event: any,
    title: string,
    isStudy: boolean,
    isShowStudy = false,
    isCheckWarning = false
  ) => {
    if (isChangedTypeDisplayed) {
      const value = state.changedType.options[stateRadio]?.value;
      state.changedType.setValue(value);
    }
    event.preventDefault();
    if (!validateForm(getSnapshot(state))) {
      setFormInvalid(true);
      message.error({ content: "Please fill the fields!", duration: 2 });
    } else {
      if (isCheckWarning && isPhysicianNPINumber) {
        const usagePatient = patient && patient.Patient || patientBase;

        if (clinicsPhysicians && clinicsPhysicians.length) {
          const active: any = clinicsPhysicians.find((item: any) => +item.id === +usagePatient.IDPhysicianOrdering);
          if (active && !active.npi) {
            return confirmAlert({
              // eslint-disable-next-line max-len
              message: `To initiate the study, please get in touch with the Admin to input the NPI number for ${active.value}, or if you're the designated physician, you can do it yourself by navigating to the Profile Page.`,
              overlayClassName: "cl-confirm-modal",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {}
                }
              ]
            });
          }
        }
      }

      setFormInvalid(false);
      const std: StudyModalDataModel = {
        name: props.patientName,
        serialNumber: state.availableDevice.value,
        operatingMode: state.serviceType.value,
        duration: state.duration.value,
        isInvitingPatient: isInvitingPatient,
        HPDays: isHolter ? state.HPDays.value : "None",
        description: isDurationChanged ? studyDurationChangeNote : ""
      };
      setModalShow({ isShow: true, title: title, studyData: std, isStudy: isStudy, isShowStudy: isShowStudy });
    }
  };

  const openSuccessStudyModal = () => {
    setModalConfirmShow({ isShow: true });
  };

  const openSuccessConfigureConfirmShow = () => {
    setModalConfigureConfirmShow({ isShow: true });
  };

  const handleChangeServiceType = (event: any) => {
    // event.preventDefault();
    if (event.value === "4") {
      state.HPDays.setValue(state.defaultHPDays);
      setIsHolter(true);
      setIs14Duration(false);
    } else if (event.value === "3") {
      setIs14Duration(true);
      setIsHolter(false);
    } else {
      setIsHolter(false);
      setIs14Duration(false);
    }
    const isChangeDiagnosticsDisplayed
      = isChangedDiagnosticsClinicAvailable && !props.isEditMode && event.label === "MCT";
    setIsChangeDiagnosticsDisplayed(isChangeDiagnosticsDisplayed);
    state.changedType.setIsOptional(!isChangeDiagnosticsDisplayed);
  };

  const handleChange = (event: any, item: any) => {
    // event.preventDefault();
    if (item.name.toLowerCase() === "holter+ days") {
      const options = JSON.parse(JSON.stringify(state.duration.optionsSnapshot));
      options.map((option: ISelectOptionsModelSnapShot) => {
        if (+option.value <= +event.value) {
          option.isDisabled = true;
        } else {
          if (state.isDurationIncreaseMode) {
            if (+option.value <= +state.isDurationIncreaseMode) {
              option.isDisabled = true;
            } else {
              option.isDisabled = false;
            }
          } else {
            option.isDisabled = false;
          }
        }
      });
      state.duration.setOptions(options);
      if (+state.duration.value <= +event.value) {
        const newSelectedOption = options.find((option: ISelectOptionsModelSnapShot) => {
          return +option.value === +event.value + 1;
        });
        if (newSelectedOption) {
          state.duration.setValue(newSelectedOption.value);
          state.duration.setDefaultValue(newSelectedOption);
        }
      }
    }
    if (props.isEditMode && item.fieldId === "duration") {
      setIsDurationChanged(true);
    }
  };
  const handleChangeDiagnostics = (event: any) => {
    if (event.target.checked) {
      state.changedType.setIsOptional(false);
    } else {
      state.changedType.setIsOptional(true);
    }
  };
  const handleChangeDevice = (event: any) => {
    // event.preventDefault();
    const list = state.availableDevice.originList;
    const activeOriginDevice = list.find((device: any) => event.value === device.SerialNumber);
    if (activeOriginDevice?.DeviceSettings?.IsBlankLastReport) {
      setIsShowDeviceWarning(true);
    }
  };

  const handleChangeShipToHome = (event: any) => {
    // event.preventDefault();
    if (event.target.checked) {
      state.availableDevice.setValue("");
      state.availableDevice.setDefaultValue({
        value: "",
        label: ""
      });
      state.availableDevice.setLoading(true);
    }
    setTimeout(() => {
      state.availableDevice.setIsOptional(event.target.checked);
      state.availableDevice.setDisabled(event.target.checked);
      state.availableDevice.setLoading(false);
    }, 100);
  };

  const onMenuOpen = (event: any, item: any) => {
    if (item.fieldId === "PNotify") {
      dynamicValidationFunction(state.PReport, item, "<");
    }

    if (item.fieldId === "PReport") {
      const options = setOptionsDisabled(item);
      dynamicValidationFunction(state.BNotify, item, "60", options);
      dynamicValidationFunction(state.PNotify, item, ">", options);
      item.setOptions(options);
    }

    if (item.fieldId === "BNotify") {
      const options = setOptionsDisabled(item);
      dynamicValidationFunction(state.BReport, item, ">", options);
      dynamicValidationFunction(state.TNotify, item, ">", options);
      dynamicValidationFunction(state.TReport, item, ">", options);
      dynamicValidationFunction(state.PReport, item, "60", options);

      item.setOptions(options);
    }

    if (item.fieldId === "BReport") {
      const options = setOptionsDisabled(item);
      dynamicValidationFunction(state.BNotify, item, "<", options);
      dynamicValidationFunction(state.TNotify, item, ">", options);
      dynamicValidationFunction(state.TReport, item, ">", options);
      item.setOptions(options);
    }

    if (item.fieldId === "TNotify") {
      const options = setOptionsDisabled(item);
      dynamicValidationFunction(state.TReport, item, "<", options);
      dynamicValidationFunction(state.BReport, item, "<", options);
      dynamicValidationFunction(state.BNotify, item, "<", options);
      item.setOptions(options);
    }

    if (item.fieldId === "TReport") {
      const options = setOptionsDisabled(item);
      dynamicValidationFunction(state.TNotify, item, ">", options);
      dynamicValidationFunction(state.BReport, item, "<", options);
      dynamicValidationFunction(state.BNotify, item, "<", options);
      item.setOptions(options);
    }
  };

  const setOptionsDisabled = (item: any): ISelectOptionsModelSnapShot[] => {
    const options = JSON.parse(JSON.stringify(item.optionsSnapshot));
    options.map((option: ISelectOptionsModelSnapShot) => {
      option.isDisabled = false;
    });
    return options;
  };

  const cancelChanges = () => {
    state.reset();
    toPatientList();
  };

  const toPatientList = () => {
    navigate("/patients-list");
  };
  const clearChangedType = () => {
    const isChangeDiagnosticsDisplayed
      = isChangedDiagnosticsClinicAvailable
      && !props.isEditMode
      && state.serviceType.getActiveObject().label === "MCT";
    if (!isChangeDiagnosticsDisplayed) {
      state.changedType.setValue("");
    }
  };
  const configureDevice = () => {
    clearChangedType();
    if (!validateForm(getSnapshot(state))) {
      setFormInvalid(true);
      message.error({ content: "Please fill the fields!", duration: 2 });
    } else {
      const key = "configure";
      message.loading({ content: "Loading...", key });
      setFormInvalid(false);
      if (state.shipToHome.value + "" === "true") {
        state.shipToHomeStudy(props.patientId, props.additional).then((response: any) => {
          if (response) {
            openSuccessStudyModal();
          } else {
            message.error({ content: "Something went wrong", key, duration: 2 });
          }
        });
      } else {
        const activeDevice = state.availableDevice.optionsAll.find(
          (item: any) => item.value === state.availableDevice.value
        );
        state.configureDevice(props.patientId, activeDevice.id, props.additional).then((response: any) => {
          if (response) {
            openSuccessConfigureConfirmShow();
          } else {
            message.error({ content: "Something went wrong", key, duration: 2 });
          }
        });
      }
    }
  };

  const startStudy = () => {
    clearChangedType();
    const key = "updatable";
    if (!validateForm(getSnapshot(state))) {
      setFormInvalid(true);
      message.error({ content: "Please fill the fields!", duration: 2 });
    } else {
      message.loading({ content: "Loading...", key });
      setFormInvalid(false);
      const activeDevice = state.availableDevice.optionsAll.find(
        (item: any) => item.value === state.availableDevice.value
      );
      state.startStudy(props.patientId, activeDevice.id, props.additional).then((response: any) => {
        if (response) {
          openSuccessStudyModal();
        } else {
          message.error({ content: "Something went wrong", key, duration: 2 });
        }
      });
    }
  };

  const updateClinicTemplateClick = (event: any) => {
    clearChangedType();
    event.preventDefault();
    const key = "updatable";
    message.loading({ content: "Loading...", key });
    setFormInvalid(false);
    updateClinicTemplate(state).then((response: any) => {
      if (response) {
        message.success({ content: "Clinic Template successfully update!", key, duration: 2 });
      } else {
        message.error({ content: "Something went wrong", key, duration: 2 });
      }
    });
  };

  function validateForm(form: any) {
    const { errors, isValid } = ValidateField(form, props.isEditMode);
    setErrors(errors);
    return isValid;
  }

  const saveChanges = (isInviting: string) => {
    if (isInviting === "true") {
      invitePatientToApp(props.patientId);
    }
    if (modalShow.isStudy) {
      startStudy();
    } else {
      configureDevice();
    }
    setModalShow(defaultModalData);
  };

  const getCalendarValue = () => {
    if (state.duration.isDisabled) {
      const device = state.availableDevice.optionsAll.find((item: any) => item.value === state.availableDevice.value);
      return device
        ? dayjs(device.startDate).add(+state.duration.value, "days")
        : dayjs();
    } else {
      if (props.isEditMode) {
        const device = state.availableDevice.optionsAll.find((item: any) => item.value === state.availableDevice.value);
        return device
          ? dayjs(device.startDate).add(+state.duration.value, "days")
          : dayjs().add(+state.duration.value, "days");
      } else {
        return dayjs().add(+state.duration.value, "days");
      }
    }
  };
  const generatePDF = () => {
     
    const SN = (state.availableDevice.value ? state.availableDevice.value : "00000000") + "_";
    let id = props.patientId + "_";
    if (props.isEditMode) {
      id = patient.RecordingID + "_";
    }
    const name = SN + id + "_" + dayjs().format("MM-DD-YYYY") + ".pdf";
    generatePDFService(name);
  };

  const openInsuranceModal = (event: any) => {
    const id = props.patientId || patient?.Patient?.Id;
    event.preventDefault();
    navigate(`/insurance-info/${id}`, {state: { isCreate: false, isStudy: true }});
  };

  const downloadPDFEnrollment = (e: any) => {
    e.preventDefault();
    generatePDF();
  };

  return (
    <CLStudyCreateWrapper>
      <div className="container" id={"study-form"}>
        <div id="print-mode"><ClPatientInfoHeader/></div>
        <h1>Study</h1>
        <div className="calendar">
          {!props.statusArchived && <Calendar
            defaultValue={getCalendarValue()}
            value={getCalendarValue()}
            fullscreen={false}
            headerRender={({ value }) => {
              return (<div className="month">{value.format("MMM YYYY")}</div>);
            }}
          ></Calendar>}
        </div>
        <form>
          <div className="study-create-block">
            {state.availableDevice.isLoading && <div className="spiner-container">
              <CLSpiner containerHeight="50px" containerWidth="273px" scale={0.75}></CLSpiner>
            </div>}
            {!state.availableDevice.isLoading && <CLDynamicFormComponent
              item={state.availableDevice}
              errors={errors}
              isFormInvalid={formInvalid}
              handleChange={handleChangeDevice}></CLDynamicFormComponent>}
            <div className="w100"></div>
            {!props.isEditMode && isShipToHomeAvailable
              ? <CLDynamicFormComponent
                item={state.shipToHome}
                errors={errors}
                isFormInvalid={formInvalid}
                handleChange={handleChangeShipToHome}></CLDynamicFormComponent>
              : null}
            <div className="w100"></div>
            {isShowDeviceWarning && <div className="device-warning">
              Prior study with the device had blank report. Please test the device
            </div>}
            <div className="w100"></div>
            <CLDynamicFormComponent item={state.serviceType} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChangeServiceType}></CLDynamicFormComponent>
            {isHolter && <CLDynamicFormComponent
              disabled={props.isEditMode && !isRX3Device(state.availableDevice?.value)}
              item={state.HPDays}
              errors={errors}
              isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>}
            {!isHidedDuration
              && <CLDynamicFormComponent
                disabled={props.isEditMode && !isRX3Device(state.availableDevice?.value)}
                item={state.duration}
                errors={errors}
                isFormInvalid={formInvalid}
                handleChange={handleChange}></CLDynamicFormComponent>}
            {(isHolter && isHPDisclaimerShow)
              ? <div className="device-warning">
                Please see Disclaimer on the Billing tab
              </div>
              : null
            }
            <div className="w100"></div>
            {isChangeDiagnosticsDisplayed
              ? <CLDynamicFormComponent
                customClass={"w100"}
                item={state.isChangeDiagnostics} errors={errors} isFormInvalid={formInvalid}
                handleChange={handleChangeDiagnostics}></CLDynamicFormComponent>
              : null}
            {isChangedTypeDisplayed
              ? state.changedType.options.map((field: any, index: number) => {
                return (<div className="radio-field" key={index}>
                  <ClRadio
                    handleChange={() => {
                      state.changedType.setValue(field.value);
                      setStateRadio(index);
                    }}
                    value={field.value}
                    isChecked={index === stateRadio}
                    name={field.value}
                    label={field.label}
                  ></ClRadio>
                </div>);
              })
              : null}
            <div className="w100 border-bottom"></div>
            <h3 className="notes">Edit only if requested by doctor</h3>
            <h3>Tachycardia:</h3>
            <CLDynamicFormComponent item={state.TNotify} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange} onMenuOpen={onMenuOpen}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.TReport} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange} onMenuOpen={onMenuOpen}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.TNoOfBeats} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange} onMenuOpen={onMenuOpen}></CLDynamicFormComponent>
            <div className="w100 border-bottom"></div>
            <h3>Bradycardia:</h3>
            <CLDynamicFormComponent item={state.BNotify} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange} onMenuOpen={onMenuOpen}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.BReport} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange} onMenuOpen={onMenuOpen}></CLDynamicFormComponent>
            <CLDynamicFormComponent item={state.BNoOfBeats} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange} onMenuOpen={onMenuOpen}></CLDynamicFormComponent>
            <div className="w100 border-bottom"></div>
            <h3>Pause:</h3>
            <CLDynamicFormComponent item={state.PNotify} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange} onMenuOpen={onMenuOpen}></CLDynamicFormComponent>

            <CLDynamicFormComponent item={state.PReport} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange} onMenuOpen={onMenuOpen}></CLDynamicFormComponent>

            <div className="w100"></div>

            <CLDynamicFormComponent item={state.reportA} errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <div className="w100 border-bottom"></div>
          </div>
          {isInvalidInsuranceInfo() && !isCheckingValid && (isStudyAvailable() || isConfigureAvailable())
            ? <div className="study-create-block block-form hide-on-print extra-padding">
              <div className="error">
                <p className="error-text">Please Fill Insurance Information before you start Study</p>
                <div>
                  <CLBlueButton
                    click={openInsuranceModal}
                    text="Insurance Information"/>
                </div>
              </div>
              <div className="w100 border-bottom"></div>
            </div>
            : null}

          <div className="study-create-block block-form hide-on-print extra-padding">
            {isStudyAvailable() && <div className="button-holder">
              {(!isInvalidInsuranceInfo()) && <CLBlueButton
                text="Start Study Now"
                isDisabled={isInvalidInsuranceInfo()}
                click={(event: any) => openConfirmationModal(event, "Start Study Now", true, true, true)}/>}
              {(isInvalidInsuranceInfo()) && <ClTooltip
                id={"btn-start-now"}
                class="cl-tooltip"
                tooltipBlock={<div>Some required fields are not completed.</div>}>
                <CLBlueButton
                  text="Start Study Now"
                  isDisabled={true}
                  click={() => {}}/>
              </ClTooltip>}
            </div>}
            {isConfigureAvailable() && !props.isEditMode && <div className="button-holder">
              {(!isInvalidInsuranceInfo()) && <CLBlueButton
                text="Start Study Later"
                isDisabled={isInvalidInsuranceInfo()}
                click={(event: any) => openConfirmationModal(event, "Start Study Later", false, true, true)}/>}
              {(isInvalidInsuranceInfo()) && <ClTooltip
                id={"btn-start-later"}
                class="cl-tooltip"
                tooltipBlock={<div>Some required fields are not completed.</div>}>
                <CLBlueButton
                  text="Start Study Later"
                  isDisabled={true}
                  click={() => {}}/>
              </ClTooltip>}
            </div>}
            {isConfigureAvailable() && props.isEditMode && <div className="button-holder">
              <CLBlueButton
                text="Update device"
                click={(event: any) => openConfirmationModal(event, "Update device", false)}/>
            </div>}
            {!props.isEditMode && <div className="button-holder">
              <CLLightButton text="Skip" click={cancelChanges}/>
            </div>}
            <div className="button-holder">
              <CLGreenButton
                text="Save Template"
                click={(event: any) => updateClinicTemplateClick(event)}/>
            </div>
            {props.isEditMode && isPDFAvailable && <div className="button-holder">
              <CLLightButton
                click={downloadPDFEnrollment}
                text="Download Enrolment PDF"></CLLightButton>
            </div>}
          </div>
        </form>
      </div>
      {modalShow.isShow && <ClStudyCreateModal
        isShow={modalShow.isShow}
        closeModal={() => setModalShow(defaultModalData)}
        saveChanges={(isInvite: string) => saveChanges(isInvite)}
        title={modalShow.title}
        isShowStudy={modalShow.isShowStudy}
        studyData={modalShow.studyData}
      ></ClStudyCreateModal>}
      {modalConfirmShow.isShow && <ClStudyConfirmModal
        isShow={modalConfirmShow.isShow}
        isPDFAvailable={isPDFAvailable}
        downloadPDFEnrollment={downloadPDFEnrollment}
        closeModal={() => {
          setModalConfirmShow({ isShow: false });
          toPatientList();
        }}
      ></ClStudyConfirmModal>}

      {modalConfigureConfirmShow.isShow && <ClStudyConfirmModal
        isShow={modalConfigureConfirmShow.isShow}
        textTitle="Device configuration was sent"
        isPDFAvailable={isPDFAvailable}
        downloadPDFEnrollment={downloadPDFEnrollment}
        closeModal={() => {
          setModalConfigureConfirmShow({ isShow: false });
          toPatientList();
        }}
      ></ClStudyConfirmModal>}

    </CLStudyCreateWrapper>
  );
});
