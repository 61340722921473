import { ClAdministrationInviteModalWrapper } from "./cl-administration-invite-modal.presents";
import { Modal } from "react-bootstrap";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { CLModalCloseBtnStyle } from "../shared/cl-modal/cl-modal.presents";
import { CLIcon } from "../shared/cl-icon";
import Close from "../../assets/icons/close.svg";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component";
import { useState } from "react";
import { CLLightButton, CLBlueButton } from "../shared/cl-button";
import { message } from "antd";
import { confirmAlert } from "react-confirm-alert";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

export const ClAdministrationInviteModal = observer(() => {
  const {
    show,
    closeModal,
    validateFormFields,
    name,
    lastName,
    email,
    isPhysician,
    phone,
    phone2,
    description,
    statReport,
    sumReport,
    npi,
    saveForm,
    isEdit
  } = useRootStore().inviteAdminStore;

  const isPhysicianNPINumber = isFeatureFlagActive("setupPhysicianNPINumber");

  const { getTotalCount } = useRootStore().listAdministrationStore;
  const { setGlobalSpiner } = useRootStore().globalStore;

  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});

  function validateForm() {
    const { errors, isValid } = validateFormFields(isPhysicianNPINumber);
    setErrors(errors);
    return isValid;
  }

  const handleChange = () => {};
  const handleChangeIsPhysician = () => {};
  const handleChangeNumber = (e: any, item: any) => {
    item.setValue(item.value.replace(/[^0-9.]/g, ""));
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (!validateForm()) {
      setFormInvalid(true);
    } else {
      setFormInvalid(false);
      setGlobalSpiner(true);
      saveForm(isPhysicianNPINumber)
        .then((result: boolean) => {
          if (result) {
            getTotalCount();
            hide();
            setGlobalSpiner(false);
            confirmAlert({
              message: isEdit ? "Administrator updated" : "Invitation has been sent!",
              overlayClassName: "cl-success-modal",
              buttons: [
                {
                  label: "Ok",
                  onClick: () => {}
                }
              ]
            });
          } else {
            setGlobalSpiner(false);
            message.error({ content: "Error, Something went wrong!", duration: 2 });
          }
        });
    }
  };

  const hide = () => {
    setFormInvalid(false);
    setErrors({});
    closeModal();
  };

  return (
    <Modal
      onHide={hide}
      show={show}
      centered
      dialogClassName="invite-admin-modal">
      <Modal.Body>
        <CLModalCloseBtnStyle onClick={hide}>
          <CLIcon icon={Close}></CLIcon>
        </CLModalCloseBtnStyle>
        <ClAdministrationInviteModalWrapper>
          <div className="form-container">
            <div className="title">{isEdit ? "Edit" : "Invite"}</div>
            <form onSubmit={onSubmit}>
              <div className="fields-container">
                <CLDynamicFormComponent
                  item={name}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>
                <CLDynamicFormComponent
                  item={lastName}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>
                <CLDynamicFormComponent
                  item={email}
                  customClass="invite-field"
                  disabled={email.isDisabled}
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>
                <CLDynamicFormComponent
                  item={phone}
                  disabled={email.isDisabled}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>
                {isPhysician.value === "true" && <CLDynamicFormComponent
                  item={phone2}
                  disabled={email.isDisabled}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>}
                {isPhysician.value === "true" && <CLDynamicFormComponent
                  item={description}
                  disabled={email.isDisabled}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>}
                {(isPhysicianNPINumber && isPhysician.value === "true") && <CLDynamicFormComponent
                  item={npi}
                  customClass="invite-field"
                  errors={errors}
                  showError={true}
                  handleChange={handleChangeNumber}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>}
                <CLDynamicFormComponent
                  item={isPhysician}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChangeIsPhysician}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>
                {isPhysician.value === "true" && <CLDynamicFormComponent
                  item={statReport}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>}
                {isPhysician.value === "true" && <CLDynamicFormComponent
                  item={sumReport}
                  customClass="invite-field"
                  errors={errors}
                  handleChange={handleChange}
                  isFormInvalid={formInvalid}></CLDynamicFormComponent>}
              </div>
              <div className="btn-container">
                <CLLightButton type="button" text="Cancel" click={hide}/>
                <CLBlueButton type="submit" text={isEdit ? "Save" : "Invite"} click={() => {}}/>
              </div>
            </form>
          </div>
        </ClAdministrationInviteModalWrapper>
      </Modal.Body>
    </Modal>
  );
});
