import { observer } from "mobx-react-lite";
import { ClPatientInfoCharts } from "../../../components/cl-patient-info-charts";
import { ClPatientInfoEvents } from "../../../components/cl-patient-info-events";
import { PatientInfoEventsStyle } from "./PatientInfoEvents.present";
import { useEffect } from "react";
import { useRootStore } from "../../../stores/storeContext";
import { CLSpiner } from "../../../components/shared/cl-spinner";
import { useLocation, useNavigate } from "react-router-dom";
import { EventActionCP } from "../../../boot/Constants";
import { getDateEndMoment } from "../../../components/cl-charts-canvas/cl-charts-canvas.service";
import dayjs from "dayjs";

export const PatientInfoEvents = observer(() => {
  const {
    patient,
    lastEcg,
    events,
    activeFolderName,
    activeDate,
    setActiveFolderName,
    isLastEcgEmpty,
    setActiveDate,
    changeStatusEvents
  } = useRootStore().patientInfoStore;

  const { search } = useLocation();
  const { state: locationState } = useLocation();
  const navigate = useNavigate();
  const eventId = new URLSearchParams(search).get("eventId");
  const isLastEcgExist = lastEcg && lastEcg.FolderName;

  useEffect(() => {
    if (lastEcg && lastEcg.FolderName) {
      setActiveDate(dayjs(lastEcg.StartDate));
      setActiveFolderName(lastEcg.FolderName + "");
    }
  }, [lastEcg]);

  useEffect(() => {
    if (isLastEcgEmpty) {
      const dateEnd = getDateEndMoment(patient);
      if (dateEnd) {
        setActiveDate(dateEnd);
      }
    }
  }, [isLastEcgEmpty, patient]);

  /** Create a problems with server on production ENV
   * when events getting early than lastEcgShort server return 404
   */
  // useEffect(() => {
  //   const isEventsExist = events && events.length;
  //   if (isLastEcgEmpty && isEventsExist) {
  //     const activeItem = findActiveEvent(events, eventId);
  //     setActiveFolderName(activeItem.FolderName + "");
  //     if (!activeEventType) {
  //       setActiveEventType(+activeItem.Id);
  //       setActiveDate(dayjs(activeItem.StartDate));
  //     }
  //   }
  // }, [isLastEcgEmpty, events]);

  useEffect(() => {
    const params = new URLSearchParams();
    if (eventId) {
      params.delete("eventId");
      changeStatusEvents([eventId], EventActionCP.STATReviewed);
    }
    const state = locationState || {};
    navigate({ search: params.toString() }, { ...state });
  }, [patient.RecordingID]);

  if (!isLastEcgExist && !isLastEcgEmpty) {
    return (<PatientInfoEventsStyle>
      <div className="spiner"><CLSpiner></CLSpiner></div>
    </PatientInfoEventsStyle>);
  }

  if (!activeFolderName && events && events[0] && events[0].data && events[0].data[0] && events[0].data[0].FolderName) {
    return (<PatientInfoEventsStyle>
      <div className="spiner"><CLSpiner></CLSpiner></div>
    </PatientInfoEventsStyle>);
  }

  return (
    <PatientInfoEventsStyle>
      <ClPatientInfoCharts
        activeFolderName={activeFolderName} patient={patient} events={events}
        activeDate={activeDate}></ClPatientInfoCharts>
      {events && !!events.length && <ClPatientInfoEvents></ClPatientInfoEvents>}
    </PatientInfoEventsStyle>
  );
});
