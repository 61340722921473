import { Modal } from "react-bootstrap";
import { useRootStore } from "../../stores/storeContext";
import { CLModalCloseBtnStyle } from "../shared/cl-modal/cl-modal.presents";
import { CLIcon } from "../shared/cl-icon";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component/cl-dynamic-form-component";
import { useEffect, useState } from "react";
import Close from "../../assets/icons/close.svg";
import { CLLightButton, CLBlueButton } from "../shared/cl-button";
import { ClStudyEnrollmentChangeModalWrapper } from "./cl-study-enrollment-change-modal.presents";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { CLInput } from "../shared/cl-input";
import LogoImgRoc from "../../assets/img/roc-logo.png";
import LogoImgAccess from "../../assets/img/rhythm-access.png";
import LogoImgFmc from "../../assets/img/fmc-logo.png";
import dayjs from "dayjs";

export const ClStudyEnrollmentChangeModal = observer((props: any) => {
  const {
    init,
    closeModal,
    recordingId,
    validateFormFields,
    clinicId,
    clinicName,
    contactName,
    contactPhone,
    contactEmail,
    patientName,
    patientLastName,
    patientID,
    patientDOB,
    gender,
    indication,
    device,
    mode,
    saveForm
  } = useRootStore().studyEnrollmentChangeModel;
  const userStore = useRootStore().userStore;

  const { setGlobalSpiner } = useRootStore().globalStore;

  const [formInvalid, setFormInvalid] = useState(false);
  const [disabledFormData, setDisabledFormData] = useState<any>({});
  const [errors, setErrors] = useState({});

  function validateForm() {
    const { errors, isValid } = validateFormFields();
    setErrors(errors);
    return isValid;
  }
  const getICD10 = () => {
    const indicationValues = props.data.patientData.IndForMonitor
      .split(";")
      .filter((item: any) => item.trim().length > 1);
    return {
      type: "multiply",
      options: indicationValues
        .map((item: any) => ({ value: item, label: item })),
      fieldId: "indication",
      name: "Indication",
      isDisabled: true,
      values: indicationValues,
      defaultValueMulti: indicationValues,
      valueMulti: indicationValues
    };
  };

  useEffect(() => {
    if (props.isShow) {
      init(userStore.clinicInfo, props.data);

      setDisabledFormData({
        patientName: toJS(patientName),
        patientLastName: toJS(patientLastName),
        patientID: toJS(patientID),
        patientDOB: toJS(patientDOB),
        gender: toJS(gender),
        indication: getICD10(),
        device: toJS(device),
        mode: toJS(mode)
      });
    }
  }, [props.isShow]);

  const handleChange = () => {};

  const hide = () => {
    props.closeModal();
    setFormInvalid(false);
    setErrors({});
    setDisabledFormData({});
    closeModal();
  };

  const onSubmit = async(event: any) => {
    event.preventDefault();
    if (!validateForm()) {
      setFormInvalid(true);
    } else {
      setFormInvalid(false);
      setGlobalSpiner(true);
      await saveForm(disabledFormData);
      setGlobalSpiner(false);
      hide();
    }
  };

  const isROCMC = userStore.IdCurrentMC + "" === "20";
  const isFMC = userStore.IdCurrentMC + "" === "13";

  const renderLogo = () => {
    if (isROCMC) return (<img className="logo" src={LogoImgRoc} alt=""></img>);
    if (isFMC) return (<img className="logo" src={LogoImgFmc} alt=""></img>);
    return (<img className="logo" src={LogoImgAccess} alt=""></img>);
  };
  return (
    <Modal
      onHide={hide}
      show={props.isShow}
      centered
      dialogClassName="invite-admin-modal">
      <Modal.Body>
        <CLModalCloseBtnStyle onClick={hide}>
          <CLIcon icon={Close}></CLIcon>
        </CLModalCloseBtnStyle>
        <ClStudyEnrollmentChangeModalWrapper>
          <div className="form-container" id="study-enrollment">
            <div className="title">{"Report Change Request Form"}</div>
            <div className="title-group">
              {renderLogo()}
              <p className="title-right"><b className="bold">Request data:</b> {dayjs().format("MM/DD/YYYY")}</p>
            </div>
            <div className="group">
              <h3 className="title-group">
                Report Info
              </h3>
              <div className="field-value"><b>Recording ID:</b> {recordingId.value}</div>
            </div>
            <div className="group">
              <h3 className="title-group">
                Customer Info
              </h3>
              <div className="staticData">
                <div className="item">
                  <p className="label">{clinicName.name}</p>
                  <p className="value-item">{clinicName.value}</p>
                </div>
                <div className="item">
                  <p className="label">{clinicId.name}</p>
                  <p className="value-item">{clinicId.value}</p>
                </div>
                <div className="item">
                  <p className="label">{contactName.name}</p>
                  <p className="value-item">{contactName.value}</p>
                </div>
                <div className="item">
                  <p className="label">{contactPhone.name}</p>
                  <p className="value-item">{contactPhone.value}</p>
                </div>
                <div className="item">
                  <p className="label">{contactEmail.name}</p>
                  <p className="value-item">{contactEmail.value}</p>
                </div>
              </div>
            </div>
            <div className="group2">
              <h3 className="title-group">
                Change Request Info
              </h3>
              <div className="table-title">
                <p>Field Name</p>
                <p>Current Value</p>
                <p>New Value</p>
              </div>
              <form onSubmit={onSubmit}>
                <div className="fields-container">
                  <div className="field">
                    <div className="label">{patientName.name}</div>
                    <div className="disabled-filed-container">
                      <CLInput
                        width={"300px"}
                        handleChange={() => {}}
                        placeholder=""
                        value={disabledFormData.patientName?.value}
                        disabled={true}/>
                    </div>
                    <CLDynamicFormComponent
                      item={patientName}
                      hideLabel={true}
                      customClass="invite-field"
                      errors={errors}
                      handleChange={handleChange}
                      isFormInvalid={formInvalid}/>
                  </div>
                  <div className="field">
                    <div className="label">{patientLastName.name}</div>
                    <div className="disabled-filed-container">
                      <CLInput
                        width={"300px"}
                        handleChange={() => {}}
                        placeholder=""
                        value={disabledFormData.patientLastName?.value}
                        disabled={true}/>
                    </div>
                    <CLDynamicFormComponent
                      item={patientLastName}
                      hideLabel={true}
                      customClass="invite-field"
                      errors={errors}
                      handleChange={handleChange}
                      isFormInvalid={formInvalid}/>
                  </div>
                  <div className="field">
                    <div className="label">{patientID.name}</div>
                    <CLInput
                      width={"300px"}
                      handleChange={() => {}}
                      placeholder=""
                      value={disabledFormData.patientID?.value}
                      disabled={true}/>
                    <CLDynamicFormComponent
                      item={patientID}
                      hideLabel={true}
                      customClass="invite-field"
                      errors={errors}
                      handleChange={handleChange}
                      isFormInvalid={formInvalid}/>
                  </div>
                  <div className="field">
                    <div className="label">{patientDOB.name}</div>
                    <CLInput
                      width={"300px"}
                      handleChange={() => {}}
                      placeholder=""
                      value={disabledFormData.patientDOB?.value}
                      disabled={true}/>
                    <CLDynamicFormComponent
                      item={patientDOB}
                      hideLabel={true}
                      customClass="invite-field"
                      errors={errors}
                      handleChange={handleChange}
                      isFormInvalid={formInvalid}/>
                  </div>
                  <div className="field">
                    <div className="label">{gender.name}</div>
                    <CLInput
                      width={"300px"}
                      handleChange={() => {}}
                      placeholder=""
                      value={disabledFormData.gender?.value}
                      disabled={true}/>
                    <CLDynamicFormComponent
                      item={gender}
                      hideLabel={true}
                      customClass="invite-field"
                      errors={errors}
                      handleChange={handleChange}
                      isFormInvalid={formInvalid}/>
                  </div>
                  <div className="field">
                    <div className="label">{indication.name}</div>
                    {disabledFormData.indication && <CLDynamicFormComponent
                      item={disabledFormData.indication}
                      hideLabel={true}
                      customClass="disabled-indication-field"
                      disabled={true}
                      handleChange={() => {}}/>}
                    <CLDynamicFormComponent
                      item={indication}
                      hideLabel={true}
                      customClass="invite-field"
                      errors={errors}
                      handleChange={handleChange}
                      isFormInvalid={formInvalid}/>
                  </div>
                  <div className="field">
                    <div className="label">{device.name}</div>
                    <CLInput
                      width={"300px"}
                      handleChange={() => {}}
                      placeholder=""
                      value={disabledFormData.device?.value}
                      disabled={true}/>
                    <CLDynamicFormComponent
                      item={device}
                      hideLabel={true}
                      disabled={true}
                      customClass="invite-field"
                      errors={errors}
                      handleChange={handleChange}
                      isFormInvalid={formInvalid}/>
                  </div>
                  <div className="field">
                    <div className="label">{mode.name}</div>
                    <CLInput
                      width={"300px"}
                      handleChange={() => {}}
                      placeholder=""
                      value={disabledFormData.mode?.defaultValue?.label}
                      disabled={true}/>
                    <CLDynamicFormComponent
                      item={mode}
                      hideLabel={true}
                      customClass="invite-field"
                      errors={errors}
                      handleChange={handleChange}
                      isFormInvalid={formInvalid}/>
                  </div>
                </div>
                <div className="notice">
                  <h3 className="title-group">
                    Notice
                  </h3>
                  <p>{`Any changes to reports 30 days past the end of a study are not supported.The most enrollment
                  information can be edited from the clinic portal while the recording is still active. It is highly
                  encouraged that clinic portal users double check their work to avoid incurring the costs of modifying
                  reports after the fact.`}</p>
                </div>
                <div className="btn-container">
                  <CLLightButton type="button" text="Cancel" click={hide}/>
                  <CLBlueButton type="submit" text={"Send"} click={() => {}}/>
                </div>
              </form>
            </div>
          </div>
        </ClStudyEnrollmentChangeModalWrapper>
      </Modal.Body>
    </Modal>
  );
});
