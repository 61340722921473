import { CLPatientListTableWraper } from "./cl-patients-list-table.presents";
import { observer } from "mobx-react-lite";
import { Table } from "react-bootstrap";
import { useRootStore } from "../../stores/storeContext";
import { CLDropdown } from "../shared/cl-dropdown";
import { CLIcon, ClIconSort, ClIconNoResult } from "../shared/cl-icon";
import { CLProgress } from "../shared/cl-progress";
import { CLGreenButton } from "../shared/cl-button";
import ActionIcon from "../../assets/icons/dots.svg";
import SortListIcon from "../../assets/icons/sort-list.svg";
import AddUserIcon from "../../assets/icons/add-user.svg";
import NoResultIcon from "../../assets/icons/no-result.svg";
import AlertIcon from "../../assets/icons/alert.svg";
import CopyIcon from "../../assets/icons/copy.svg";
import NoteIcon from "../../assets/icons/note.svg";
import NoteIconGray from "../../assets/icons/note_gray.svg";
import NoReport from "../../assets/icons/no-result-reports_2.svg";
import {
  DeviceStatuses,
  ArchiveStatus,
  DeviceModes,
  sortDirection,
  listPatientsSort,
  chargeValues
} from "../../boot/Constants";
import {
  formatDate,
  getProgresOfDaysText,
  getProgresOfDaysPercentage,
  getCountDaysByDates,
  isNullDate
} from "../../services/handlers/date.handler";
import { CLSpiner } from "../shared/cl-spinner";
import { ClTooltip } from "../shared/cl-tooltip";
import ContentLoader from "react-content-loader";
import { ClStudyCreateModal } from "../cl-study-create-modal/cl-study-create-modal";
import { StudyModalDataModel } from "../cl-study-create-modal/cl-study-create-modal.props";
import { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from "antd";
import { CLNotesModal } from "../cl-notes-modal";
import { updatePhysicianCommentApi } from "../../services/api/clinic.api";
import { ClStudyEnrollmentChangeModal } from "../cl-study-enrollment-change-modal";
import { isFeatureFlagActive } from "../../services/feature-flag.service";
import { isIDTFMC } from "../../stores/patientInfo/patientInfo.helper.service";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export const CLPatientListTable = observer(() => {
  const {
    items,
    isLoading,
    setSortBy,
    activeCategory,
    sendCommandForDevice,
    isNoPatients,
    checkIsCanUse
  } = useRootStore().listPatientsStore;
  const { resetPatientInfo, getDataBasePatient } = useRootStore().patientInfoStore;
  const { isInvitingPatient, invitePatientToApp, firstName, lastName, IdCurrentMC } = useRootStore().userStore;
  const { setGlobalSpiner } = useRootStore().globalStore;
  const { reset } = useRootStore().createUserStore;
  const isInsuranceInfo = isFeatureFlagActive("insuranceInfo");
  const isIDF = isIDTFMC(IdCurrentMC);
  const [modalShow, setModalShow] = useState({
    isShow: false,
    title: "",
    activeIndex: null as any,
    studyData: {} as StudyModalDataModel
  });
  const [modalStudyShow, setStudyModalShow] = useState({
    isShow: false,
    data: {}
  });

  const [modalNoteShow, setModalNoteShow] = useState({
    isShow: false,
    note: "",
    patientId: "",
    physicianNote: ""
  });

  const navigate = useNavigate();

  const isEndStudyAvailable = !isIDF;
  const comandStudy = [{
    text: "End Study",
    url: "EndStudy",
    checkUrl: "IsCanUseEndStudy",
    index: 0,
    checkArchiveStatus: true,
    checkInTransitStatus: true
  }, {
    text: "End Study Without Report",
    description: "No report was requested. If report is needed, please contact"
      + " <a href='mailto:reports@rhythmexpressecg.com'>reports@rhythmexpressecg.com</a> for 30 days since study end",
    url: "EndStudyWR",
    checkUrl: "IsCanUseEndStudy",
    index: 0,
    isWR: true,
    checkArchiveStatus: true,
    checkInTransitStatus: true
  }];
  const comandDevices = [{
    text: "Start/Edit Study",
    url: "StartTest",
    checkUrl: "IsCanUseStartTest",
    isStart: true,
    index: 3
  },
  {
    text: "Edit patient",
    isEdit: true,
    index: 4
  },
  {
    text: "Edit Enrollment",
    checkUrl: "IsCanUseStudyEnrollmentChange",
    isEnrollmentChange: true,
    index: 6
  },
  {
    text: "Invite to App",
    index: 5
  }];

  const comandForDevices = isEndStudyAvailable ? [...comandStudy, ...comandDevices] : comandDevices;

  if (isInsuranceInfo) {
    comandForDevices.push({
      text: "Edit Insurance Info",
      isEdit: true,
      index: 7
    });
  }

  const updatePhysicianCommnet = async(id: string, comment: string) => {
    await updatePhysicianCommentApi(id, comment);
    setModalNoteShow({ isShow: false, note: "", physicianNote: "", patientId: "" });
  };

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };

  const getActionInactive = async(
    isOpen: boolean,
    idPatient: number | string
  ) => {
    if (!isOpen) { return []; }
    const result = comandForDevices.map((item) => {
      switch (item.index) {
        case 0:
          return {
            text: item.text,
            action: () => {},
            disabled: true
          };
        case 3:
          return {
            text: item.text,
            action: () => {
              if (idPatient) {
                resetPatientInfo();
                openStartStudyPatient(idPatient);
              }
            },
            disabled: false
          };
        case 4:
          return {
            text: item.text,
            action: () => { openEditPatient(idPatient); },
            disabled: false
          };
        case 7:
          return {
            text: item.text,
            action: () => { openInsuranceInfo(idPatient); },
            disabled: false
          };
        case 5:
          return isInvitingPatient
            ? {
              text: item.text,
              action: () => { invitePatientToApp(idPatient); }
            }
            : null;
      }
    });

    return await Promise.all(result.filter(item => !!item));
  };

  const getAction = async(
    isOpen: boolean,
    status: number,
    idDevice: number | string,
    idPatient: number | string,
    idRecording: number | string,
    deviceSettings: any,
    patientData: any,
    archiveStatus: number
  ) => {
    if (!isOpen) { return []; }
    const result = comandForDevices.map((item: any) => {
      if (item.index === 5) {
        return isInvitingPatient
          ? {
            text: item.text,
            action: () => { invitePatientToApp(idPatient); }
          }
          : null;
      }
      return checkIsCanUse(item, status, idDevice, idPatient, archiveStatus, idRecording)
        .then((isCanUse: boolean) => {
          return {
            text: item.text,
            action: () => {
              let data = {} as any;
              switch (item.index) {
                case 0: {
                  data = {
                    deviceId: idDevice,
                    recordingID: idRecording
                  };
                  const std: StudyModalDataModel = {
                    name: patientData.Name + patientData.LastName,
                    isWR: item.isWR,
                    serialNumber: patientData.Device?.SerialNumber || "-",
                    operatingMode: patientData.Device?.DeviceSettings?.OperatingMode || "-",
                    duration: Math.round((patientData.Device?.DeviceSettings?.Duration || 0) / 24) + "",
                    description: item.description,
                    HPDays: patientData.Device?.DeviceSettings?.HPDays
                  };

                  setModalShow({
                    isShow: true,
                    title: item.text,
                    studyData: std,
                    activeIndex: function(reasonWR: any, otherValue: any) {
                      if (reasonWR || reasonWR === 0) {
                        data.reportStudy = reasonWR;
                        data.reportStudyText = "";
                      }
                      if (otherValue && otherValue.length) {
                        data.reportStudyText = otherValue;
                      }
                      sendCommandForDevice(item, status, idDevice, idPatient, data);
                    }
                  });
                  break;
                }
                case 3:
                  // data = {
                  //   patientId: idPatient,
                  //   deviceId: idDevice,
                  //   jsonDeviceSettings: JSON.stringify(deviceSettings)
                  // };
                  // sendCommandForDevice(item, status, idDevice, idPatient, data);
                  resetPatientInfo();
                  if (DeviceStatuses.Archived === status) {
                    openStartStudyPatient(idPatient);
                  } else if (idRecording && archiveStatus === 0) {
                    openEditStudyPatient(idRecording);
                  } else {
                    openStartStudyPatient(idPatient);
                  }
                  break;
                case 4:
                  openEditPatient(idPatient);
                  break;
                case 7:
                  openInsuranceInfo(idPatient);
                  break;
                case 6:
                  setStudyModalShow({
                    isShow: true,
                    data: {
                      deviceSettings,
                      patientData,
                      recordingID: idRecording,
                      user: {
                        firstName,
                        lastName
                      }
                    }
                  });
                  break;
                default:
                  break;
              }
            },
            disabled: !isCanUse
          };
        });
    });

    return await Promise.all(result.filter(item => !!item));
  };

  const HandleClick = (ev: any, item: any) => {
    ev.preventDefault();
    const isEnrolled = isEnrolledTab();
    const isScrubbed = item?.Device?.DeviceSettings?.ArchiveStatus === 2;
    const invalidData = item.Device?.DeviceStatistics?.DeviceRecordingStartDate === "0001-01-01T00:00:00";
    const isPendingCategory = activeCategory === "pending";
    if (isEnrolled && (!isScrubbed)) return;
    if (invalidData || isPendingCategory) {
      resetPatientInfo();
      navigate(`/patient-info/${item.RecordingID}/reports`, {state: { activeCategory: activeCategory }});
    } else if (item.RecordingID) {
      resetPatientInfo();
      const goOnTab = !isScrubbed ? "events" : "reports";
      navigate(`/patient-info/${item.RecordingID}/${goOnTab}`, {state: { activeCategory: activeCategory }});
    }
  };

  const HandleOpenNotesClick = async(ev: any, item: any) => {
    ev.preventDefault();
    if (!item.Patient) return;
    const result = await getDataBasePatient(item.Patient.Id);
    let note = item.Patient.Comment || "";
    let physicianNote = item.Patient.PhysicianComment || "";
    if (result.ok) {
      note = result.data.Comment;
      physicianNote = result.data.PhysicianComment;
    }
    setModalNoteShow({
      isShow: true,
      note: note,
      patientId: item.Patient.Id + "",
      physicianNote: physicianNote
    });
  };

  const openCreatePatient = () => {
    reset();
    navigate("/create-patient");
  };

  const openEditPatient = async(idPatient: string | number) => {
    setGlobalSpiner(true);
    const result = await getDataBasePatient(idPatient);
    setGlobalSpiner(false);
    if (result.ok && result.data) {
      navigate(`/edit-patient/${idPatient}`, {state: { patientData: result.data, activeCategory: activeCategory }});
    }
  };
  const openInsuranceInfo = async(idPatient: string | number) => {
    setGlobalSpiner(true);
    const result = await getDataBasePatient(idPatient);
    setGlobalSpiner(false);
    if (result.ok && result.data) {
      navigate(`/insurance-info/${idPatient}`, {state: { isCreate: false }});
    }
  };

  const openEditStudyPatient = (RecordingID: string | number) => {
    navigate(`/patient-info/${RecordingID}/study`, {state: { activeCategory: activeCategory }});
  };

  const openStartStudyPatient = (RecordingID: string | number) => {
    navigate(`/study-create/${RecordingID}`,{state: { activeCategory: activeCategory }});
  };

  const getLoadContent = () => {
    const items = [];
    for (let i = 0; i < 6; i++) {
      items.push(<tr key={i.toString()}>
        <td>
          <ContentLoader title="" width="55" height="26">
            <rect rx="4" ry="4" width="55" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="85" height="26">
            <rect rx="4" ry="4" width="85" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="60" height="26">
            <rect rx="4" ry="4" width="60" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="100" height="53">
            <rect rx="4" ry="4" width="100" height="32" />
            <rect y="41" rx="4" ry="4" width="100" height="12" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="100" height="26">
            <rect rx="4" ry="4" width="100" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="100" height="26">
            <rect rx="4" ry="4" width="100" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="60" height="26">
            <rect rx="4" ry="4" width="60" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="80" height="26">
            <rect rx="4" ry="4" width="80" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="40" height="26">
            <rect rx="4" ry="4" width="40" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="60" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="50" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td className="action">
          <ContentLoader rtl={true} title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
      </tr>);
    }
    return items;
  };

  const isArchiveStatus = (item: any) => {
    return !!(item.ArchiveStatus && item.ArchiveStatus > 0);
  };

  const isEnrolledTab = () => {
    return activeCategory === "inactive";
  };

  const getStatusWithError = (item: any) => {
    if (item.Device) {
      if (isArchiveStatus(item)) {
        return ArchiveStatus[item.ArchiveStatus];
      } else {
        if (item.Device.Status === DeviceStatuses.Recording && item.Device.DeviceStatistics) {
          if (item.Device.DeviceStatistics.BattV < chargeValues.error) {
            return (
              <ClTooltip
                id="alertBattery"
                class="cl-tooltip"
                tooltipBlock={<div>{`Battery<${chargeValues.error}%`}</div>}>
                <div className="alert-block">
                  <CLIcon icon={AlertIcon} width="20px" height="20px"></CLIcon>
                  <div className="text">{DeviceStatuses[item.Device.Status]}</div>
                </div>
              </ClTooltip>);
          } else if (getCountDaysByDates(item.Device.DeviceStatistics.LastTransmission) > 1) {
            return (
              <ClTooltip
                id="alertBattery"
                class="cl-tooltip"
                tooltipBlock={<div>{"Last Comms > 1 day ago"}</div>}>
                <div className="alert-block">
                  <CLIcon icon={AlertIcon} width="20px" height="20px"></CLIcon>
                  <div className="text">{DeviceStatuses[item.Device.Status]}</div>
                </div>
              </ClTooltip>);
          } else if (item.Device.DeviceStatistics.Ch1LeadOff && item.Device.DeviceStatistics.Ch2LeadOff) {
            return (
              <ClTooltip
                id="alertBattery"
                class="cl-tooltip"
                tooltipBlock={<div>{"Not wearing for > 30 min"}</div>}>
                <div className="alert-block">
                  <CLIcon icon={AlertIcon} width="20px" height="20px"></CLIcon>
                  <div className="text">{DeviceStatuses[item.Device.Status]}</div>
                </div>
              </ClTooltip>);
          } else {
            return DeviceStatuses[item.Device.Status];
          }
        } else {
          return DeviceStatuses[item.Device.Status];
        }
      }
    } else {
      return "-";
    }
  };

  const getOperatingMode = (item: any) => {
    if (item.Device) {
      return item.Device.DeviceSettings.HPDays > 0
        ? "Holter+ MCT"
        : DeviceModes[item.Device.DeviceSettings.OperatingMode];
    } else {
      return "-";
    }
  };

  const getLastCommClass = (lastComm: number) => {
    const ToHour = lastComm / 60;
    const ToDays = ToHour / 24;
    const className = "last-comm";
    if (lastComm) {
      if (ToHour <= 24) {
        return className + " green";
      } else if (ToDays < 3) {
        return className + " orange";
      } else {
        return className + " red";
      }
    } else {
      return className;
    }
  };

  const handleSaveChanges = (isInvite: string, whatReason?: number, otherValue?: string) => {
    saveChanges(isInvite, whatReason, otherValue);
  };

  const saveChanges = (isInviting: string, whatReason?: number, otherValue?: string) => {
    if (isInviting === "true") {
      console.error("Unhandled property");
    }
    if (whatReason) {
      modalShow.activeIndex(whatReason, otherValue);
    } else if (modalShow.activeIndex) {
      modalShow.activeIndex();
    }

    setModalShow({ isShow: false, title: "", studyData: {} as StudyModalDataModel, activeIndex: null });
  };

  const showCopied = () => {
    const key = "updatable";
    message.success({ content: "Copied", key, duration: 2 });
  };

  const checkPending = (date: string) => {
    return dayjs(date).year() === 1 || dayjs(date).year() === 1901;
  };

  return (
    <CLPatientListTableWraper>
      <Table>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-title">Patient Name</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listPatientsSort.fullname, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listPatientsSort.fullname, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Patient ID</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listPatientsSort.patientid, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listPatientsSort.patientid, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Status</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Monitoring Status</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listPatientsSort.status, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listPatientsSort.status, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Start Date</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listPatientsSort.startdate, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listPatientsSort.startdate, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">End Date</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listPatientsSort.enddate, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listPatientsSort.enddate, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Serial</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listPatientsSort.serialnumber, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listPatientsSort.serialnumber, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Recording Id</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Mode</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listPatientsSort.operatingmode, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listPatientsSort.operatingmode, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th className="sort">
              <div className="sort-title">Notes</div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Notifiable</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listPatientsSort.newevents, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listPatientsSort.newevents, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Ordering Physician</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listPatientsSort.physician, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listPatientsSort.physician, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Last Transmission</div>
              </div>
            </th>
            <th className="action sort">
              <div className="sort-title">Actions</div>
            </th>
          </tr>
        </thead>
        {(!isLoading && items.length > 0) && <tbody>
          {items.map((item: any) => <tr className="cursor" key={item.Patient.Id}>
            <td onClick={(ev: any) => HandleClick(ev, item)}>{item.Patient.Name} {item.Patient.LastName}</td>
            <td className="copy">
              {item.Patient.PatientIDClinic}
              {item.Patient.PatientIDClinic && <CopyToClipboard
                text={item.Patient.PatientIDClinic}
                onCopy={() => showCopied()}>
                <CLIcon icon={CopyIcon} height="16px" width="16px"></CLIcon>
              </CopyToClipboard>}
              {!item.Patient.PatientIDClinic && "-"}
            </td>
            <td onClick={(ev: any) => HandleClick(ev, item)}>
              {getStatusWithError(item)}
            </td>
            <td onClick={(ev: any) => HandleClick(ev, item)} className="monitoring">
              {item.Device && item.Device.DeviceStatistics && <div>
                {isArchiveStatus(item) && <div>{ArchiveStatus[item.ArchiveStatus]}</div>}
                {!isArchiveStatus(item) && <div>
                  {(item.Device.Status === DeviceStatuses.Recording)
                      && <div className="record">
                        {checkPending(item.Device.DeviceStatistics.DeviceRecordingStartDate) && <div>Pending</div>}
                        {!checkPending(item.Device.DeviceStatistics.DeviceRecordingStartDate) && <CLProgress
                          text={getProgresOfDaysText(
                            item.Device.DeviceStatistics.DeviceRecordingStartDate,
                            isNullDate(item.Device.DeviceStatistics.DeviceRecordingEndDate)
                              ? dayjs(item.Device.DeviceStatistics.DeviceRecordingStartDate)
                                .add(item.Device.DeviceSettings.Duration, "hours").format()
                              : item.Device.DeviceStatistics.DeviceRecordingEndDate
                          )}
                          percentage={getProgresOfDaysPercentage(
                            item.Device.DeviceStatistics.DeviceRecordingStartDate,
                            isNullDate(item.Device.DeviceStatistics.DeviceRecordingEndDate)
                              ? dayjs(item.Device.DeviceStatistics.DeviceRecordingStartDate)
                                .add(item.Device.DeviceSettings.Duration, "hours").format()
                              : item.Device.DeviceStatistics.DeviceRecordingEndDate
                          )}
                        ></CLProgress>}
                        {/* <div className="received">
                          <div className="small">Received</div>
                          <CLProgress
                            percentage={30}
                          ></CLProgress>
                        </div> */}
                      </div>}
                  {(item.Device.Status === DeviceStatuses.Complete
                        || item.Device.Status === DeviceStatuses.InTransit
                        || item.Device.Status === DeviceStatuses.Archived)
                        && <div>
                          {formatDate(isNullDate(item.Device.DeviceStatistics.DeviceRecordingEndDate)
                            ? dayjs(item.Device.DeviceStatistics.DeviceRecordingStartDate)
                              .add(item.Device.DeviceSettings.Duration, "hours").format()
                            : item.Device.DeviceStatistics.DeviceRecordingEndDate)}
                          <br></br>
                          {DeviceStatuses[item.Device.Status]}
                        </div>}
                  {(item.Device.Status === DeviceStatuses.Ready
                        || item.Device.Status === DeviceStatuses.Configured)
                        && <div>
                          {DeviceStatuses[item.Device.Status]}
                        </div>}
                </div>}
              </div>}
              {item.Device && !item.Device.DeviceStatistics && item.Device.Status !== null && <div>
                {DeviceStatuses[item.Device.Status]}
              </div>}
              {item.Device && !item.Device.DeviceStatistics && item.Device.Status === null && <div>
                {"-"}
              </div>}
              {!item.Device && "-"}
            </td>
            <td onClick={(ev: any) => HandleClick(ev, item)}>
              {item.Device && item.Device.DeviceStatistics
                ? formatDate(item.Device.DeviceStatistics.DeviceRecordingStartDate, !isEnrolledTab())
                : "-"}
            </td>
            <td onClick={(ev: any) => HandleClick(ev, item)}>
              {item.Device && item.Device.DeviceStatistics
                ? formatDate(isNullDate(item.Device.DeviceStatistics.DeviceRecordingEndDate)
                  ? dayjs(item.Device.DeviceStatistics.DeviceRecordingStartDate)
                    .add(item.Device.DeviceSettings.Duration, "hours").format()
                  : item.Device.DeviceStatistics.DeviceRecordingEndDate, !isEnrolledTab())
                : "-"}
            </td>
            <td onClick={(ev: any) => HandleClick(ev, item)}>
              {item.Device ? item.Device.SerialNumber : "-"}
            </td>
            <td className="copy">
              {item.RecordingID || "-"}
              {item.RecordingID && <CopyToClipboard
                text={item.RecordingID}
                onCopy={() => showCopied()}>
                <CLIcon icon={CopyIcon} height="16px" width="16px"></CLIcon>
              </CopyToClipboard>}
            </td>
            <td onClick={(ev: any) => HandleClick(ev, item)}>
              {getOperatingMode(item)}
            </td>
            <td className="copy">
              {(item.Patient) && <div
                onClick={(ev: any) => HandleOpenNotesClick(ev, item)}>
                {(item.Patient.Comment && item.Patient.Comment !== ".")
                || (item.Patient.PhysicianComment && item.Patient.PhysicianComment !== ".")
                  ? <CLIcon icon={NoteIcon} height="24px" width="24px"></CLIcon>
                  : <CLIcon icon={NoteIconGray} height="24px" width="24px"></CLIcon>}
                {(isArchiveStatus(item) && item.Device?.DeviceSettings?.ReportStudy)
                  ? <div>
                    <ClTooltip
                      id="noReport"
                      class="cl-tooltip"
                      tooltipBlock={<div>{"Study ended without report"}</div>}>
                      <div className="alert-block">
                        <CLIcon icon={NoReport} height="24px" width="24px"></CLIcon>
                      </div>
                    </ClTooltip>
                  </div>
                  : null}
              </div>}
            </td>
            <td onClick={(ev: any) => HandleClick(ev, item)}>
              {item.CountNewSTATEvents > 0 && <div className="count-new-events">{item.CountNewSTATEvents}</div>}
            </td>
            <td onClick={(ev: any) => HandleClick(ev, item)}>
              {item.Patient.PhysicianOrderingName
                ? item.Patient.PhysicianOrderingName
                : "-"}
            </td>
            <td>
              <div className={getLastCommClass(item.LastCommMins)}>{item.LastComm || "-"}</div>
            </td>
            <td className="action">
              <CLDropdown
                icon={ActionIcon}
                onToggle={(isOpen: boolean) => {
                  return item.Device
                    ? getAction(
                      isOpen,
                      item.Device.Status,
                      item.Device.Id,
                      item.Patient.Id,
                      item.RecordingID || "",
                      item.Device.DeviceSettings,
                      item.Patient,
                      item.ArchiveStatus
                    ).then(result => { return result; })
                    : getActionInactive(
                      isOpen,
                      item.Patient.Id
                    ).then(result => { return result; });
                }}
                actions={[]}></CLDropdown>
            </td>
          </tr>
          )}
        </tbody>}
        {isLoading && <tbody>
          {getLoadContent()}
          <tr><td><div className="spiner"><CLSpiner></CLSpiner></div></td></tr>
        </tbody>}
      </Table>
      {(!isLoading && !items.length)
        && <div className="no-result-section">
          {!isNoPatients() && <div className="no-result-content">
            <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
            <div className="text">Sorry, no results found</div>
          </div>}
          {isNoPatients() && <div className="no-result-content">
            <ClIconNoResult icon={AddUserIcon}></ClIconNoResult>
            <div className="text">Create your first patient!</div>
            <CLGreenButton
              click={openCreatePatient}
              text="Create Patient"
              width="256px"></CLGreenButton>
          </div>}
        </div>}

      {modalShow.isShow && <ClStudyCreateModal
        isShow={modalShow.isShow}
        closeModal={() => setModalShow({
          isShow: false,
          title: "",
          studyData: {} as StudyModalDataModel,
          activeIndex: null
        })}
        saveChanges={handleSaveChanges}
        title={modalShow.title}
        studyData={modalShow.studyData}
      ></ClStudyCreateModal>}

      <ClStudyEnrollmentChangeModal
        isShow={modalStudyShow.isShow}
        data={modalStudyShow.data}
        closeModal={() => setStudyModalShow({
          isShow: false,
          data: {}
        })}/>

      {modalNoteShow.isShow && <CLNotesModal
        isShow={modalNoteShow.isShow}
        note={modalNoteShow.note}
        physicianNote={modalNoteShow.physicianNote}
        onSaveComment={(comment: string) => { updatePhysicianCommnet(modalNoteShow.patientId, comment); }}
        closeModal={
          () => setModalNoteShow({ isShow: false, note: "", physicianNote: "", patientId: "" })}></CLNotesModal>}
    </CLPatientListTableWraper>
  );
});
