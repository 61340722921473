import { CLdevicesListTableWraper } from "./cl-devices-list-table.presents";
import { observer } from "mobx-react-lite";
import { useRootStore } from "../../stores/storeContext";
import { Table } from "react-bootstrap";
import {
  sortDirection,
  devicesListCountRows,
  listDevicesSort,
  DeviceStatuses,
  DeviceLocation,
  chargeValues
} from "../../boot/Constants";
import { ClIconNoResult, ClIconSort, CLIcon } from "../shared/cl-icon";
import NoResultIcon from "../../assets/icons/no-result.svg";
import SortListIcon from "../../assets/icons/sort-list.svg";
import { CLSpiner } from "../shared/cl-spinner";
import ContentLoader from "react-content-loader";
import AlertIcon from "../../assets/icons/alert.svg";
import NormalIcon from "../../assets/icons/normal.svg";
import ActionIcon from "../../assets/icons/dots.svg";
import { formatDate, formatDateSeconds, getCountDaysByDates, isNullDate } from "../../services/handlers/date.handler";
import { getSignalIcon, getWifiSignalIcon } from "../../services/handlers/data.handler";
import { CLProgress } from "../shared/cl-progress";
import { theme } from "../../theme/theme";
import { CLDropdown } from "../shared/cl-dropdown";
import { StudyModalDataModel } from "../cl-study-create-modal/cl-study-create-modal.props";
import { useState } from "react";
import { ClStudyCreateModal } from "../cl-study-create-modal/cl-study-create-modal";
import { isIDTFMC } from "../../stores/patientInfo/patientInfo.helper.service";
import dayjs from "dayjs";

const commandForDevices = [
  {
    text: "End Study",
    url: "EndStudy",
    checkUrl: "IsCanUseEndStudy",
    index: 0,
    checkArchiveStatus: true,
    checkInTransitStatus: true
  }];

export const CLDevicesListTable = observer(() => {
  const {
    items,
    isLoading,
    setSortBy,
    openMoveModal,
    checkIsCanUse,
    sendCommandForDevice
  } = useRootStore().listDevicesStore;

  const { inviting, IdCurrentMC } = useRootStore().userStore;
  const isEndStudyAvailable = !isIDTFMC(IdCurrentMC);
  const [modalShow, setModalShow] = useState({
    isShow: false,
    title: "",
    activeIndex: null as any,
    studyData: {} as StudyModalDataModel
  });

  const getLoadContent = () => {
    const items = [];
    for (let i = 0; i < devicesListCountRows; i++) {
      items.push(<tr key={i.toString()}>
        <td>
          <ContentLoader title="" width="80" height="26">
            <rect rx="4" ry="4" width="80" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="70" height="26">
            <rect rx="4" ry="4" width="70" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="80" height="26">
            <rect rx="4" ry="4" width="80" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="100" height="26">
            <rect rx="4" ry="4" width="100" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="100" height="26">
            <rect rx="4" ry="4" width="100" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="120" height="26">
            <rect rx="4" ry="4" width="120" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
        <td>
          <ContentLoader title="" width="160" height="26">
            <rect rx="4" ry="4" width="160" height="26" />
          </ContentLoader>
        </td>
        <td className="action">
          <ContentLoader rtl={true} title="" width="26" height="26">
            <rect rx="4" ry="4" width="26" height="26" />
          </ContentLoader>
        </td>
      </tr>);
    }

    return items;
  };

  const sort = (sortBy: string, sortDirection: number) => {
    return setSortBy(sortBy, sortDirection);
  };

  const getDevHealth = (item: any) => {
    if (!item.DeviceStatistics) {
      return "-";
    }
    if (item.DeviceSettings.IsBlankLastReport) {
      return (
        <div className="alert-block">
          <CLIcon icon={AlertIcon} width="20px" height="20px"></CLIcon>
          <div className="text">{"Blank report"}</div>
        </div>);
    } else if (item.DeviceStatistics.BattV < chargeValues.error) {
      return (
        <div className="alert-block">
          <CLIcon icon={AlertIcon} width="20px" height="20px"></CLIcon>
          <div className="text">{`Battery<${chargeValues.error}%`}</div>
        </div>);
    } else if (getCountDaysByDates(item.DeviceStatistics.LastTransmission) > 1) {
      return (
        <div className="alert-block">
          <CLIcon icon={AlertIcon} width="20px" height="20px"></CLIcon>
          <div className="text">{"Last Comms > 1 day ago"}</div>
        </div>);
    } else if ((item.DeviceStatistics.Ch1LeadOff && item.DeviceStatistics.Ch2LeadOff)
      || item.DeviceStatistics.State === 5) {
      return (
        <div className="alert-block">
          <CLIcon icon={AlertIcon} width="20px" height="20px"></CLIcon>
          <div className="text">{"Not wearing for > 30 min"}</div>
        </div>);
    } else {
      return (
        <div className="alert-block normal">
          <CLIcon icon={NormalIcon} width="20px" height="20px"></CLIcon>
          <div className="text">{"Normal"}</div>
        </div>);
    }
  };

  const getCharge = (item: any) => {
    if (!item.DeviceStatistics) {
      return "-";
    }
    if (item.DeviceStatistics.BattV < chargeValues.error) {
      return (
        <CLProgress
          backgroundColor={theme.colors.red}
          text={`${item.DeviceStatistics.BattV}%`}
          percentage={item.DeviceStatistics.BattV}></CLProgress>);
    } else if (item.DeviceStatistics.BattV >= chargeValues.error
      && item.DeviceStatistics.BattV < chargeValues.warning) {
      return (
        <CLProgress
          backgroundColor={theme.colors.yellow}
          text={`${item.DeviceStatistics.BattV}%`}
          percentage={item.DeviceStatistics.BattV}></CLProgress>);
    } else {
      return (
        <CLProgress
          backgroundColor={theme.colors.green}
          text={`${item.DeviceStatistics.BattV}%`}
          percentage={item.DeviceStatistics.BattV}></CLProgress>);
    }
  };

  const getAction = async(item: any) => {
    if (!item.DeviceSettings || !isEndStudyAvailable) {
      if (DeviceStatuses.Standby === item.Status && inviting) {
        return [{
          text: "Move Device",
          disabled: false,
          color: "",
          action: () => {
            openMoveModal(item);
          }
        }];
      } else {
        return [{
          text: "No Actions",
          disabled: true
        }];
      }
    }
    const result = commandForDevices.map((comand) => {
      return checkIsCanUse(comand, item.Status, item.Id, item.DeviceSettings.RecordingID)
        .then((isCanUse: boolean) => {
          return {
            text: comand.text,
            action: () => {
              let data = {} as any;
              switch (comand.index) {
                case 0: {
                  data = {
                    deviceId: item.Id,
                    recordingID: item.DeviceSettings.RecordingID
                  };
                  const std: StudyModalDataModel = {
                    name: item.LastPatient || "-",
                    serialNumber: item.SerialNumber || "-",
                    operatingMode: item.DeviceSettings.OperatingMode || "-",
                    duration: Math.round((item.DeviceSettings.Duration || 0) / 24) + "",
                    HPDays: item.DeviceSettings.HPDays
                  };
                  setModalShow({
                    isShow: true,
                    title: comand.text,
                    studyData: std,
                    activeIndex: function() {
                      sendCommandForDevice(comand, status, item.Id, item.DeviceSettings.RecordingID, data);
                    }
                  });
                  break;
                }
              }
            },
            disabled: !isCanUse,
            color: ""
          };
        });
    });

    if (DeviceStatuses.Standby === item.Status && inviting) {
      result.push({
        text: "Move Device",
        disabled: false,
        color: "",
        action: () => {
          openMoveModal(item);
        }
      });
    }

    return await Promise.all(result);
  };

  const saveChanges = () => {
    if (modalShow.activeIndex) {
      modalShow.activeIndex();
    }
    setModalShow({ isShow: false, title: "", studyData: {} as StudyModalDataModel, activeIndex: null });
  };

  const getSignalType = (DeviceStatistics: any) => {
    if (DeviceStatistics.ComMode === 2) {
      return getWifiSignalIcon(DeviceStatistics.QCSQ_04 || -100);
    } else {
      return getSignalIcon(DeviceStatistics.QCSQ_04 || 0);
    }
  };

  return (
    <CLdevicesListTableWraper>
      <Table>
        <thead>
          <tr>
            <th>
              <div className="sort">
                <div className="sort-title">Serial</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listDevicesSort.serialnumber, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listDevicesSort.serialnumber, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Status</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listDevicesSort.status, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listDevicesSort.status, sortDirection.DESC)} className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Dev Health</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Location</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Last Patient</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Last Communicated</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listDevicesSort.lasttransmission, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listDevicesSort.lasttransmission, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">End Date</div>
                <div className="sort-actions">
                  <div onClick={() => sort(listDevicesSort.recordingenddate, sortDirection.ASC)}>
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                  <div onClick={() => sort(listDevicesSort.recordingenddate, sortDirection.DESC)}
                    className="bottom-sort">
                    <ClIconSort icon={SortListIcon}></ClIconSort>
                  </div>
                </div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Ordering Physician</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Signal Strength</div>
              </div>
            </th>
            <th>
              <div className="sort">
                <div className="sort-title">Battery Charge</div>
              </div>
            </th>
            <th className="action sort">
              <div className="sort-title">Actions</div>
            </th>
          </tr>
        </thead>
        {(!isLoading && items.length > 0) && <tbody>
          {items.map((item: any) => <tr>
            <td>{item.SerialNumber || "-"}</td>
            <td>{DeviceStatuses[item.Status]}</td>
            <td>{getDevHealth(item)}</td>
            <td>{item.DeviceLocation || item.DeviceLocation === 0 ? DeviceLocation[item.DeviceLocation] : "-"}</td>
            <td>{item.LastPatient || "-"}</td>
            <td>{item.DeviceStatistics && item.DeviceStatistics.LastTransmission
              ? formatDateSeconds(item.DeviceStatistics.LastTransmission)
              : "-"}</td>
            <td>{(item.Status !== DeviceStatuses.Configured) && item.DeviceStatistics
              ? formatDate(dayjs(isNullDate(item.DeviceStatistics.DeviceRecordingEndDate)
                ? dayjs(item.DeviceStatistics.DeviceRecordingStartDate)
                  .add(item.DeviceSettings.Duration, "hours").format()
                : item.DeviceStatistics.DeviceRecordingEndDate).format(), true)
              : "-"}
            </td>
            <td>{item.OrderingPhysicianLastPatient || "-"}</td>
            <td>{item.DeviceStatistics
              ? <CLIcon icon={getSignalType(item.DeviceStatistics)} width="24px" height="24px"></CLIcon>
              : "-"}</td>
            <td>{getCharge(item)}</td>
            <td className="action">
              <CLDropdown
                icon={ActionIcon}
                actions={[]}
                onToggle={(isOpen: boolean) => {
                  return isOpen
                    ? getAction(item)
                    : [];
                }}
              ></CLDropdown>
            </td>
          </tr>)}
        </tbody>}
        {isLoading && <tbody>
          {getLoadContent()}
          <div className="spiner"><CLSpiner></CLSpiner></div>
        </tbody>}
      </Table>
      {(!isLoading && !items.length) && <div className="no-result-section">
        <div className="no-result-content">
          <ClIconNoResult icon={NoResultIcon}></ClIconNoResult>
          <div className="text">Sorry, no results found</div>
        </div>
      </div>}
      {modalShow.isShow && <ClStudyCreateModal
        isShow={modalShow.isShow}
        closeModal={() => setModalShow({
          isShow: false,
          title: "",
          studyData: {} as StudyModalDataModel,
          activeIndex: null
        })}
        saveChanges={() => saveChanges()}
        title={modalShow.title}
        studyData={modalShow.studyData}
      ></ClStudyCreateModal>}

    </CLdevicesListTableWraper>
  );
});
