import {
  CVAUSA,
  DEV1CENTERID,
  DEV2CENTERID,
  FCMID,
  IDTFID,
  localEnv,
  PTID,
  RA1ID,
  RA2ID,
  ROCTELID
} from "../../boot/Constants";
import dayjs from "dayjs";
const storedBeats: any = {};
let storedListECG: any = {};
let storedValidBeats: any = {};
let activeBeatsDiff: number;

export function generateList(receivedData: any) {
  let events: any[] = [];
  const outputEvents: any[] = [];

  receivedData.map((minutes5: any) => {
    if (minutes5.EcgEvents) {
      minutes5.EcgEvents.map((item: any) => {
        if (minutes5.FolderName) {
          item.FolderName = minutes5.FolderName;
          events.push(item);
        }
      });
    }
  });

  const returnValue: any = {};
  events.sort((a: any, b: any): any => {
    const A = dayjs(a.StartDate);
    const B = dayjs(b.StartDate);
    const diff = A.diff(B);
    return diff > 0 ? -1 : 1;
  });
  events = events.map((item) => {
    if (+item.EventSource === 77) {
      const isSymptmatic = events
        .find((search) => +search.EventSource === 40 && search.IDReceivedData === item.IDReceivedData);
      if (isSymptmatic) {
        item.EventSource = 78;
      }
    }
    return item;
  });
  events.map((a: any): any => {
    const resultObj = dayjs(a.StartDate).format("MM/DD/YYYY");
    if (!returnValue[resultObj]) {
      returnValue[resultObj] = [];
    }
    returnValue[resultObj].push(a);
  });
  const keys = Object.keys(returnValue);
  const sortedArr = keys.sort((a: any, b: any): any => {
    const A = dayjs(a).startOf("day");
    const B = dayjs(b).startOf("day");
    const diff = A.diff(B);
    return diff > 0 ? -1 : 1;
  });
  sortedArr.forEach((item) => {
    outputEvents.push({
      key: item,
      data: returnValue[item]
    });
  });

  return outputEvents;
}

export function setStoredBeats(patientId: string | number, date: string, data: any) {
  const key = patientId + "_" + date;
  storedBeats[key] = data;
}

export function getStoredBeats(patientId: string | number, date: string) {
  const key = patientId + "_" + date;
  if (storedBeats[key]) {
    return storedBeats[key];
  }
  return {};
}

export function setValidBeats(data: any) {
  storedValidBeats = data;
}

export function getValidBeats() {
  return storedValidBeats;
}

export function setListECG(data: any) {
  storedListECG = data;
}

export function getListECG() {
  return storedListECG;
}

export function getStoredBeatsPromise(patientId: string | number, date: string) {
  return new Promise((resolve, reject) => {
    const key = patientId + "_" + date;
    if (storedBeats[key]) {
      resolve(storedBeats[key]);
    } else {
      reject(null);
    }
  });
}

export function setActiveBeatsDiff(diff: number) {
  activeBeatsDiff = diff;
}

export function getActiveBeatsDiff(): number {
  return activeBeatsDiff || 0;
}

export function isIncludeStoredBeats(patientId: string | number, date: string) {
  const key = patientId + "_" + date;
  if (storedBeats[key]) {
    return true;
  }
  return false;
}

export function getEventTypeString(event: any): string {
  if (event.EventActionCP) {
    switch (event.EventActionCP) {
      case 1:
        return "Stat";
      case 2:
        return "Stat";
      case 3: // Rejected
        return "Rejected";
    }
  }
  if (event.EventSource && event.EventSource === 40) {
    return "Profile";
  }
  if (event.EventSource && event.EventSource === 78) {
    return "Profile";
  }
  switch (event.EventStatus) {
    case 1:
      return "Confirmet";
    case 4: // ConfirmedSTAT
      return "Stat";
    case 3: // Rejected
      return "Rejected";
    case 5: // ConfirmedReviewed
      return "Confirmet";
  }
  return "Confirmet";
}

export const isIDTFMC = (IdCurrentMC: number | undefined) => {
  // @ts-ignore
   
  return (IdCurrentMC === PTID || IdCurrentMC === IDTFID)
  || (localEnv === "development" && IdCurrentMC === DEV2CENTERID);
};

export const isRA1MC = (IdCurrentMC: number) => {
  // @ts-ignore
   
  return (IdCurrentMC === RA1ID)
    || (localEnv === "development" && IdCurrentMC === DEV1CENTERID);
};

export const isRA2MC = (IdCurrentMC: number) => {
  // @ts-ignore
   
  return (IdCurrentMC === RA2ID)
    || (localEnv === "development" && IdCurrentMC === DEV1CENTERID);
};

export const isFCMMC = (IdCurrentMC: number) => {
  return IdCurrentMC === FCMID;
};

export const isROCTELMC = (IdCurrentMC: number) => {
  return IdCurrentMC === ROCTELID;
};

export const isCVAUSAMC = (IdCurrentMC: number) => {
  return (IdCurrentMC === CVAUSA)
    || (localEnv === "development" && IdCurrentMC === DEV1CENTERID);
};
