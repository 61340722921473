 
import styled from "styled-components";

export const RadioStyle = styled.div`
  display: flex;
  
  .custom-radio {
    width: 20px;
    height: 20px;
    position: relative;
    &::before {
      content: " ";
      border-radius: 100%;
      border: 1px solid #B9B9B9;
      background: #FFF;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      box-sizing: border-box;
      pointer-events: none;
      z-index: 0;
    }
  }

  label {
    margin-left: 5px;
  }
  .fill {
    background: #457EF5;
    width: 0;
    height: 0;
    border-radius: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.2s ease-in, height 0.2s ease-in;
    pointer-events: none;
    z-index: 1;

    &::before {
      content: "";
      opacity: 0;
      width: 20px;
      position: absolute;
      height: 20px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border: 1px solid #457EF5;
      border-radius: 100%;
    }
  }

  input {
    opacity: 0;
    z-index: 2;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:checked {
      & ~ .fill {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        transition: width 0.2s ease-out, height 0.2s ease-out;

        &::before {
          opacity: 1;
          transition: opacity 1s ease;
        }
      }
    }
  }
`;
