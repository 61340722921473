import { types, applySnapshot, flow, Instance, SnapshotIn } from "mobx-state-tree";
import { UserFormModel } from "../createUser/user.model";
import { InitialState } from "./initial.state";
import { updateWIFIAdminApi } from "../../services/api/administration.api";
import { validateEmailHandler, validatePhoneHandler } from "../../services/handlers/validate.handler";

export const WiFiSetupModel = types
  .model("WiFiSetupModel", {
    show: types.optional(types.boolean, false),
    isEdit: types.optional(types.boolean, false),
    idUser: types.optional(types.string, ""),
    id: types.identifier,
    ssid: types.maybe(UserFormModel),
    password: types.maybe(UserFormModel),
    name: types.maybe(UserFormModel),
    email: types.maybe(UserFormModel),
    phoneNumber: types.maybe(UserFormModel)
  })
  .actions(self => {
    const resetForm = () => {
      self.isEdit = false;
      applySnapshot(self, InitialState);
    };

    const initData = (data?: any) => {
      if (data) {
        if (self.ssid) {
          self.ssid.value = data.ssid || "";
        }
        if (self.password) {
          self.password.value = data.password || "";
        }
      }
    };
    const initITContactData = (data?: any) => {
      if (data) {
        if (self.name) {
          self.name.value = data.Name || "";
        }
        if (self.email) {
          self.email.value = data.Email || "";
        }
        if (self.phoneNumber) {
          self.phoneNumber.value = data.Phone || "";
        }
      }
    };

    const closeModal = () => {
      self.show = false;
      resetForm();
    };

    const validateFormFields = (): {errors: {[key: string]: string}, isValid: boolean} => {
      const errors: {[key: string]: string} = {};
      let isValid: boolean = true;
      const requiredFields = ["ssid", "password", "name"];
      requiredFields.forEach((field: string) => {
        if (!(<any>self[field as keyof typeof self])?.value) {
          errors[field] = "Please fill the field";
          isValid = false;
        }
      });

      if (!self.email?.value) {
        errors.email = "Please fill the field";
        isValid = false;
      } else {
        if (!validateEmailHandler(self.email?.value)) {
          errors.email = "Wrong email";
          isValid = false;
        }
      }

      if (!self.phoneNumber?.value) {
        errors.phoneNumber = "Please fill the field";
        isValid = false;
      } else {
        if (!validatePhoneHandler(self.phoneNumber?.value)) {
          errors.phoneNumber = "Please enter valid Phone Number";
          isValid = false;
        }
      }

      return {
        errors,
        isValid
      };
    };

    const getDataForSave = () => {
      const data: any = {
        login: self.ssid && self.ssid.value,
        password: self.password && self.password.value
      };

      return data;
    };
    const getParamsForSave = () => {
      const data: any = {
        Name: self.name && self.name.value,
        Email: self.email && self.email.value,
        Phone: self.phoneNumber && self.phoneNumber.value
      };

      return JSON.stringify(data);
    };

    const saveForm = flow(function* saveForm() {
      const data = getDataForSave();
      const params = getParamsForSave();

      try {
        const response = yield updateWIFIAdminApi(data, params);
        if (response.ok && response.data) {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    });

    return {
      initData,
      initITContactData,
      closeModal,
      saveForm,
      validateFormFields
    };
  });

export interface IWiFiSetupModel extends Instance<typeof WiFiSetupModel> {}
export interface IWiFiSetupModelSnapShot extends SnapshotIn<typeof WiFiSetupModel> {}
