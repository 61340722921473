/* eslint-disable max-len */
import AllIcon from "../assets/icons/list-patients-all.svg";
import AllIconActive from "../assets/icons/list-patients-all-active.svg";
import ActiveIcon from "../assets/icons/list-patients-active.svg";
import ActiveIconActive from "../assets/icons/list-patients-active-active.svg";
import EventIcon from "../assets/icons/list-patients-event.svg";
import Alert from "../assets/icons/alert-active.svg";
import AlertGray from "../assets/icons/alert-gray.svg";
import EventIconActive from "../assets/icons/list-patients-event-active.svg";
import PendingIcon from "../assets/icons/list-patients-pending.svg";
import PendingIconActive from "../assets/icons/list-patients-pending-active.svg";
import InnactiveIcon from "../assets/icons/list-patients-innactive.svg";
import HookupIcon from "../assets/icons/home-hookup.svg";
import PendingStudyIcon from "../assets/icons/pending-study.svg";
import HookupActiveIcon from "../assets/icons/home-hookup-active.svg";
import PendingStudyActiveIcon from "../assets/icons/pending-study-active.svg";
import InnactiveIconActive from "../assets/icons/list-patients-innactive-active.svg";
import ReportAll from "../assets/icons/report-all.svg";
import ReportDaily from "../assets/icons/report-daily.svg";
import ReportStat from "../assets/icons/report-stat.svg";
import ReportSummary from "../assets/icons/report-summary.svg";
import AdministrationUserIcon from "../assets/icons/administration-user.svg";
import AdministrationUserIconActive from "../assets/icons/administration-user-active.svg";
import AdministrationPhysicianIcon from "../assets/icons/administration-physician.svg";
import AdministrationPhysicianIconActive from "../assets/icons/administration-physician-active.svg";
import { theme } from "../theme/theme";
import { NotificationSelectNames } from "./enums/NotificationSelectNames";

export const apiUrl = process.env.REACT_APP_API_URL;
export const localEnv = process.env.REACT_APP_ENV_NAME;
export const patientListCountRows = 10;
export const administrationListCountRows = 10;
export const devicesListCountRows = 10;
export const billingListCountRows = 10;
export const reportsListCountRows = 10;
export const timeUpdateStatus = 1000 * 30;
export const timeUpdateListPatients = 1000 * 60 * 3;
export const timeUpdateNotification = 1000 * 60 * 3;
export const recaptchaKey = "6Lf3GssbAAAAAIqUo2myXWPyzkMpfWcu4dX97Id4";

// CLINICS
export const FCMID = 13;
export const ROCTELID = 20;
export const RA1ID = 12;
export const RA2ID = 22;
export const IDTFID = 23;
export const CVAUSA = 14;

export const PTID = 7;

export const DEV1CENTERID = 10;
export const DEV2CENTERID = 17;

export const HeaderTabs = [
  {
    title: "Patients",
    link: "/patients-list"
  },
  {
    title: "Devices",
    link: "/devices-list"
  },
  {
    title: "Billing",
    link: "/billing"
  },
  {
    title: "All Reports",
    link: "/reports-list"
  },
  {
    title: "Admin",
    link: "/administration-list"
  },
  {
    title: "Support",
    link: "/support"
  }
];

export const AdditionalTabs = [
  {
    title: "ICD-10-edit",
    link: "/icd10-edit"
  }
];

export const PatientInfoTabs = [
  {
    title: "Events",
    link: "/events",
    id: 0
  },
  {
    title: "Reports",
    link: "/reports",
    id: 3
  },
  {
    title: "Study",
    link: "/study",
    id: 2
  }
];

export const BillingTabs = [
  {
    title: "Billing",
    link: "/list",
    id: 0
  },
  {
    title: "Utilization",
    link: "/utilization",
    id: 1
  }
];
export const ReportsTabs = [
  {
    title: "User Management",
    link: "/user-management",
    id: 0
  },
  {
    title: "ICD-10",
    link: "/icd10",
    id: 1
  },
  {
    title: "Settings",
    link: "/settings",
    isSecure: true,
    id: 2
  },
  {
    title: "Notifications",
    link: "/notifications?allUsers=true",
    id: 3
  }
];

export const PatiensListCategories = [
  {
    title: "All",
    value: "all",
    icon: AllIcon,
    iconActive: AllIconActive
  },
  {
    title: "Active",
    value: "active",
    icon: ActiveIcon,
    iconActive: ActiveIconActive
  },
  {
    title: "STAT events",
    value: "events",
    icon: EventIcon,
    iconActive: EventIconActive
  },
  {
    title: "Pending diagnosis",
    value: "pending",
    icon: PendingIcon,
    iconActive: PendingIconActive
  },
  // {
  //   title: "Archived",
  //   value: "archived",
  //   icon: ArchiveIcon,
  //   iconActive: ArchiveIconActive
  // },
  {
    title: "Enrolled",
    value: "inactive",
    icon: InnactiveIcon,
    iconActive: InnactiveIconActive
  }
];

export const HomeHookupCategory = {
  title: "Home Hookup",
  value: "homeHookup",
  icon: HookupIcon,
  iconActive: HookupActiveIcon
};

export const PendingStudyCategory = {
  title: "Pending Study",
  value: "pendingStudy",
  icon: PendingStudyIcon,
  iconActive: PendingStudyActiveIcon
};

export const ListPatientsUrls = [
  {
    key: "all",
    url: "/HSData/All"
  },
  {
    key: "active",
    url: "/HSData/ActiveData"
  },
  {
    key: "events",
    url: "/HSData/WithNewEvents"
  },
  {
    key: "pending",
    url: "/HSData/PendingDiagnosis"
  },
  {
    key: "archived",
    url: "/HSData/ArchiveData"
  },
  {
    key: "inactive",
    url: "/ClinicPatients/Free"
  }
];

export enum DeviceStatuses {
  Archived,
  Complete,
  Standby,
  Ready,
  Recording,
  Reported,
  InTransit,
  Configured
}

// Available Statuses
// "Archived", // 0
// "Complete", // 1
// "Standby", // 2
// "Ready", // 3
// "Recording", // 4
// "Reported", // 5
// "InTransit", // 6
// "Configured" // 7
export const DeviceStatusesArr = [
  { value: "All", id: -1 },
  { value: "Configured", id: 7 },
  { value: "Ready", id: 3 },
  { value: "Recording", id: 4 },
  { value: "Complete", id: 1 },
  { value: "Archived", id: 0 },
  { value: "InTransit", id: 6 }
];

export enum ArchiveStatus
{
  Active,
  Archived,
  Scrubbed
}

export enum ReportType {
  Summary,
  Daily,
  Stat,
  Baseline,
  Holter,
  Weekly,
  EV,
  BlankHolter = 8,
  BlankSummary = 9,
  SleepQuality = 10,
  SummaryM = 11
}

export const ReportTypeArr = [
  "Summary",
  "Daily",
  "Stat",
  "Baseline",
  "Holter",
  "Weekly",
  "EV",
  "",
  "Holter",
  "Summary",
  "SleepQuality",
  "SummaryM"
];

export enum DeviceModes {
  MCT = 1,
  EventRecorder = 2,
  WirelessHolter = 3
}

export enum EventActionCP {
  STATNone = 0,
  STATNew = 1,
  STATReviewed = 2,
  STATRejected = 3,
}

export enum GenderType {
  M = "MALE",
  F = "FEMALE",
  O = "OTHER"
}

export enum DeviceLocation {
  "Not Specified" = 0,
  "With Patient" = 1,
  "In Clinic" = 2
}

export enum UsersGroupEnum {
  Standard = 0,
  Admin = 1,
  Production = 2,
  Superuser= 3,
}

export const UsersGroupSTR = {
  Support: "Support_CP",
  Superuser: "Superuser_CP",
  Admin: "Admin_CP",
  Standard: "Standard_CP"
};

export const defaultValueClinicSelect = {
  label: "All clinics",
  value: -1
};

export const sortDirection = {
  ASC: 1,
  DESC: 2
};

export const listPatientsSort = {
  fullname: "fullname",
  patientid: "patientid",
  status: "status",
  startdate: "startdate",
  enddate: "enddate",
  operatingmode: "operatingmode",
  newevents: "newevents",
  serialnumber: "serialnumber",
  physician: "physician"
};
export const homeHookupPatientsSort = {
  patientname: "patientname",
  patientid: "patientid",
  serial: "serial",
  recordingid: "recordingid",
  trackingnumber: "trackingnumber",
  deviceclinic: "deviceclinic",
  physician: "physician"
};
export const pendingStudyPatientsSort = {
  patientname: "patientname",
  patientid: "patientid",
  serial: "serial",
  recordingid: "recordingid",
  trackingnumber: "trackingnumber",
  deviceclinic: "deviceclinic",
  physician: "physician"
};

export const ErrorMessages = {
  errorLogin: "Wrong email or username or password",
  errorForgot: "Wrong email",
  wrongEmail: "Wrong Email",
  wrongPassword: "Wrong password",
  actionCanNotUse: "This action is not allowed",
  recoveryError: "Error recovery password",
  recoveryPasswordsNotEqual: "Passwords not equal"
};

export const SuccessMessages = {
  successForgot: "We have emailed you a link resetting your password.\nIf you did not receive the email please check your address and your spam folder.\nOnly users already registered with RhythmExpress can apply for a new password.",
  successRecovery: "Your password was successfully updated!"
};

export const ReportTypesNav = [
  {
    type: -1,
    id: 0,
    icon: ReportAll,
    color: "rgb(240, 240, 240)",
    tooltip: "All"
  },
  {
    type: ReportType.Daily,
    id: 1,
    icon: ReportDaily,
    color: "rgb(99, 179, 119)",
    tooltip: "Daily"
  },
  {
    type: ReportType.Stat,
    id: 2,
    icon: ReportStat,
    color: "rgb(226, 101, 96)",
    tooltip: "Stat"
  },
  {
    type: ReportType.Summary,
    id: 3,
    icon: ReportSummary,
    color: "rgb(120, 86, 175)",
    tooltip: "Summary"
  }
];

export const ReportTypesColorSchema = [
  {
    type: -1,
    color: "rgb(240, 240, 240)",
    tooltip: "All"
  },
  {
    type: ReportType.Daily,
    color: "rgb(99, 179, 119)",
    tooltip: "Daily"
  },
  {
    type: ReportType.Stat,
    color: "rgb(226, 101, 96)",
    tooltip: "Stat"
  },
  {
    type: ReportType.Summary,
    color: "rgb(120, 86, 175)",
    tooltip: "Summary"
  },
  {
    type: ReportType.Holter,
    color: "rgb(255,196,46)",
    tooltip: "Holter"
  },
  {
    type: ReportType.Weekly,
    color: "rgb(69,126,245)",
    tooltip: "Weekly"
  },
  {
    type: ReportType.BlankSummary,
    color: "rgb(120, 86, 175)",
    tooltip: "Summary"
  },
  {
    type: ReportType.BlankHolter,
    color: "rgb(255,196,46)",
    tooltip: "Holter"
  },
  {
    type: ReportType.SummaryM,
    color: "rgb(194,148,255)",
    tooltip: "Summary M"
  }
];

export const AdministrationListCategories = [
  {
    title: "All",
    value: "all",
    icon: AllIcon,
    iconActive: AllIconActive
  },
  {
    title: "Physicians",
    value: "physicians",
    icon: AdministrationUserIcon,
    iconActive: AdministrationUserIconActive
  },
  {
    title: "Users",
    value: "users",
    icon: AdministrationPhysicianIcon,
    iconActive: AdministrationPhysicianIconActive
  }
];

export const listBillingSort = {
  startdate: "startdate",
  deviceid: "deviceid"
};
export const listAdministrationSort = {
  name: "name",
  lastname: "lastname",
  email: "email",
  phone: "phone"
};

export const listDevicesSort = {
  serialnumber: "serialnumber",
  status: "status",
  lasttransmission: "lasttransmission",
  recordingenddate: "recordingenddate"
};

export const chargeValues = {
  error: 12,
  warning: 50
};

export const DevicesListCategories = [
  {
    title: "All",
    value: "all",
    icon: AllIcon,
    iconActive: AllIconActive,
    type: 0
  },
  {
    title: "Active",
    value: "active",
    icon: ActiveIcon,
    iconActive: ActiveIconActive,
    type: 1
  },
  {
    title: "Inventory",
    value: "inventory",
    icon: EventIcon,
    iconActive: EventIconActive,
    type: 2
  },
  {
    title: "Alerts",
    value: "problem",
    icon: AlertGray,
    iconActive: Alert,
    type: 3
  }
];

export const SupportInfo = [
  {
    title: "Report Request",
    content: [
      {
        title: "Email",
        type: "email",
        valueFunction: (idCenter: number) => {
          switch (idCenter) {
            case RA1ID:
              return "clinicsupport@rhythmexpressecg.com";
            case RA2ID:
              return "reports@rhythmexpressecg.com";
            case ROCTELID:
              return "reports@roctele.com";
            case FCMID:
              return "ClinicSupport@fcminc.com";
            default:
              return "clinicsupport@rhythmexpressecg.com";
          }
        }
      }
    ]
  },
  {
    title: "Customer Support",
    content: [
      {
        title: "Monday — Friday ",
        titleFunction: (idCenter: number) => {
          switch (idCenter) {
            case ROCTELID:
              return "Monday — Sunday ";
            case FCMID:
              return "Monday — Sunday ";
            default:
              return "Monday — Friday ";
          }
        },
        value: "9am-6pm EST",
        valueFunction: (idCenter: number) => {
          switch (idCenter) {
            case ROCTELID:
              return "24/7";
            case FCMID:
              return "24/7";
            default:
              return "9am-6pm EST";
          }
        }
      },
      {
        title: "Phone Number",
        value: "866-324-8783 Option 2",
        valueFunction: (idCenter: number) => {
          switch (idCenter) {
            case ROCTELID:
              return "800-381-8593";
            case FCMID:
              return "800-274-5399";
            default:
              return "866-324-8783 Option 2";
          }
        }
      },
      {
        title: "Email",
        type: "email",
        value: "support@rhythmexpressecg.com",
        valueFunction: (idCenter: number) => {
          switch (idCenter) {
            case ROCTELID:
              return "support@roctele.com";
            case FCMID:
              return "ClinicSupport@fcminc.com";
            default:
              return "support@rhythmexpressecg.com";
          }
        }
      }
    ]
  },
  {
    title: "Patient Support",
    content: [
      {
        title: "Phone Number (24/7)",
        value: "866-324-8783 Option 1",
        valueFunction: (idCenter: number) => {
          switch (idCenter) {
            case ROCTELID:
              return "877-476-2835";
            case FCMID:
              return "800-274-5399";
            default:
              return "866-324-8783 Option 1";
          }
        }
      }
    ]
  }
];
export const SupportInfoCVAUSA = [
  {
    title: "CVAUSA Command Center Hotline",
    content: [
      {
        title: "Phone Number (24/7)",
        value: "1-689-273-9740"
      }
    ]
  }
];
export const listReportsSort = {
  reportdate: "reportdate"
};

export const DeviceMode = {
  mobile: "mobile",
  desktop: "desktop"
};

export const chartUtilizationOptions = {
  zoomEnabled: false,
  dataPointMaxWidth: 16,
  toolTip: {
    content: "{x}",
    fontColor: theme.colors.text,
    borderColor: theme.colors.background,
    fontSize: 14,
    fontweight: "normal",
    cornerRadius: 4,
    backgroundColor: theme.colors.light,
    borderThickness: 0
  },
  axisY: {
    labelPlacement: "outside",
    tickPlacement: "inside",
    gridColor: theme.colors.background,
    tickColor: theme.colors.background,
    lineColor: theme.colors.background,
    labelFontColor: theme.colors.placeholder,
    labelFontSize: 12,
    includeZero: true
  },
  axisX: {
    labelPlacement: "outside",
    tickPlacement: "inside",
    gridColor: theme.colors.background,
    tickColor: theme.colors.background,
    lineColor: theme.colors.background,
    labelFontColor: theme.colors.placeholder,
    labelFontSize: 12,
    tickThickness: 0,
    includeZero: true
  }
};

export const NotificationSelectStates = [
  {
    name: NotificationSelectNames.VTR1_V1,
    defaultValue: "170",
    disabled: true
  },
  {
    name: NotificationSelectNames.VTR1_V2,
    defaultValue: "10",
    minValue: 8,
    maxValue: 14,
    increment: 2
  },
  {
    name: NotificationSelectNames.VTR2_V1,
    defaultValue: "160",
    disabled: true
  },
  {
    name: NotificationSelectNames.VTR2_V2,
    defaultValue: "170",
    disabled: true
  },
  {
    name: NotificationSelectNames.VTR2_V3,
    defaultValue: "10",
    minValue: 8,
    maxValue: 14,
    increment: 2
  },
  {
    name: NotificationSelectNames.AF1_V1,
    defaultValue: "150",
    disabled: true
  },
  {
    name: NotificationSelectNames.AF1_V2,
    defaultValue: "5",
    additionalValue: 1,
    minValue: 5,
    maxValue: 30,
    increment: 5
  },
  {
    name: NotificationSelectNames.AF1_V3,
    defaultValue: 0,
    options: [{
      value: 1,
      label: "All the time"
    }, {
      value: 0,
      label: "New Onset"
    }]
  },
  {
    name: NotificationSelectNames.AF2_V1,
    defaultValue: "50",
    disabled: true
  },
  {
    name: NotificationSelectNames.AF2_V2,
    defaultValue: "150",
    disabled: true
  },
  {
    name: NotificationSelectNames.AF2_V3,
    defaultValue: "5",
    additionalValue: 1,
    minValue: 5,
    maxValue: 30,
    increment: 5
  },
  {
    name: NotificationSelectNames.AF2_V4,
    defaultValue: 0,
    options: [{
      value: 1,
      label: "All the time"
    }, {
      value: 0,
      label: "New Onset"
    }]
  },
  {
    name: NotificationSelectNames.BR1_V1,
    defaultValue: "30",
    disabled: true
  },
  {
    name: NotificationSelectNames.BR1_V2,
    defaultValue: "10",
    minValue: 10,
    maxValue: 30,
    increment: 5
  },
  {
    name: NotificationSelectNames.BR2_V1,
    defaultValue: "30",
    disabled: true
  },
  {
    name: NotificationSelectNames.BR2_V2,
    defaultValue: "40",
    disabled: true
  },
  {
    name: NotificationSelectNames.BR2_V3,
    defaultValue: "10",
    minValue: 10,
    maxValue: 30,
    increment: 5
  },
  {
    name: NotificationSelectNames.BR2_V4,
    defaultValue: 1,
    options: [{
      value: 0,
      label: ""
    }, {
      value: 1,
      label: "New Onset"
    }]
  },
  {
    name: NotificationSelectNames.P1_V1,
    defaultValue: "6",
    minValue: 6,
    maxValue: 10,
    increment: 1
  },
  {
    name: NotificationSelectNames.P2_V1,
    defaultValue: "3",
    minValue: 3,
    maxValue: 5,
    increment: 1
  },
  {
    name: NotificationSelectNames.P2_V2,
    defaultValue: "5",
    minValue: 3,
    maxValue: 5,
    increment: 1
  },
  {
    name: NotificationSelectNames.P2_V3,
    defaultValue: 1,
    options: [{
      value: 0,
      label: ""
    }, {
      value: 1,
      label: "New Onset"
    }]
  },
  {
    name: NotificationSelectNames.SVT_V1,
    defaultValue: "150",
    minValue: 150,
    maxValue: 200,
    increment: 10
  },
  {
    name: NotificationSelectNames.SVT_V2,
    defaultValue: "10",
    minValue: 10,
    maxValue: 30,
    increment: 5
  },
  {
    name: NotificationSelectNames.SDAVB_V,
    defaultValue: "10",
    minValue: 10,
    maxValue: 20,
    increment: 5
  },
  {
    name: NotificationSelectNames.CHB_V,
    defaultValue: "6",
    minValue: 5,
    maxValue: 10,
    increment: 1
  }
];
