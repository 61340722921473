import { observer } from "mobx-react-lite";
import { CLUserProfileWrapper, ContainerCodeWrapper } from "./cl-user-profile.presents";
import { useRootStore } from "../../stores/storeContext";
import { getSnapshot } from "mobx-state-tree";
import { CLDynamicFormComponent } from "../cl-dynamic-form-component";
import { CLBlueButton } from "../shared/cl-button";
import { useEffect, useState } from "react";
import { ValidateField } from "./cl-user-profile.service";
import { message } from "antd";
import { getValidPhoneNumber } from "../../services/handlers/data.handler";
import { Modal } from "react-bootstrap";
import { CLModalCloseBtnStyle } from "../shared/cl-modal/cl-modal.presents";
import { CLIcon } from "../shared/cl-icon";
import Close from "../../assets/icons/close.svg";
import AuthCode from "react-auth-code-input";
import { CLMessage } from "../shared/cl-message";
import { ClAlert } from "../shared/cl-alert";
import { isFeatureFlagActive } from "../../services/feature-flag.service";
import { useLocation } from "react-router-dom";

export const ClUserProfile = observer(() => {
  const [formInvalid, setFormInvalid] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorCode, setErrorCode] = useState(false);
  const [authCode, setAuthCode] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const state = useRootStore().profileStore;
  const { state: locationState } = useLocation();

  const {
    updateProfileData,
    phoneNumber,
    disablePasscode,
    usersGroupSTR,
    idPhysician,
    waiver2FA
  } = useRootStore().userStore;

  const isMandatoryMFA = isFeatureFlagActive("mandatoryMFA");
  const isPhysicianNPINumber = isFeatureFlagActive("setupPhysicianNPINumber");
  const isAllClinickAvailable = idPhysician && usersGroupSTR === "Standard" || usersGroupSTR === "Admin";
  const isPhysician = idPhysician;

  useEffect(() => {
    if (locationState?.highlight2AF) {
      const errorsHiglight: any = {};
      errorsHiglight[state.twoFactorAuth.fieldId] = "Please enable 2FA to show all clinics";
      setErrors(errorsHiglight);
    }
  }, []);

  const handleChange = () => {
    // event.preventDefault();
    // console.log(event, item)
  };
  const handleChangeNumber = (e: any, item: any) => {
    item.setValue(item.value.replace(/[^0-9.]/g, ""));
  };

  const handleChangeShowAllClinics = () => {
    if (state.allClinicLoad.value + "" === "true") {
      state.twoFactorAuth.setValue("true");
      state.allClinicLoad.setChecked(true);
      setShowAlert(true);
    }
  };
  const handleChange2FA = () => {
    if (state.twoFactorAuth.value + "" === "false") {
      state.allClinicLoad.setValue("false");
      state.allClinicLoad.setChecked(false);
    }
  };
  const saveChanges = () => {};

  const phoneOrAuthChanged = () => {
    if (getValidPhoneNumber(phoneNumber) !== state.phoneNumber?.value
      || !disablePasscode + "" !== state.twoFactorAuth?.value) {
      return true;
    } else {
      return false;
    }
  };
  const onSubmit = async(event: any) => {
    event.preventDefault();
    if (!validateForm(getSnapshot(state))) {
      setFormInvalid(true);
      message.error({ content: "Please fill the fields!", duration: 2 });
    } else {
      setFormInvalid(false);
      const key = "updatable";
      const skipPhoneValidation = state.twoFactorAuth?.value === "false" && disablePasscode === true;
      const isPhoneOrAuthChanged = phoneOrAuthChanged();

      message.loading({ content: "Loading...", key });
      await state.prepareSaveForm(skipPhoneValidation, isPhoneOrAuthChanged, disablePasscode, waiver2FA);
    }
  };

  function validateForm(form: any) {
    const { errors, isValid } = ValidateField(form, isPhysicianNPINumber, isPhysician);
    setErrors(errors);
    return isValid;
  }

  const hideModal = () => {
    setAuthCode("");
    state.twoFactorAuth.setValue(!disablePasscode + "");
    state.twoFactorAuth.setChecked(!disablePasscode);
    if (isAllClinickAvailable) {
      state.allClinicLoad.setValue(!disablePasscode + "");
      state.allClinicLoad.setChecked(!disablePasscode);
    }
    state.closeModal();
  };

  const handleOnChangeAuthCode = (res: string) => {
    setAuthCode(res);
    setErrorCode(false);
  };

  const onHideAlert = () => {
    setShowAlert(false);
  };

  const onValidateCode = async(event: any) => {
    event.preventDefault();
    const key = "updatable";
    const response = await state.validateCode(authCode);
    if (response) {
      updateProfileData(response);
      setAuthCode("");
      state.closeModal();
      message.success({ content: "User profile successfully updated!", key, duration: 2 });
    } else {
      message.error({ content: "Error, Something went wrong!", key, duration: 2 });
    }
  };

  const getTwoFactorAuthenticationMessage = () => {
    const replaced = state.phoneNumber.value.replace(/.(?=....)/g, "*");
    return `A message with a verification code has been sent to your phone number ${replaced}.
    Enter the code to continue.`;
  };

  return (
    <CLUserProfileWrapper>
      <div className="container">
        <h1>Profile</h1>
        <form onSubmit={onSubmit}>
          <div className="patient-create-block">
            <CLDynamicFormComponent customClass="profile-field" item={state.firstName}
              errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent customClass="profile-field" item={state.lastName}
              errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent customClass="profile-field" item={state.email}
              errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent customClass="profile-field" item={state.username}
              errors={errors} isFormInvalid={formInvalid} showError={true}
              handleChange={handleChange}></CLDynamicFormComponent>
            <CLDynamicFormComponent customClass="profile-field" item={state.phoneNumber}
              errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>
            {isPhysician && <CLDynamicFormComponent customClass="profile-field" item={state.phone2}
              errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>}
            {isPhysician && <CLDynamicFormComponent customClass="profile-field" item={state.description}
              errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange}></CLDynamicFormComponent>}
            {(isPhysicianNPINumber && isPhysician) && <CLDynamicFormComponent
              item={state.npi}
              customClass="profile-field"
              errors={errors}
              showError={true}
              handleChange={handleChangeNumber}
              isFormInvalid={formInvalid}></CLDynamicFormComponent>}
            <CLDynamicFormComponent customClass="profile-field bold" item={state.twoFactorAuth}
              errors={errors} isFormInvalid={formInvalid}
              handleChange={handleChange2FA}></CLDynamicFormComponent>
            {isAllClinickAvailable
              && <CLDynamicFormComponent
                customClass="profile-field bold" item={state.allClinicLoad}
                errors={errors} isFormInvalid={formInvalid}
                handleChange={handleChangeShowAllClinics}></CLDynamicFormComponent>}
            {!isMandatoryMFA && (state.twoFactorAuth.value === false || state.twoFactorAuth.value === "false")
              ? <CLDynamicFormComponent customClass="profile-field bold" item={state.optOutTwoFactorAuth}
                errors={errors} isFormInvalid={formInvalid}
                handleChange={handleChange}></CLDynamicFormComponent>
              : null }
          </div>
          <div className="patient-create-block">
            <div className="button-holder">
              <CLBlueButton text="Save" click={saveChanges}/>
            </div>
          </div>
        </form>
      </div>
      <ClAlert
        show={showAlert}
        onHide={onHideAlert}
        msg={"2 FA is required to Show All clinics. Please verify text phone number for texting the code"}/>
      <Modal
        onHide={hideModal}
        show={state.showModal}
        centered
        dialogClassName="invite-admin-modal">
        <Modal.Body>
          <CLModalCloseBtnStyle onClick={hideModal}>
            <CLIcon icon={Close}></CLIcon>
          </CLModalCloseBtnStyle>
          <ContainerCodeWrapper>
            <div className="container-code">
              <CLMessage text={getTwoFactorAuthenticationMessage()}></CLMessage>
              <div className="label">Authorization Code</div>
              <AuthCode
                length={6}
                onChange={handleOnChangeAuthCode}
                containerClassName="auth-code-container"
                inputClassName={`auth-code-input${errorCode ? " error" : ""}`}
                allowedCharacters="numeric"
              />
              <div className="error-code">{errorCode && <span>Wrong authorization code</span>}&nbsp;</div>
              <CLBlueButton
                width="100%"
                text="Confirm"
                type="button"
                isDisabled={!authCode || authCode.length < 6}
                click={(event: any) => { onValidateCode(event); }}></CLBlueButton>
            </div>
          </ContainerCodeWrapper>
        </Modal.Body>
      </Modal>
    </CLUserProfileWrapper>
  );
});
