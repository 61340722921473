import { applySnapshot, flow, Instance, SnapshotIn, types } from "mobx-state-tree";
import { UserFormModel } from "../createUser/user.model";
import { getDurationOptions, getHolterDays, InitialState } from "./initial.state";
import { configureDeviceApi, shipToHomeApi, startStudyApi } from "../../services/api/study.api";
import { DeviceStatuses } from "../../boot/Constants";
import { isRX3Device } from "../../services/handlers/data.handler";
import { isFeatureFlagActive } from "../../services/feature-flag.service";

function getValue(value: any) {
  if (value + "" === "0") {
    return "None";
  } else {
    return value;
  }
}

export const StudyModel = types
  .model("StudyModel", {
    // Personal Info
    id: types.identifier,
    availableDevice: types.maybe(UserFormModel),
    shipToHome: types.maybe(UserFormModel),
    serviceType: types.maybe(UserFormModel),
    duration: types.maybe(UserFormModel),
    HPDays: types.maybe(UserFormModel),
    // Tachycardia
    TNotify: types.maybe(UserFormModel),
    TReport: types.maybe(UserFormModel),
    TNoOfBeats: types.maybe(UserFormModel),
    // Bradycardia
    BNotify: types.maybe(UserFormModel),
    BReport: types.maybe(UserFormModel),
    BNoOfBeats: types.maybe(UserFormModel),
    // Pause
    PNotify: types.maybe(UserFormModel),
    PReport: types.maybe(UserFormModel),
    reportA: types.maybe(UserFormModel),
    isDurationIncreaseMode: types.optional(types.number, 0),
    defaultHPDays: types.optional(types.string, ""),
    isChangeDiagnostics: types.maybe(UserFormModel),
    changedType: types.maybe(UserFormModel)
  })
  .actions(self => ({
    setDefaultValueByKey(key: string, defaultValue: any) {
      if (self[key as keyof typeof self]) {
        (<any>self[key as keyof typeof self]).value = defaultValue.value;
        (<any>self[key as keyof typeof self]).defaultValue = defaultValue;
        // self[key as keyof typeof self].name = self[key as keyof typeof self].name + " ";
      }
    }
  }))
  .actions(self => ({
    /**
     *  ‘Total duration’ field could be increased for a study in Recording and Device with SN starting from ‘RX3’
     */
    setDefaultDuration(state: any, status: number, activeItemValue: any) {
      if ((DeviceStatuses.Recording === status
          || DeviceStatuses.Configured === status
          || DeviceStatuses.Ready === status)
        && self.duration && isRX3Device(activeItemValue.label)) {
        const newOptions = self.duration.options.map((option: any) => {
          if (+option.value < +state.duration) {
            option.isDisabled = true;
          } else {
            option.isDisabled = false;
          }
          return option;
        });
        self.duration.isDisabled = false;
        self.duration.setOptions(newOptions);
        self.isDurationIncreaseMode = +state.duration;
      }
    }
  }))
  .actions(self => {
    const getDataForSave = (additional?: any) => {
      function getValue(item: any) {
        if (item && item.value && item.value !== "None") {
          return item.value;
        } else {
          return "0";
        }
      }
      function getValueServiceType(item: any) {
        if (item && item.value && +item.value !== 4) {
          return item.value;
        } else {
          return "3";
        }
      }
      function getHPDays(item: any, days: any) {
        if (item && item.value && +item.value === 4) {
          return getValue(days);
        } else {
          return "0";
        }
      }
      const data: any = {
        "OperatingMode": getValueServiceType(self.serviceType),
        "TachyRateUrgent": getValue(self.TNotify),
        "TachyRateRoutine": getValue(self.TReport),
        "TachySensitivity": getValue(self.TNoOfBeats),
        "BradyRateUrgent": getValue(self.BNotify),
        "BradyRateRoutine": getValue(self.BReport),
        "BradySensitivity": getValue(self.BNoOfBeats),
        "AlternativeMode": getValue(self.changedType),
        "PauseTimeUrgent": +getValue(self.PNotify) * 2,
        "PauseTimeRoutine": +getValue(self.PReport) * 2,
        // "AFDurationUrgent": self.TNotify && self.TNotify.value,
        "Duration": +getValue(self.duration),
        "IsAF": (self.reportA && self.reportA.value) || "false",
        "TimeZone": additional && additional.TimeZone || "-05",
        "DST": additional && additional.DST || 0,
        "HPDays": getHPDays(self.serviceType, self.HPDays)
      };

      return data;
    };

    const startStudy = flow(function* (
      idPatient: string | number,
      deviceId: string | number,
      additional?: any) {
      const data = getDataForSave(additional);
      const str = JSON.stringify(data);
      try {
        const response = yield startStudyApi(idPatient + "", deviceId + "", str);
        if (response.ok) {
          return response.data;
        }
        return false;
      } catch (error) {
        return false;
      }
    });

    const shipToHomeStudy = flow(function* (
      idPatient: string | number,
      additional?: any) {
      const data = getDataForSave(additional);
      const str = JSON.stringify(data);
      try {
        const response = yield shipToHomeApi(idPatient + "" + "", str);
        if (response.ok) {
          return response.data;
        }
        return false;
      } catch (error) {
        return false;
      }
    });

    const configureDevice = flow(function* (
      idPatient: string | number,
      deviceId: string | number,
      additional?: any) {
      const data : any = getDataForSave(additional);
      const str = JSON.stringify(data);
      try {
        const response = yield configureDeviceApi(idPatient + "", deviceId + "", str);
        if (response.ok) {
          return response.data;
        }
        return false;
      } catch (error) {
        return false;
      }
    });

    function reset() {
      applySnapshot(self, InitialState);
    }

    function apply(state: any, isCreate: boolean, status?: number) {
      for (const key in state) {
        if (self[key as keyof typeof self]) {
          if ((<any>self[key as keyof typeof self]).type === "select") {
            const activeItem = (<any>self[key as keyof typeof self]);
            let activeItemValue = null;
            if (key === "availableDevice") {
              if (activeItem?.optionsAll && activeItem.optionsAll.length) {
                activeItemValue = activeItem.optionsAll.find((item: any) => +item.id === +state[key]);
                if (self.availableDevice && !isCreate) self.availableDevice.isDisabled = true;
                if (status && activeItemValue) {
                  self.setDefaultDuration(state, status, activeItemValue);
                }
              }
            } else {
              if (activeItem.options && activeItem.options.length) {
                activeItemValue = activeItem.options.find((item: any) => +item.value === +state[key]);
              }
            }

            if (key === "duration") {
              if ((+state.serviceType === 3 || +state.serviceType === 4) && self.duration) {
                self.duration.setOptions(getDurationOptions(14));
              }
              if (state.HPDays && state.HPDays !== "None" && self.duration) {
                const newOptions = self.duration.options.map((option: any) => {
                  if (+option.value <= +state.HPDays) {
                    option.isDisabled = true;
                  } else {
                    option.isDisabled = false;
                  }
                  return option;
                });
                self.duration.setOptions(newOptions);
              }
              if (!isCreate && self.duration) {
                self.duration.isDisabled = true;
                const deviceValue = self?.availableDevice?.optionsAll.find(
                  (item: any) => +item.id === +state.availableDevice);
                if (status && deviceValue) {
                  self.setDefaultDuration(state, status, deviceValue);
                }
              }
            }

            if (activeItemValue) {
              self.setDefaultValueByKey(key, {
                value: "" + activeItemValue.value,
                label: "" + activeItemValue.label
              });
            } else {
              self.setDefaultValueByKey(key, {
                value: "" + state[key],
                label: "" + state[key]
              });
            }
          } else if ((<any>self[key as keyof typeof self]).type === "checkbox") {
            (<any>self[key as keyof typeof self]).value = state[key] + "";
            (<any>self[key as keyof typeof self]).isChecked = state[key] === "true";
          } else {
            (<any>self[key as keyof typeof self]).value = state[key] + "";
          }
        }
      }

      if (state.HPDays) {
        self.defaultHPDays = state.HPDays + "";
      }
    }

    function setOptionsHPDays(days: number) {
      self.HPDays?.setOptions(getHolterDays(days));
    }

    function init(clinicSettingsTemplate: any) {
      const initState: any = {};
      initState.serviceType = (+clinicSettingsTemplate.HPDays) ? "4" : getValue(clinicSettingsTemplate.OperatingMode);
      initState.duration = Math.round(clinicSettingsTemplate.Duration / 24);
      initState.TNotify = getValue(clinicSettingsTemplate.TachyRateUrgent);
      initState.TReport = getValue(clinicSettingsTemplate.TachyRateRoutine);
      initState.TNoOfBeats = getValue(clinicSettingsTemplate.TachySensitivity);
      initState.BNotify = getValue(clinicSettingsTemplate.BradyRateUrgent);
      initState.BReport = getValue(clinicSettingsTemplate.BradyRateRoutine);
      initState.BNoOfBeats = getValue(clinicSettingsTemplate.BradySensitivity);
      initState.PNotify = getValue(clinicSettingsTemplate.PauseTimeUrgent) === "None"
        ? "None"
        : +clinicSettingsTemplate.PauseTimeUrgent / 2;
      initState.PReport = getValue(clinicSettingsTemplate.PauseTimeRoutine) === "None"
        ? "None"
        : +clinicSettingsTemplate.PauseTimeRoutine / 2;
      initState.reportA = clinicSettingsTemplate.IsAF || "false";
      initState.HPDays = getValue(clinicSettingsTemplate.HPDays) || "None";
      initState.changedType = "";
      apply(initState, true);

      const isSevenHPDays = isFeatureFlagActive("sevenHPDays");
      const countDays = isSevenHPDays ? 7 : 2;
      self.HPDays?.setOptions(getHolterDays(countDays));
    }

    return {
      startStudy,
      shipToHomeStudy,
      reset,
      apply,
      init,
      setOptionsHPDays,
      configureDevice
    };
  });

export interface IStudyModel extends Instance<typeof StudyModel> {}
export interface IStudyModelSnapShot extends SnapshotIn<typeof StudyModel> {}
