import API from "../../boot/Api";
import * as Types from "./api.types";
import { encodeData } from "../handlers/data.handler";

export const getTotalDevicesCountApi = async(jsonFilter: {}): Promise<Types.ApiResponse> => {
  const result = API.get("/ClinicDevices/DeviceCounter", {
    params: { jsonFilter: jsonFilter }
  });
  return await Types.getResponse(result);
};

export const getListDevicesApi = async(jsonFilter: {}, type: number): Promise<Types.ApiResponse> => {
  const result = API.get("/ClinicDevices/GetDevices", {
    params: {
      jsonFilter: jsonFilter,
      type: type
    }
  });
  return await Types.getResponse(result);
};

export const moveDeviceApi = async(deviceId: string| number, clinicId: string | number): Promise<Types.ApiResponse> => {
  const encodedData = encodeData({
    deviceId: deviceId,
    clinicId: clinicId
  });
  const result = API.post(`/ClinicDevices/MoveDeviceToClinic?${encodedData}`);
  return await Types.getResponse(result);
};

export const serviceRequestDeviceApi
  = async(deviceSN: string | number, directContact: string, reasonOfReturn: string): Promise<Types.ApiResponse> => {
    const encodedData = encodeData({
      deviceSN: deviceSN,
      directContact: directContact,
      reasonOfReturn: reasonOfReturn
    });
     
    const result = API.post(`/ClinicDevices/RMAServiceRequest?${encodedData}`);
    return await Types.getResponse(result);
  };
