import { observer } from "mobx-react-lite";
import { ClDynamicFormComponentType } from "./cl-dynamic-form-component.props";
import { CLInput } from "../shared/cl-input";
import { CLSelect } from "../shared/cl-select";
import { CLCheckbox } from "../shared/cl-checkbox";
import { DatePicker } from "antd";

import { CLDynamicFormWrapper } from "./cl-dynamic-form-component.presents";
import InputMask from "react-input-mask";
import dayjs from "dayjs";

const InputField = observer((props: ClDynamicFormComponentType) => {
  const handleChange = (event: any) => {
    const value = event.target.value;
    if (!props.item.setValue) return;
    props.item.setValue(value);
    props.handleChange(event, props.item);
  };

  const handleCheckbox = (event: any) => {
    const value = event.target.checked + "";
    if (!props.item.setValue) return;
    props.item.setValue(value);
    props.item.setChecked(event.target.checked);
    if (props.handleChange) {
      props.handleChange(event, props.item);
    }
  };
  const handleDatePick = (event: any, dateString: string | string[]) => {
    if (!props.item.setValue) return;
    if (Array.isArray(dateString)) {
      props.item.setValue(dateString[0]);
    } else {
      props.item.setValue(dateString);
    }
  };
  const selectOption = (event: any) => {
    const value = event?.value || "";
    if (!props.item.setValue) return;
    props.item.setValue(value);
    props.handleValueChanges && props.item.setDefaultValue({
      value: event?.value || "",
      label: event?.label || ""
    });
    props.handleChange(event, props.item);
  };
  const selectOptionMultiply = (event: any) => {
    const values = event.map((item: any) => item.value);
    if (!props.item.setValueMultiply) return;
    props.item.setValueMultiply(values);
    props.item.setDefaultMultiValue(values);
    props.handleChange(event, props.item);
  };

  const onMenuOpen = (event: any) => {
    props.onMenuOpen && props.onMenuOpen(event, props.item);
  };

  switch (props.item.type) {
    case "select": {
      const invalidText = (props.showError && props.errors && props.errors[props.item.fieldId])
        ? props.errors[props.item.fieldId]
        : "";
      return (<>
        <CLSelect
          width="100%"
          options={props.item.options || []}
          selectOption={selectOption}
          isClearable={props.isClearable}
          handleValueChanges={props.handleValueChanges}
          isCreatable={props.isCreatable}
          onMenuOpen={onMenuOpen}
          noOptionsMsg={props.noOptionsMsg}
          defaultValue={props.item.defaultValue}
          isDisabled={props.item.isDisabled || props.disabled}
          name={props.item.fieldId}></CLSelect>
        {invalidText && <div className="invalid-text">{invalidText}</div>}
      </>);
    }
    case "multiply":
      return (<CLSelect
        width="100%"
        isMulti={true}
        dynamicMulti={true}
        showSelectedOptions={true}
        options={props.item.options || []}
        selectOption={selectOptionMultiply}
        onMenuOpen={onMenuOpen}
        defaultValue={props.item.defaultValueMulti}
        isDisabled={props.item.isDisabled}
        name={props.item.fieldId}></CLSelect>);
    case "dateTime": {
      let latestValue = "";
      const maskDate = (value: string) => {
        return value
          .replace(/\D/g, "")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{2})(\d)/, "$1/$2")
          .replace(/(\d{4})(\d)/, "$1");
      };

      const onChangeRaw = (e: any) => {
        setTimeout(() => {
          const value = e.target.value;
          e.target.value = maskDate(value);
          latestValue = e.target.value;
        }, 0);
      };

      const handleBlur = () => {
        if (latestValue.length === 10 && dayjs(latestValue, "MM/DD/YYYY").isValid()) {
          if (!props.item.setValue) return;
          props.item.setValue(latestValue);
          latestValue = "";
        }
      };
      const value = props.item.value
        ? dayjs(props.item.value).add(1, "hours")
        : dayjs("01/01/1970").add(1, "hours");
      return props.item.value
        ? <DatePicker popupStyle={{ zIndex: 10500 }} defaultPickerValue={value} value={value} onChange={handleDatePick}
          onKeyDown={onChangeRaw} format={"MM/DD/YYYY"} onBlur={handleBlur}/>
        : <DatePicker popupStyle={{ zIndex: 10500 }} defaultPickerValue={value} onBlur={handleBlur}
          onChange={handleDatePick} onKeyDown={onChangeRaw} format={"MM/DD/YYYY"}/>;
    }
    case "checkbox":
      return (<CLCheckbox handleChange={handleCheckbox}
        value={props.item.value}
        label={props.item.name}
        isChecked={props.item.isChecked}
        disabled={props.disabled}
        errorFiled={!!(props.errors && props.errors[props.item.fieldId])}
        name={props.item.fieldId}></CLCheckbox>);

    case "textarea":
      return (<textarea onChange={handleChange}
        placeholder={props.item.placeholder || ""}
        value={props.item.value}
        name={props.item.fieldId}
        disabled={props.disabled}></textarea>);

    case "number":
      return (<InputMask mask="+1 (999) 999 9999"
        className="input-mask"
        disabled={props.disabled}
        onChange={handleChange}
        width="100%"
        value={props.item.value}/>);

    default:
      return (<CLInput handleChange={handleChange}
        placeholder={props.item.placeholder || ""}
        width="100%"
        value={props.item.value}
        maxLength={props.item.maxLength}
        name={props.item.fieldId}
        disabled={props.disabled}
        isInvalid={!!(props.showError && props.errors && props.errors[props.item.fieldId])}
        invalidText={(props.showError && props.errors && props.errors[props.item.fieldId])
          ? props.errors[props.item.fieldId]
          : ""}
        type={props.item.type}></CLInput>);
  }
});

export const CLDynamicFormComponent = observer((props: ClDynamicFormComponentType) => {
  const handleChange = (event: any) => {
    props.handleChange(event, props.item);
  };

  const onMenuOpen = (event: any) => {
    props.onMenuOpen && props.onMenuOpen(event, props.item);
  };

  let className = "valid";
  if (props.errors && props.errors[props.item.fieldId]) {
    className = "invalid";
  }

  return (
    <CLDynamicFormWrapper className={props.customClass || ""}>
      <div className={className + " form-field"}>
        {props.item.type !== "checkbox" && !props.hideLabel && <label>
          {props.item.name}
          {props.item.isOptional && <span className="optional-flag">Optional</span>}
          {props.item.isCompliance && <span className="optional-flag">Required for Compliance</span>}
          {props.item.additionalText && <span className="optional-flag">{props.item.additionalText}</span>}
        </label>}
        <InputField
          item={props.item}
          handleChange={handleChange}
          onMenuOpen={onMenuOpen}
          disabled={props.disabled}
          noOptionsMsg={props.noOptionsMsg}
          errors={props.errors}
          isClearable={props.isClearable}
          handleValueChanges={props.handleValueChanges}
          isCreatable={props.item.isCreatable}
          showError={props.showError}></InputField>
      </div>
    </CLDynamicFormWrapper>
  );
});
