export const ARRHYTHMIA_NAME_SVE_RUN = "Supraventricular Run";
export const ARRHYTHMIA_NAME_SVE_COUPLET = "Supraventricular Couplet";

export const ARRHYTHMIA_NAME_COUPLET = "Ventricular Couplet";
export const ARRHYTHMIA_NAME_BIGEMINY = "Ventricular Bigeminy";
export const ARRHYTHMIA_NAME_VPC = "Ventricular Trigeminy";

export const ARRHYTHMIA_NAME_VE_RUN = "Ventricular Run";
export const ARRHYTHMIA_NAME_JUNCTIONAL_ESCAPE = "Junctional rhythm";
export const ARRHYTHMIA_NAME_DEGREE_BLOCK = "2nd Degree AV Block";
export const ARRHYTHMIA_NAME_AV_BLOCK = "3rd Degree AV Block";
export const ARRHYTHMIA_NAME_AV_BLOCK_HIGH = "High-Grade AV Block";

//    export const ARRHYTHMIA_NAME_LATE_BEAT = "Late Beat"; // Not used
export const ARRHYTHMIA_NAME_ATRIAL_FIBRILLATION = "Afib/Aflutter";
export const ARRHYTHMIA_NAME_BRADY = "Bradycardia";
export const ARRHYTHMIA_NAME_DELETED = "Deleted"; // Not used
// export const ARRHYTHMIA_NAME_PAUSE = "Pause/Dropped";
export const ARRHYTHMIA_NAME_PAUSE = "Pause";
export const ARRHYTHMIA_NAME_TACHY = "Tachycardia";
export const ARRHYTHMIA_NAME_UNINTERP = "Uninterpretable";
export const ARRHYTHMIA_NAME_ABNORMAL_BEAT = "Abnormal beat";

export const ARRHYTHMIA_NAME_NONE_SELECTED = "Symptomatic";
export const ARRHYTHMIA_NAME_PALPITATIONS = "Palpitations";
export const ARRHYTHMIA_NAME_DIZZY = "Dizzy";
export const ARRHYTHMIA_NAME_OUT_OF_BREATH = "Out of breath";
export const ARRHYTHMIA_NAME_CHEST_PAIN = "Chest pain";
export const ARRHYTHMIA_NAME_OTHER = "Other";
export const ARRHYTHMIA_NAME_REQUEST = "Requested by Physician"; // Not used
export const ARRHYTHMIA_NAME_BASELINE = "Baseline"; // Not used

export const ARRHYTHMIA_NAME_AIVR = "AIVR";
export const ARRHYTHMIA_NAME_VENT_TACHY = "Ventricular Tachycardia";
export const ARRHYTHMIA_NAME_PACED_RHYTHM = "Paced rhythm";
export const ARRHYTHMIA_NAME_VE_MANY = "VE";
export const ARRHYTHMIA_NAME_SVE_MANY = "SVE";
export const ARRHYTHMIA_NAME_SVE_SVT = "Supraventricular Tachycardia";

export const ARRHYTHMIA_NAME_MIN_HR = "Min HR";
export const ARRHYTHMIA_NAME_MAX_HR = "Max HR";

export const ARRHYTHMIA_NAME_SVE_BT = "Supraventricular Bigeminy";
export const ARRHYTHMIA_NAME_COMPLETE_AVB = "Complete AV block";
export const ARRHYTHMIA_NAME_ROUTINE_ECG = "Routine ECG";

export const ARRHYTHMIA_NAME_SINUS_BRADY = "Sinus Bradycardia";
export const ARRHYTHMIA_NAME_SINUS_TACHY = "Sinus Tachycardia";
export const ARRHYTHMIA_NAME_SINUS_ARRHYTHMIA = "Sinus Arrhythmia";

// export const ARRHYTHMIA_NAME_HIGH_GRADE_AV_BLOCK = "High grade AV block"; // Not used
export const ARRHYTHMIA_NAME_ATRIAL_FLUTTER = "Afib/Aflutter";
export const ARRHYTHMIA_NAME_VENT_FIBRILLATION = "Ventricular Fibrillation";
export const ARRHYTHMIA_NAME_BBB = "IVCD";
export const ARRHYTHMIA_NAME_NSR = "Sinus Rhythm";
export const ARRHYTHMIA_NAME_SYMPTOMATIC = "Symptomatic"; // Not used

export const ARRHYTHMIA_NAME_SINUS_TACHYCARDIA_ONSET = "Sinus Tachycardia Onset";
export const ARRHYTHMIA_NAME_SINUS_TACHYCARDIA_OFFSET = "Sinus Tachycardia Offset";
export const ARRHYTHMIA_NAME_SINUS_TACHYCARDIA_GRADUAL_ONSET = "Sinus Tachycardia Gradual Onset";
export const ARRHYTHMIA_NAME_SINUS_TACHYCARDIA_GRADUAL_OFFSET = "Sinus Tachycardia Gradual Offset";
export const ARRHYTHMIA_NAME_IVR = "IVR";
export const ARRHYTHMIA_NAME_ASYSTOLE = "Asystole";
export const ARRHYTHMIA_NAME_SUPRAVENTRICULAR_TRIGEMINY = "Supraventricular Trigeminy";
export const ARRHYTHMIA_NAME_DEGREE_AV_BLOCK_1ST = "1st Degree AV Block";
export const ARRHYTHMIA_NAME_DEGREE_AV_BLOCK_2ST = "2nd Degree AV Block 2:1 Conduction";
export const ARRHYTHMIA_NAME_OPERATOR_SELECTED = "Operator Selected";

export const ARRHYTHMIA_NAME_TORSADE_DE_POINTES = "Torsade de Pointes"; // Not used
export const ARRHYTHMIA_NAME_RT = "R on T"; // Not used
export const ARRHYTHMIA_NAME_OTHER_A = "Other A"; // Not used
export const ARRHYTHMIA_NAME_OTHER_B = "Other B"; // Not used
export const ARRHYTHMIA_NAME_OTHER_C = "Other C"; // Not used

export function getEventTypeConstant(eventType: number) {
  switch (eventType) {
    case 0: return "";
    case 1: return ARRHYTHMIA_NAME_TACHY;
    case 2: return ARRHYTHMIA_NAME_BRADY;
    case 3: return ARRHYTHMIA_NAME_PAUSE;
    case 4: return ARRHYTHMIA_NAME_ATRIAL_FIBRILLATION;
    case 5: return ARRHYTHMIA_NAME_VE_RUN;
    case 6: return ARRHYTHMIA_NAME_VPC;
    case 7: return ARRHYTHMIA_NAME_COUPLET;
    case 9: return ARRHYTHMIA_NAME_BIGEMINY;
    case 10: return ARRHYTHMIA_NAME_AV_BLOCK;
    case 11: return ARRHYTHMIA_NAME_SVE_RUN;
    case 12: return ARRHYTHMIA_NAME_SVE_COUPLET;
    case 14: return "BEAT_LATE"; // some mistake
    case 19: return ARRHYTHMIA_NAME_ABNORMAL_BEAT;
    case 20: return ARRHYTHMIA_NAME_UNINTERP;

    case 69: return ARRHYTHMIA_NAME_NSR;
    case 70: return ARRHYTHMIA_NAME_AV_BLOCK_HIGH;
      // case 71: return Constants.ARRHYTHMIA_NAME_HIGH_GRADE_AV_BLOCK;
    case 72: return ARRHYTHMIA_NAME_DEGREE_BLOCK;
    case 73: return ARRHYTHMIA_NAME_JUNCTIONAL_ESCAPE;
    case 74: return ARRHYTHMIA_NAME_ATRIAL_FLUTTER;
    case 75: return ARRHYTHMIA_NAME_VENT_FIBRILLATION;
    case 76: return ARRHYTHMIA_NAME_BBB;
    case 77: return ARRHYTHMIA_NAME_SVE_MANY;
    case 79: return ARRHYTHMIA_NAME_SVE_SVT;
    case 80: return ARRHYTHMIA_NAME_VE_MANY;
    case 81: return ARRHYTHMIA_NAME_AIVR;
    case 82: return ARRHYTHMIA_NAME_VENT_TACHY;
    case 83: return ARRHYTHMIA_NAME_PACED_RHYTHM;

    case 90: return ARRHYTHMIA_NAME_MIN_HR;
    case 91: return ARRHYTHMIA_NAME_MAX_HR;
    case 92: return ARRHYTHMIA_NAME_SINUS_BRADY;
    case 93: return ARRHYTHMIA_NAME_SINUS_TACHY;
    case 94: return ARRHYTHMIA_NAME_SINUS_ARRHYTHMIA;

    case 95: return ARRHYTHMIA_NAME_SVE_BT;
    case 96: return ARRHYTHMIA_NAME_COMPLETE_AVB;
    case 97: return ARRHYTHMIA_NAME_ROUTINE_ECG;

    case 100: return ARRHYTHMIA_NAME_SINUS_TACHYCARDIA_ONSET;
    case 101: return ARRHYTHMIA_NAME_SINUS_TACHYCARDIA_OFFSET;
    case 102: return ARRHYTHMIA_NAME_SINUS_TACHYCARDIA_GRADUAL_ONSET;
    case 103: return ARRHYTHMIA_NAME_SINUS_TACHYCARDIA_GRADUAL_OFFSET;
    case 104: return ARRHYTHMIA_NAME_IVR;
    case 105: return ARRHYTHMIA_NAME_ASYSTOLE;
    case 106: return ARRHYTHMIA_NAME_SUPRAVENTRICULAR_TRIGEMINY;
    case 107: return ARRHYTHMIA_NAME_DEGREE_AV_BLOCK_1ST;
    case 108: return ARRHYTHMIA_NAME_DEGREE_AV_BLOCK_2ST;
    case 111: return ARRHYTHMIA_NAME_OPERATOR_SELECTED;

    // case 199: return ARRHYTHMIA_NAME_REQUEST;
    // case 198: return ARRHYTHMIA_NAME_BASELINE;
    case 200: return ARRHYTHMIA_NAME_NONE_SELECTED;
    case 201: return ARRHYTHMIA_NAME_PALPITATIONS;
    case 202: return ARRHYTHMIA_NAME_DIZZY;
    case 203: return ARRHYTHMIA_NAME_OUT_OF_BREATH;
    case 204: return ARRHYTHMIA_NAME_CHEST_PAIN;
    case 205: return ARRHYTHMIA_NAME_OTHER;
  }
}
