import { PropsType } from "./cl-app-header-notification.props";
import { observer } from "mobx-react-lite";
import { ClAppHeaderNotificationWraper } from "./cl-app-header-notification.presents";
import { useRootStore } from "../../stores/storeContext";
import { convertNotificationsByDate, compare } from "../../services/handlers/data.handler";
import { formatDateSeconds, secondsToTimeString } from "../../services/handlers/date.handler";
import EventIcon from "../../assets/icons/list-patients-event.svg";
import EventIconRed from "../../assets/icons/events-stat.svg";
import { CLIcon } from "../shared/cl-icon";
import { getEventTypeConstant } from "../cl-patient-events-type/cl-patient-events-type.constant";
import { useNavigate } from "react-router-dom";

export const ClAppHeaderNotification = observer((props: PropsType) => {
  const { newNotifications, lastNotifications, problemDevices } = useRootStore().userStore;
  const navigate = useNavigate();
  const sortData = () => {
    let list = [];
    if (newNotifications.length > 0) {
      list.push({
        title: `New (${newNotifications.length})`,
        isNew: true,
        data: [...newNotifications].sort((a: any, b: any): any => { return compare(a, b, "StartDate"); })
      });
    }

    if (lastNotifications.length > 0) {
      const sortedLastNotifications = convertNotificationsByDate(lastNotifications);
      list = list.concat(sortedLastNotifications);
    }
    return list;
  };

  const openEvent = (ev: any, item: any) => {
    ev.preventDefault();
    if (item.RecordingID) {
      window.location.href = `/patient-info/${item.RecordingID}/events?eventId=${item.Id}`;
    }
  };

  const goToDeviceAlert = (e: any) => {
    e.preventDefault();
    navigate("/devices-list", { state: { activeCategory: "problem" }, replace: true});
  };

  const deviceIssueCopy = problemDevices > 1 ? "devices" : "device";

  return (
    <ClAppHeaderNotificationWraper isOpen={props.isOpen}>
      <div className="title">Notifications</div>
      {(!newNotifications.length && !lastNotifications.length && !problemDevices) && <div className="not-found">
        Notifications not found
      </div>}
      <div className="list">
        {(problemDevices && problemDevices !== "0")
          ? <div className="devices-block">
            <div className={"problem-devices-block"}
              onClick={goToDeviceAlert}>
              <div className="title">{problemDevices} {deviceIssueCopy} need maintenance</div>
            </div></div>
          : null}
        {(newNotifications.length > 0 || lastNotifications.length > 0)
          ? <div>
            {sortData().map((item: any) => {
              if (item.data.length > 0) {
                return (
                  <div>
                    <div className="title">{item.title}</div>
                    {item.data.map((notification: any) => <div
                      className="notification-item"
                      onClick={(ev: any) => openEvent(ev, notification)}>
                      <div className={`icon ${item.isNew ? "new" : ""}`}>
                        <CLIcon icon={item.isNew ? EventIconRed : EventIcon}></CLIcon>
                      </div>
                      <div className="info">
                        <div className="caption">{getEventTypeConstant(notification.EventType)}</div>
                        <div className="date">
                          {notification.PatientName || ""} {notification.PatientLastName || ""}{" | "}
                          {formatDateSeconds(notification.StartDate)}{" | "}
                          {secondsToTimeString(notification.Length)}
                        </div>
                        <div className="date">{notification.EcgStripCaption}</div>
                      </div>
                    </div>)}
                  </div>
                );
              }
            })}
          </div>
          : null}
      </div>
    </ClAppHeaderNotificationWraper>
  );
});
