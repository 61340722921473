import React from "react";
import { createRoot } from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "spin.js/spin.css";
import "react-circular-progressbar/dist/styles.css";
import "react-pdf/dist/Page/TextLayer.css";
import "react-pdf/dist/Page/AnnotationLayer.css";

import "./index.css";


import * as serviceWorker from "./serviceWorker";
import App from "./boot/App";
import { pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.mjs`;

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <>
    <App />
  </>
);
serviceWorker.unregister();
